import * as React from "react";
import * as FDN from "src/core";
import {
  BvLocation,
  IBauvorhaben,
  IMstTasksPlan,
  ITodo,
  ITodoBucket,
  ITodoBuckets,
  ITodoTask,
  ITodoUsers,
} from "src/types/types";
import I18n from "src/core/I18n";
import { TActionsBauvorhabenAufgaben } from "src/services/cc/CCBvAufgabenService";
import { formatDateTime } from "src/core/helpers/helpers";

const maxAssignmentAvatars = 3;

interface IBvAufgabenProps {
  bauvorhaben: IBauvorhaben;
  location: BvLocation;
  updatingTasksPlan?: IMstTasksPlan | null;
  todo: ITodo;
  type: "finished" | "unfinished";
  editMode: boolean;
  actions: TActionsBauvorhabenAufgaben;
}

const BvAufgaben: React.FunctionComponent<IBvAufgabenProps> = ({
  bauvorhaben,
  location,
  updatingTasksPlan,
  editMode,
  todo,
  type,
  actions,
}) => {
  let buckets: ITodoBuckets | null = {};
  if (type === "finished") buckets = todo.finishedTasks;
  else if (type === "unfinished") buckets = todo.unfinishedTasks;

  if (!buckets || Object.keys(buckets).length === 0)
    return <div className="text-center">{I18n.t("ccBvAufgaben.noBuckets")}</div>;

  return (
    <div className="__cc-bauvorhaben-aufgaben">
      {updatingTasksPlan && updatingTasksPlan.syncPercent < 100 ? (
        <RefreshingTasksPlan
          todo={todo}
          type={type}
          location={location}
          bauvorhaben={bauvorhaben}
          updatingTasksPlan={updatingTasksPlan}
          editMode={editMode}
          actions={actions}
        />
      ) : (
        <>
          <SyncInfoLine todo={todo} actions={actions} />
          <div className="__cc-bauvorhaben-aufgaben-buckets-wrapper">
            <div className="__cc-bauvorhaben-aufgaben-buckets">
              {Object.keys(buckets).map((bucketId) => {
                if (!buckets || !buckets[bucketId]) return null;

                return (
                  <Bucket
                    location={location}
                    key={bucketId}
                    bucket={buckets[bucketId]}
                    todo={todo}
                    bauvorhaben={bauvorhaben}
                    type={type}
                    actions={actions}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface ISyncInfoLineProps {
  todo: ITodo;
  actions: TActionsBauvorhabenAufgaben;
}

const SyncInfoLine: React.FunctionComponent<ISyncInfoLineProps> = ({ todo, actions }) => {
  return (
    <div className="__cc-bauvorhaben-aufgaben-syncinfoline">
      <FDN.Icon icon="clock" left />
      {FDN.I18n.t("ccBvAufgaben.refreshTasksPlan.lastSync", {
        date: todo.tasksPlanLastSync
          ? formatDateTime(todo.tasksPlanLastSync, { seconds: false })
          : FDN.I18n.t("main.date.never"),
      })}{" "}
      &nbsp;{" "}
      <button onClick={() => actions.refreshTasksPlan(todo.tasksPlanIdentifier)}>
        <FDN.Icon icon="refresh" left /> {FDN.I18n.t("ccBvAufgaben.refreshTasksPlan.syncNow")}
      </button>
    </div>
  );
};

interface IBucketProps {
  bauvorhaben: IBauvorhaben;
  location: BvLocation;
  bucket: ITodoBucket;
  todo: ITodo;
  type: "finished" | "unfinished";
  actions: TActionsBauvorhabenAufgaben;
}

const Bucket: React.FunctionComponent<IBucketProps> = ({
  bauvorhaben,
  location,
  bucket,
  todo,
  type,
  actions,
}) => {
  const tasks = bucket.tasks;

  return (
    <div className="__cc-bauvorhaben-aufgaben-buckets-bucket">
      <div className="__cc-bauvorhaben-aufgaben-buckets-bucket-name">{bucket.name}</div>
      <div className="__cc-bauvorhaben-aufgaben-buckets-bucket-tasks">
        {!tasks || tasks.length === 0 ? (
          <div className="text-center">{I18n.t(`ccBvAufgaben.${type}.noTasks`)}</div>
        ) : (
          <>
            {tasks.map((task, index) => {
              return (
                <Task
                  key={index}
                  bauvorhaben={bauvorhaben}
                  location={location}
                  todo={todo}
                  task={task}
                  type={type}
                  actions={actions}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

interface ITaskProps {
  bauvorhaben: IBauvorhaben;
  location: BvLocation;
  todo: ITodo;
  task: ITodoTask;
  type: "finished" | "unfinished";
  actions: TActionsBauvorhabenAufgaben;
}

const Task: React.FunctionComponent<ITaskProps> = ({ todo, task, actions }) => {
  return (
    <button className="__cc-bauvorhaben-aufgaben-task" onClick={() => actions.openTask(task)}>
      <div className="__cc-bauvorhaben-aufgaben-content">
        <div className="__cc-bauvorhaben-aufgaben-task-title">{task.title}</div>
        <TaskChecklist task={task} />
      </div>
      <div className="__cc-bauvorhaben-aufgaben-task-icons">
        <TaskIconPriority task={task} />
        <TaskIconProgress task={task} />
      </div>
      <div className="__cc-bauvorhaben-aufgaben-task-bottomline">
        <TaskDueDate task={task} actions={actions} />
        {todo.users && <TaskAssignments todoUsers={todo.users} task={task} />}
      </div>
    </button>
  );
};

interface ITaskInfoProps {
  task: ITodoTask;
}

const TaskIconProgress: React.FunctionComponent<ITaskInfoProps> = ({ task }) => {
  if (task.percentComplete === 100)
    return (
      <span className="__cc-bauvorhaben-aufgaben-task-icons-icon progress-icon">
        <FDN.Icon icon="check-circle" />
      </span>
    );
  else if (task.percentComplete === 50)
    return (
      <span className="__cc-bauvorhaben-aufgaben-task-icons-icon progress-icon">
        <FDN.Icon icon="play-circle" />
      </span>
    );
  else return null;
};

const TaskIconPriority: React.FunctionComponent<ITaskInfoProps> = ({ task }) => {
  if (task.priority === 1)
    return <span className="__cc-bauvorhaben-aufgaben-task-icons-icon exclamation-icon">!!</span>;
  else if (task.priority === 3)
    return <span className="__cc-bauvorhaben-aufgaben-task-icons-icon exclamation-icon">!</span>;
  else if (task.priority === 5) return null;
  else return null;
};

interface ITaskDueDateProps extends ITaskInfoProps {
  actions: TActionsBauvorhabenAufgaben;
}

const TaskDueDate: React.FunctionComponent<ITaskDueDateProps> = ({ task, actions }) => {
  if (task.dueDate) {
    return (
      <div
        className={`__cc-bauvorhaben-aufgaben-task-duedate ${
          actions.isTaskOverdue(task) ? `is-overdue` : ``
        }`}
      >
        <FDN.Icon icon="calendar" left /> {formatDateTime(task.dueDate)}
      </div>
    );
  } else return <>&nbsp;</>;
};

const TaskChecklist: React.FunctionComponent<ITaskInfoProps> = ({ task }) => {
  if (task.checklistItemCount) {
    return (
      <div className="__cc-bauvorhaben-aufgaben-task-checklist">
        <TaskChecklistList task={task} />
        <FDN.Icon icon="list-ul" left /> {task.finishedChecklistItemCount} /{" "}
        {task.checklistItemCount}
      </div>
    );
  } else return <>&nbsp;</>;
};

const TaskChecklistList: React.FunctionComponent<ITaskInfoProps> = ({ task }) => {
  if (!task.checklistItems || task.checklistItems.length === 0) return null;

  const checklistItems = task.checklistItems as [{ [key: string]: any }];

  return (
    <div className="__cc-bauvorhaben-aufgaben-task-checklist-list">
      <ul>
        {checklistItems.map((checklistItem, index) => {
          return (
            <li key={index}>
              <FDN.Icon
                icon={checklistItem.isChecked === true ? `check-circle-o` : `circle-o`}
                left
              />
              {checklistItem.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

interface ITaskAssignmentsProps extends ITaskInfoProps {
  todoUsers: ITodoUsers;
}

const TaskAssignments: React.FunctionComponent<ITaskAssignmentsProps> = ({ todoUsers, task }) => {
  if (!task.assignments) return null;

  return (
    <div className="__cc-bauvorhaben-aufgaben-task-assignments">
      {(task.assignments as string[]).map((assignmentUserId, index) => {
        if (!(assignmentUserId in todoUsers)) return null;
        if (index + 1 > maxAssignmentAvatars) return null;

        return (
          <div className="__cc-bauvorhaben-aufgaben-task-assignments-assignment" key={index}>
            <FDN.ToolTip tooltip={todoUsers[assignmentUserId].displayname as string}>
              <FDN.Avatar size={30} user={todoUsers[assignmentUserId]} />
            </FDN.ToolTip>
          </div>
        );
      })}
      {task.assignments.length > maxAssignmentAvatars && (
        <span className="__cc-bauvorhaben-aufgaben-task-assignments-assignment-more">
          <FDN.Avatar
            user={{ firstname: "+", lastname: `${task.assignments.length - maxAssignmentAvatars}` }}
            size={30}
          />
        </span>
      )}
    </div>
  );
};

const RefreshingTasksPlan: React.FunctionComponent<IBvAufgabenProps> = ({ updatingTasksPlan }) => {
  if (!updatingTasksPlan) return null;

  return (
    <div className="__cc-bauvorhaben-aufgaben-tasksplanrefreshing">
      <FDN.Box>
        <FDN.Loading box />
        <h3>{FDN.formatNumber(updatingTasksPlan.syncPercent, 0, 2)}%</h3>
        <div>{FDN.I18n.t("ccBvAufgaben.refreshTasksPlan.popup.text")}</div>
      </FDN.Box>
    </div>
  );
};

export default BvAufgaben;
