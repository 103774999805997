import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben, IMstFolder } from "src/types/types";
import CCApi from "src/api/CCApi";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import FileBrowser from "src/components/thirdparty/microsoft/MstFileBrowser";
import { useNavigate } from "react-router-dom";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import MstSyncInfo from "src/components/thirdparty/microsoft/MstSyncInfo";

const tabs = {
  knowledgebase: {
    label: FDN.I18n.t("ccBvKnowledgebase.tabs.knowledgebase.label"),
    icon: "book",
  },
};

const defaultTab = "knowledgebase";

interface IBauvorhabenKnowledgebaseProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BauvorhabenKnowledgebase: React.FunctionComponent<IBauvorhabenKnowledgebaseProps> = ({
  bauvorhaben,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);
  const [mstFolder, setMstFolder] = React.useState<IMstFolder>();
  const [lastSync, setLastSync] = React.useState<Date>();
  const [error, setError] = React.useState<StatusCode>();

  const { api } = useServiceCore();

  const navigate = useNavigate();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "knowledgebase").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        if (response?.body?.status === StatusCode.MST_FOLDER_NOT_FOUND) {
          setError(response.body.status);
        } else {
          if (response?.body?.mstFolder) setMstFolder(response.body.mstFolder as IMstFolder);
          if (response?.body?.lastSync) setLastSync(response.body.lastSync as Date);
        }
      }
    });
  };

  if (!mstFolder && !error) return <FDN.Loading box />;

  if (error)
    return (
      <FDN.CalloutBox type="warning">
        <p>
          <strong>{FDN.I18n.t(`ccMain.errors.${error}.title`)}</strong>
        </p>
        <div>{FDN.I18n.t(`ccMain.errors.${error}.text`)}</div>
      </FDN.CalloutBox>
    );

  if (!mstFolder) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-einstellungen">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">
        {FDN.I18n.t(`ccBvKnowledgebase.tabs.${selectedTab}.title`)}
      </h2>
      <FDN.TabsContent>
        <MstSyncInfo lastSync={lastSync} bvIdentifier="system" type="knowledgeBase" />
        {selectedTab === "knowledgebase" && <FileBrowser initMstFolder={mstFolder} />}
        <div>&nbsp;</div>
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenKnowledgebase;
