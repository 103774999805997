import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben } from "src/types/types";
import BvTermine from "./termine/BvTermine";
import { useNavigate } from "react-router-dom";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import useServiceCCBvTermine, {
  TActionsBauvorhabenTermine,
} from "src/services/cc/CCBvTermineService";

const tabs = {
  termine: {
    label: FDN.I18n.t("ccBvTermine.tabs.termine.label"),
    icon: "calendar-o",
  },
  // bauzeitverzoegerungen: {
  //   label: FDN.I18n.t("ccBvTermine.tabs.bauzeitverzoegerungen.label"),
  //   icon: "calendar-times-o",
  // },
};

const defaultTab = "termine";

interface IBauvorhabenTermineProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BauvorhabenTermine: React.FunctionComponent<IBauvorhabenTermineProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const { termine, actions: termineActions } = useServiceCCBvTermine(actions);

  const moduleActions: TActionsBauvorhabenTermine = { ...actions, ...termineActions };

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!termine) termineActions.init(bauvorhaben);
  }, [bauvorhaben]);

  if (!termine) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-tab-termine">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">
        {FDN.I18n.t(`ccBvTermine.tabs.${selectedTab}.title`)}
      </h2>
      <FDN.TabsContent>
        {selectedTab === "termine" && (
          <BvTermine
            termine={termine}
            bauvorhaben={bauvorhaben}
            editMode={editMode}
            actions={moduleActions}
          />
        )}
        {selectedTab === "bauzeitverzoegerungen" && <FDN.Box>Bauze</FDN.Box>}
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenTermine;
