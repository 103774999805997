import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben, IBvPaymentPlan } from "src/types/types";
import Config from "src/core/Config";
import InstallmentsList from "./parts/InstallmentsList";
import { TActionsBauvorhabenPaymentPlan } from "src/services/cc/CCBvPaymentPlanService";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IBvPaymentPlanProps {
  bauvorhaben: IBauvorhaben;
  paymentPlan: IBvPaymentPlan;
  editMode: boolean;
  actions: TActionsBauvorhabenPaymentPlan;
}

const BvPaymentPlan: React.FunctionComponent<IBvPaymentPlanProps> = ({
  bauvorhaben,
  paymentPlan,
  editMode,
  actions,
}) => {
  const { userView } = React.useContext(AppContext);

  return (
    <div className="__cc-bauvorhaben-paymentplan">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={12}>
            <FDN.Box>
              <h4>{FDN.I18n.t("ccBvPaymentPlan.hauspreis.title")}</h4>
              <PriceOverviewLine
                label={FDN.I18n.t("ccBvPaymentPlan.sumBuilding.label")}
                value={paymentPlan.sumBuilding}
              />
              <PriceOverviewLine
                label={FDN.I18n.t("ccBvPaymentPlan.sumBnk.label")}
                value={paymentPlan.sumBnk}
              />
              <hr className="paymentplan-priceoverview-separator" />
              <PriceOverviewLine
                label={FDN.I18n.t("ccBvPaymentPlan.sumSubTotal.label")}
                value={paymentPlan.sumSubTotal}
              />
              <PriceOverviewLine
                label={FDN.I18n.t("ccBvPaymentPlan.sumMehr.label")}
                value={paymentPlan.sumMehr}
              />
              <PriceOverviewLine
                label={FDN.I18n.t("ccBvPaymentPlan.sumMinder.label")}
                value={paymentPlan.sumMinder}
              />
              <hr className="paymentplan-priceoverview-separator" />
              <PriceOverviewLine
                label={FDN.I18n.t("ccBvPaymentPlan.sumTotal.label")}
                value={paymentPlan.sumTotal}
              />
            </FDN.Box>
          </FDN.Cell>
          <FDN.Cell sm={24} md={12}>
            <FDN.Box>
              <h4>{FDN.I18n.t("ccBvPaymentPlan.zahlungsfortschritt.title")}</h4>
              <PriceOverviewLine
                label={FDN.I18n.t("ccBvPaymentPlan.totalApproved.label")}
                value={paymentPlan.totalApproved}
              />
              <PriceOverviewLine
                label={FDN.I18n.t("ccBvPaymentPlan.totalInvoiced.label")}
                value={paymentPlan.totalInvoiced}
              />
              <PriceOverviewLine
                label={FDN.I18n.t("ccBvPaymentPlan.totalNotInvoiced.label")}
                value={(paymentPlan.totalApproved || 0) - (paymentPlan.totalInvoiced || 0)}
              />
              <div>&nbsp;</div>
              <div className="success-text">
                <PriceOverviewLine
                  label={FDN.I18n.t("ccBvPaymentPlan.totalPaid.label")}
                  value={paymentPlan.totalPaid}
                />
              </div>
              <div className="warning-text">
                <PriceOverviewLine
                  label={FDN.I18n.t("ccBvPaymentPlan.totalNotPaid.label")}
                  value={paymentPlan.totalNotPaid}
                />
              </div>
              <div className="error-text">
                <PriceOverviewLine
                  label={FDN.I18n.t("ccBvPaymentPlan.totalOverdue.label")}
                  value={paymentPlan.totalOverdue}
                />
              </div>
            </FDN.Box>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      {userView === "cc" && (
        <div className="text-right" style={{ paddingBottom: "10px" }}>
          <button className="small primary button" onClick={() => actions.selectInstallment("new")}>
            <FDN.Icon icon="list" left />{" "}
            {FDN.I18n.t("ccBvPaymentPlan.buttons.addInstallment.label")}
          </button>{" "}
          <button
            className="small primary button"
            onClick={() => actions.selectInstallment("minder")}
          >
            <FDN.Icon icon="minus" left /> {FDN.I18n.t("ccBvPaymentPlan.buttons.addMinder.label")}
          </button>{" "}
          <button
            className="small primary button"
            onClick={() => actions.selectInstallment("mehr")}
          >
            <FDN.Icon icon="plus" left /> {FDN.I18n.t("ccBvPaymentPlan.buttons.addMehr.label")}
          </button>
        </div>
      )}
      <InstallmentsList
        paymentPlan={paymentPlan}
        bauvorhaben={bauvorhaben}
        editMode={editMode}
        actions={actions}
      />
    </div>
  );
};

interface IPriceOverviewLine {
  label: string;
  value?: number;
  textValue?: string;
}

const PriceOverviewLine: React.FunctionComponent<IPriceOverviewLine> = ({
  label,
  value,
  textValue,
}) => {
  const currency = Config.get("currency") as string;
  const contentValue =
    value || value === 0 ? (
      <span className="is-number">
        {currency}
        {FDN.formatNumber(value)}
      </span>
    ) : (
      textValue || ""
    );

  return (
    <div className="paymentplan-priceoverview-line">
      <div className="paymentplan-priceoverview-line-label">{label}</div>
      <div className="paymentplan-priceoverview-line-value">{contentValue}</div>
    </div>
  );
};

export default BvPaymentPlan;
