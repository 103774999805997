import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben } from "src/types/types";
import BvInformationen from "./informationen/BvInformationen";
import BvAdresse from "./informationen/BvAdresse";
import { useNavigate } from "react-router-dom";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";

const tabs = {
  info: {
    label: FDN.I18n.t("ccBvInformationen.tabs.info.label"),
    icon: "home",
  },
  bvAdresse: {
    label: FDN.I18n.t("ccBvInformationen.tabs.bvAdresse.label"),
    icon: "id-card-o",
  },
};

const defaultTab = "info";

interface IBauvorhabenInformationenProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BauvorhabenInformationen: React.FunctionComponent<IBauvorhabenInformationenProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const navigate = useNavigate();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    // CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "einstellungen").then((response) => {
    //   if (response?.statusCode === StatusCode.SUCCESS) {
    //     if (response?.body?.mstTeams) setMstTeams(response.body.mstTeams as IMstTeams);
    //     if (response?.body?.mstTaskPlans)
    //       setMstTaskPlans(response.body.mstTaskPlans as IMstTasksPlans);
    //   }
    // });
  };

  if (!bauvorhaben) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-informationen">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">
        {FDN.I18n.t(`ccBvInformationen.tabs.${selectedTab}.title`)}
      </h2>
      <FDN.TabsContent>
        {selectedTab === "info" && (
          <BvInformationen bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
        )}
        {selectedTab === "bvAdresse" && (
          <BvAdresse bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
        )}
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenInformationen;
