import * as React from "react";
import CCNavigation from "./CCNavigation";
import LayoutWithTopbar from "../../../layouts/LayoutWithTopbar";

interface ICCLayoutProps {
  page: string;
  contentPadding?: string;
  children?: React.ReactNode;
}

const CCLayout: React.FunctionComponent<ICCLayoutProps> = ({ page, contentPadding, children }) => {
  return (
    <div className="__cc-wrapper">
      <LayoutWithTopbar page={page}>
        <main>
          <CCNavigation page={page} />
          <div className="__cc-content">
            <div
              style={{
                padding: `${contentPadding}`,
              }}
            >
              {children}
            </div>
          </div>
        </main>
      </LayoutWithTopbar>
    </div>
  );
};

export default CCLayout;
