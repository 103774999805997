import * as React from "react";
import * as FDN from "src/core";
import { IBvFertigungsbuergschaft } from "src/types/types";
import BvProgressListBox from "../../parts/BvProgressList";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IStatusFertigungsbuergschaftenProps {
  fertigungsbuergschaften?: IBvFertigungsbuergschaft;
}

const StatusFertigungsbuergschaften: React.FunctionComponent<
  IStatusFertigungsbuergschaftenProps
> = ({ fertigungsbuergschaften }) => {
  if (!fertigungsbuergschaften) return <FDN.Loading box />;

  const APP = React.useContext(AppContext);
  const userView = APP.userView;

  return (
    <div className="__bv-status-fertigungsbuergschaften">
      <FDN.Box>
        <BvProgressListBox
          type="fertigungsbuergschaften"
          isEditable={false}
          fertigungsbuergschaften={fertigungsbuergschaften}
          link={userView === "cc" ? "tab=bauvorbereitung" : undefined}
        />
      </FDN.Box>
    </div>
  );
};

export default StatusFertigungsbuergschaften;
