import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben, IBauvorhabenFormProps, TActions } from "src/types/types";
import Config from "src/core/Config";

interface INewBauvorhabenProps {
  newBauvorhaben: IBauvorhaben;
  missing: string[];
  actions: TActions;
}

const NewBauvorhaben: React.FunctionComponent<INewBauvorhabenProps> = ({
  newBauvorhaben,
  missing,
  actions,
}) => {
  const editMode = true;

  return (
    <div className="__cc-bauvorhaben-new">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={12}>
            <FormGroupName
              bauvorhaben={newBauvorhaben}
              editMode={editMode}
              missing={missing}
              actions={actions}
            />
            <FormGroupStatus
              bauvorhaben={newBauvorhaben}
              editMode={editMode}
              missing={missing}
              actions={actions}
            />
          </FDN.Cell>
          <FDN.Cell md={12}>
            <FormGroupAddress
              bauvorhaben={newBauvorhaben}
              editMode={editMode}
              missing={missing}
              actions={actions}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      <FDN.FormButtons
        missing={missing}
        onCancel={actions.onCancelNew}
        onSave={actions.onSaveNew}
        saveLabel={FDN.I18n.t("ccBauvorhaben.form.buttons.new.onSave.label")}
        saveIcon="arrow-right"
        saveIconPosition="right"
      />
    </div>
  );
};

const FormGroupName: React.FunctionComponent<IBauvorhabenFormProps> = ({
  bauvorhaben,
  editMode,
  missing,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("ccBauvorhaben.form.name.title")}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              editable={false}
              editMode={editMode}
              type="text"
              value={bauvorhaben.name}
              label={FDN.I18n.t("ccBauvorhaben.form.name.label")}
              placeholder={FDN.I18n.t("ccBauvorhaben.form.name.placeholder")}
              isMissing={missing?.includes("name")}
              onUpdate={(value) => actions.onEdit("name", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.FormGroup>
  );
};
const FormGroupStatus: React.FunctionComponent<IBauvorhabenFormProps> = ({
  bauvorhaben,
  editMode,
  missing,
  actions,
}) => {
  const configBvStatus = Config.get("bauvorhaben.status") as string[];

  const selectValues: { [key: string]: string | null } = {};
  for (const statusKey of configBvStatus) {
    selectValues[statusKey] = FDN.I18n.t(`ccBauvorhaben.status.${statusKey}.label`);
  }

  return (
    <FDN.FormGroup title={FDN.I18n.t("ccBauvorhaben.form.status.title")}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              editable={true}
              editMode={editMode}
              type="select"
              selectValues={selectValues}
              value={bauvorhaben.status}
              label={FDN.I18n.t("ccBauvorhaben.form.status.label")}
              placeholder={FDN.I18n.t("ccBauvorhaben.form.status.placeholder")}
              onUpdate={(value) => actions.onEdit("status", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.FormGroup>
  );
};

const FormGroupAddress: React.FunctionComponent<IBauvorhabenFormProps> = ({
  bauvorhaben,
  editMode,
  missing,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("ccBauvorhaben.form.address.title")}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              editable={false}
              editMode={editMode}
              type="text"
              value={bauvorhaben.street}
              label={FDN.I18n.t("ccBauvorhaben.form.street.label")}
              isMissing={missing?.includes("street")}
              onUpdate={(value) => actions.onEdit("street", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              editable={false}
              editMode={editMode}
              type="text"
              value={bauvorhaben.streetAdditional}
              label={FDN.I18n.t("ccBauvorhaben.form.streetAdditional.label")}
              isMissing={missing?.includes("streetAdditional")}
              onUpdate={(value) => actions.onEdit("streetAdditional", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={8}>
            <FDN.Input
              editable={false}
              editMode={editMode}
              type="text"
              value={bauvorhaben.zip}
              label={FDN.I18n.t("ccBauvorhaben.form.zip.label")}
              isMissing={missing?.includes("zip")}
              onUpdate={(value) => actions.onEdit("zip", value)}
            />
          </FDN.Cell>
          <FDN.Cell sm={16}>
            <FDN.Input
              editable={false}
              editMode={editMode}
              type="text"
              value={bauvorhaben.city}
              label={FDN.I18n.t("ccBauvorhaben.form.city.label")}
              isMissing={missing?.includes("city")}
              onUpdate={(value) => actions.onEdit("city", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        {/*<Row margin="xy">
          <Cell sm={24}>
            <Input
              editable={false}
              editMode={editMode}
              type="country"
              value={bauvorhaben.country}
              label={I18n.t("ccBauvorhaben.form.country.label")}
              isMissing={missing?.includes("name")}
              onUpdate={(value) => actions.onEdit("country", value)}
            />
          </Cell>
  </Row>*/}
      </FDN.Grid>
    </FDN.FormGroup>
  );
};

export default NewBauvorhaben;
