import { useState } from "react";
import { IBauvorhaben, IBvTermine, TFunction } from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import CCApi from "src/api/CCApi";
import { cloneDeep } from "lodash";
import { TActionsBauvorhaben } from "./CCBauvorhabenService";

export interface TActionsBauvorhabenTermine extends TActionsBauvorhaben {
  init: TFunction;
  onEditTermin: TFunction;
}

const useServiceCCBvTermine = (bvActions: TActionsBauvorhaben) => {
  const { api } = useServiceCore();

  const [bauvorhaben, setBauvorhaben] = useState<IBauvorhaben>();
  const [termine, setTermine] = useState<IBvTermine>();

  const init = (bauvorhaben: IBauvorhaben) => {
    setBauvorhaben(cloneDeep(bauvorhaben));
    CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "termine").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setTermine(response?.body?.termine as IBvTermine);
      }
    });
  };

  const onEditTermin = (property: string, value: Date | null | undefined) => {
    if (!termine || !bauvorhaben) return;

    termine[property] = value;
    bauvorhaben.termine = termine;

    setTermine(cloneDeep(termine));

    bvActions.onEdit("termine", cloneDeep(termine));
  };

  const actions: TActionsBauvorhabenTermine = {
    ...bvActions,
    init,
    onEditTermin,
  };

  return { termine, actions };
};

export default useServiceCCBvTermine;
