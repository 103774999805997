import * as React from "react";
import * as FDN from "src/core";
import { ITeamsUserInfo, TActions, IUserSettingsState } from "src/types/types";
import useServiceCore from "src/services/CoreService";
import LocalStorageService from "src/core/LocalStorageService";

interface ITabMicrosoftTeamsProps {
  userSettings: IUserSettingsState;
  teamsUserInfo?: ITeamsUserInfo;
  actions: TActions;
}

const TabMicrosoftTeams: React.FunctionComponent<ITabMicrosoftTeamsProps> = (props) => {
  const { userSettings, teamsUserInfo, actions } = props;

  const { api, APP } = useServiceCore();

  const user = APP.getUser();

  if (!user) return null;

  const userIsAlreadyConnected = user.teamsAccessToken && user.teamsRefreshToken ? true : false;

  const connectUrl = api.getEndpoint(
    "user.microsoftConnect.connect",
    {},
    {
      token: LocalStorageService.get("ACCESS_TOKEN") as string,
    }
  ) as string;

  const disconnectUrl = api.getEndpoint(
    "user.microsoftConnect.disconnect",
    {},
    {
      token: LocalStorageService.get("ACCESS_TOKEN") as string,
    }
  ) as string;

  let alreadyConnectedBoxText = FDN.I18n.t("usersettings.microsoft.form.connected.box");

  if (teamsUserInfo?.teamsDisplayname)
    alreadyConnectedBoxText = alreadyConnectedBoxText.replace(
      "{teamsName}",
      teamsUserInfo.teamsDisplayname
    );

  if (teamsUserInfo?.teamsEmail)
    alreadyConnectedBoxText = alreadyConnectedBoxText.replace(
      "{teamsEmail}",
      teamsUserInfo.teamsEmail
    );

  return (
    <>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={12}>
            <FDN.FormGroup title={FDN.I18n.t("usersettings.microsoft.form.connection.title")}>
              {userIsAlreadyConnected ? (
                <>
                  <FDN.CalloutBox type="success">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: alreadyConnectedBoxText,
                      }}
                    />
                  </FDN.CalloutBox>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: FDN.I18n.t("usersettings.microsoft.form.connected.intro"),
                    }}
                  />
                  <div className="text-center">
                    <a
                      href={disconnectUrl}
                      className="primary hollow button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (window.confirm(FDN.I18n.t("usersettings.microsoft.disconnect.confirm")))
                          window.location.href = disconnectUrl;
                      }}
                    >
                      <FDN.Icon icon="ban" left />
                      {FDN.I18n.t("usersettings.microsoft.form.connected.disconnect.button.label")}
                    </a>{" "}
                    <a href={connectUrl} className="primary button">
                      <FDN.Icon icon="refresh" left />
                      {FDN.I18n.t("usersettings.microsoft.form.connected.button.label")}
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: FDN.I18n.t("usersettings.microsoft.form.notconnected.intro"),
                    }}
                  />
                  <div className="text-center">
                    <a href={connectUrl} className="primary button">
                      <FDN.Icon icon="plus" left />
                      {FDN.I18n.t("usersettings.microsoft.form.notconnected.button.label")}
                    </a>
                  </div>
                </>
              )}
            </FDN.FormGroup>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </>
  );
};

export default TabMicrosoftTeams;
