import * as React from "react";
import * as FDN from "src/core";
import { useNavigate } from "react-router-dom";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { SocketContext } from "src/components/context/SocketContext/SocketContext";
import AdminHeader from "../layouts/AdminHeader";
import AdminLayout from "../layouts/AdminLayout";
import UrlService from "src/core/UrlService";
import AccountUserInfo from "./tabs/AccountUserInfo";
import AccountInfo from "./tabs/AccountInfo";
import useServiceAdminAccountService from "src/services/admin/AccountService";
import Logs from "../log/Logs";
import AccountPermissions from "./tabs/AccountPermissions";
import { userHasPermission } from "src/core/AdminService/helpers";
import Config from "src/core/Config";

const tabs: { [key: string]: FDN.ITabsTab } = {
  info: {
    label: FDN.I18n.t("adminAccounts.tabs.info.label"),
    icon: "id-card-o",
  },
  account: {
    label: FDN.I18n.t("adminAccounts.tabs.account.label"),
    icon: "user",
    hideOnNew: true,
    hideOnEdit: true,
  },
  permissions: {
    label: FDN.I18n.t("adminAccounts.tabs.permissions.label"),
    icon: "key",
    hideOnNew: true,
    hideOnEdit: true,
  },
  logs: {
    label: FDN.I18n.t("adminLogs.tabs.logs.label"),
    icon: "history",
    hideOnNew: true,
    hideOnEdit: true,
  },
};

const defaultTab = "info";

const AdminAccount: React.FunctionComponent = () => {
  const SOCKETIO = React.useContext(SocketContext);
  const APP = React.useContext(AppContext);
  const user = APP.getUser();

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = React.useState<string>(defaultTab);

  const {
    actions,
    account,
    editedProperties,
    sendConfirmationMailAfterCreate,
    editMode,
    menuItems,
  } = useServiceAdminAccountService();

  if (!account) return <FDN.Loading />;

  return (
    <AdminLayout page="admin-accounts" selectedNav="accounts">
      <AdminHeader
        backButton={{
          url: UrlService.url("admin.accounts.index"),
        }}
        title={
          account.identifier === "new" ? (
            FDN.I18n.t("adminAccounts.addAccount.title")
          ) : (
            <>
              {account.displayname}{" "}
              <FDN.OnlineStatusIndicator
                userIdentifier={account.identifier}
                privacyShowOnlineStatus={Config.get("privacy.showOnlineStatus") as boolean}
                SOCKETIO={SOCKETIO}
                I18n={FDN.I18n}
              />{" "}
            </>
          )
        }
        buttons={[
          <>
            {userHasPermission(user, "adminAccountsEdit") && editMode === false ? (
              <button className="primary button" onClick={() => actions.toggleEditMode()}>
                <FDN.Icon icon="pencil-square-o" left />{" "}
                {FDN.I18n.t("adminAccounts.buttons.edit.label")}
              </button>
            ) : null}
          </>,
          <>
            {editMode === false && menuItems ? (
              <FDN.DropdownButton activationMode="click" menuItems={menuItems} />
            ) : null}
          </>,
        ]}
      />
      {editMode === true && (
        <FDN.FormButtons
          onCancel={account.identifier === "new" ? actions.onCancelNew : actions.onCancel}
          onSave={actions.onSave}
        />
      )}
      <FDN.Tabs
        tabs={tabs}
        selected={selectedTab}
        isNew={account.identifier === "new"}
        editMode={editMode}
        updateHistory={true}
        onSelect={(tabKey) => setSelectedTab(tabKey)}
        onUpdateUrl={(params) => navigate({ search: params })}
      >
        {selectedTab === "info" && (
          <AccountUserInfo
            account={account}
            sendConfirmationMailAfterCreate={sendConfirmationMailAfterCreate}
            editMode={editMode}
            editedProperties={editedProperties}
            actions={actions}
          />
        )}
        {selectedTab === "account" && (
          <AccountInfo account={account} editMode={editMode} onUpdate={actions.onUpdate} />
        )}
        {selectedTab === "permissions" && (
          <AccountPermissions account={account} editMode={editMode} onUpdate={actions.onUpdate} />
        )}
        {selectedTab === "logs" && (
          <Logs type="user" affectedIdentifier={account.identifier} affectedObject={account} />
        )}
      </FDN.Tabs>
      {editMode === true && (
        <FDN.FormButtons
          onCancel={account.identifier === "new" ? actions.onCancelNew : actions.onCancel}
          onSave={actions.onSave}
          marginBottom="20px"
        />
      )}
    </AdminLayout>
  );
};

export default AdminAccount;
