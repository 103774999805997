import * as React from "react";
import * as FDN from "src/core";
import { BvStatusResult, IBauvorhaben } from "src/types/types";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import StatusTermine from "./StatusTermine";
import StatusDetails from "./StatusDetails";
import StatusVermessung from "./StatusVermessung";
import StatusFenster from "./StatusFenster";
import StatusRohbauCheck from "./StatusRohbauCheck";
import StatusFertigungsbuergschaften from "./StatusFertigungsbuergschaften";
import BvWaermepumpe from "../../parts/BvWaermepumpe";
import BvFortschritt from "../../parts/BvFortschritt";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IBvStatusStatusProps {
  bauvorhaben: IBauvorhaben;
  bvStatus: BvStatusResult;
  actions: TActionsBauvorhaben;
}

const BvStatusStatus: React.FunctionComponent<IBvStatusStatusProps> = ({
  bauvorhaben,
  bvStatus,
  actions,
}) => {
  const APP = React.useContext(AppContext);
  const userView = APP.userView;

  return (
    <div className="__cc-bauvorhaben-status-status">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={8}>
            <StatusTermine termine={bvStatus.termine} />
            <BvWaermepumpe
              details={bvStatus.details}
              link={userView === "cc" ? "tab=bauvorbereitung" : undefined}
              editMode={false}
            />
            <BvFortschritt
              details={bvStatus.details}
              link={userView === "cc" ? "tab=bauvorbereitung" : undefined}
              editMode={false}
            />
          </FDN.Cell>
          <FDN.Cell sm={24} md={8}>
            <StatusVermessung vermessung={bvStatus.vermessung} />
            <StatusFenster fenster={bvStatus.fenster} />
            <StatusRohbauCheck rohbauCheck={bvStatus.rohbauCheck} />
            <StatusFertigungsbuergschaften
              fertigungsbuergschaften={bvStatus.fertigungsbuergschaften}
            />
          </FDN.Cell>
          <FDN.Cell sm={24} md={8}>
            <StatusDetails details={bvStatus.details} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default BvStatusStatus;
