import * as React from "react";
import * as FDN from "src/core";
import useServiceCCBauvorhaben, { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import CCLayout from "../layouts/CCLayout";
import UrlService from "src/core/UrlService";
import BauvorhabenHeader from "./layout/BauvorhabenHeader";
import BauvorhabenTabs from "./layout/BauvorhabenTabs";
import BauvorhabenContent from "./layout/BauvorhabenContent";
import useServiceCCBauvorhabenTabs from "src/services/cc/CCBauvorhabenTabsService";
import { Link } from "react-router-dom";
import JumpToBauvorhaben from "./parts/JumpToBauvorhaben";

const CCBauvorhaben: React.FunctionComponent = () => {
  const { bauvorhaben, editMode, actions } = useServiceCCBauvorhaben();
  const { tabs, selectedTab, onSelectTab } = useServiceCCBauvorhabenTabs();

  actions.onSelectTab = onSelectTab;

  if (!bauvorhaben) return <FDN.Loading />;

  return (
    <CCLayout page="cc-bauvorhaben">
      <div className="__cc-bauvorhaben-aboveheader">
        <div className="__cc-bauvorhaben-aboveheader-left">
          <div className="__cc-bauvorhaben-aboveheader-backbutton">
            <Link
              to={UrlService.url("cc.bauvorhaben.index")}
              className="small primary white button"
            >
              <FDN.Icon icon="angle-double-left" left /> {FDN.I18n.t("adminAdmin.backButton.label")}
            </Link>
          </div>
          {editMode === false ? <JumpToBauvorhaben bauvorhaben={bauvorhaben} /> : null}
        </div>
        <div className="__cc-bauvorhaben-aboveheader-buttons">
          {!["dateien"].includes(selectedTab) && (
            <>
              {editMode === true ? (
                <FDN.FormButtons smallButtons onSave={actions.onSave} onCancel={actions.onCancel} />
              ) : (
                <BauvorhabenButtons actions={actions} />
              )}
            </>
          )}
        </div>
      </div>
      <div className="__cc-bauvorhaben-below-aboveheader">
        <BauvorhabenHeader bauvorhaben={bauvorhaben} />
        <div className="__cc-bauvorhaben-bv-layout">
          <BauvorhabenTabs
            tabs={tabs}
            selectedTab={selectedTab}
            onSelectTab={actions.onSelectTab}
          />
          <BauvorhabenContent
            location="CC"
            bauvorhaben={bauvorhaben}
            editMode={editMode}
            selectedTab={selectedTab}
            actions={actions}
          />
        </div>
      </div>
    </CCLayout>
  );
};

interface IBauvorhabenButtonsProps {
  actions: TActionsBauvorhaben;
}

const BauvorhabenButtons: React.FunctionComponent<IBauvorhabenButtonsProps> = ({ actions }) => {
  return (
    <div className="__cc-bauvorhaben-buttons">
      <button className="small primary button" onClick={() => actions.setEditMode(true)}>
        <FDN.Icon icon="edit" left /> {FDN.I18n.t("buttons.edit")}
      </button>
    </div>
  );
};

export default CCBauvorhaben;
