import * as React from "react";
import * as FDN from "src/core";
import { IFileBrowserFile, TActions } from "src/types/types";
import FileInfoPreview from "./FileInfoPreview";

import FileIcon from "./FileIcon";
import { userHasAtLeastOnePermission } from "src/core/AdminService/helpers";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { formatDateTime } from "src/core/helpers/helpers";

interface IFileInfoInfoProps {
  file: IFileBrowserFile;
  lastSync?: Date;
  actions: TActions;
}

const FileInfoInfo: React.FunctionComponent<IFileInfoInfoProps> = ({ file, lastSync, actions }) => {
  const APP = React.useContext(AppContext);
  const user = APP.getUser();

  return (
    <div className="__filebrowser-fileinfo-info">
      <FileInfoPreview file={file} actions={actions} />
      <h3>
        <FileIcon item={file} /> {file.name}
      </h3>
      <div className="__filebrowser-fileinfo-filesystem-dates">
        <FDN.Box small>
          <h4>{FDN.I18n.t("filebrowser.fileInfo.group.created.title")}</h4>
          <div>{formatDateTime(file._teamsCreationDate)}</div>{" "}
          {FDN.I18n.t("filebrowser.fileInfo.group.created.by")} {file._teamsCreatedBy}
        </FDN.Box>
        <FDN.Box small>
          <h4>{FDN.I18n.t("filebrowser.fileInfo.group.modified.title")}</h4>
          <div>{formatDateTime(file._teamsLastModified)}</div>{" "}
          {FDN.I18n.t("filebrowser.fileInfo.group.created.by")} {file._teamsLastModifiedBy}
        </FDN.Box>
      </div>
      <div className="__filebrowser-fileinfo-actions">
        {file.downloadUrl && (
          <a
            href={file.downloadUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="small primary hollow button"
          >
            <FDN.Icon icon="download" left />{" "}
            {FDN.I18n.t("filebrowser.fileInfo.actions.download.label")}
          </a>
        )}
        {userHasAtLeastOnePermission(user, ["ccBauvorhabenView", "ccBauvorhabenOwn"]) &&
        file.webUrl ? (
          <a
            href={file.webUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="small primary hollow button"
          >
            <FDN.Icon icon="share" left />{" "}
            {FDN.I18n.t("filebrowser.fileInfo.actions.sharepoint.label")}
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default FileInfoInfo;
