import * as React from "react";
import useServiceUserNotifications from "src/services/UserNotificationsService";
import UserNotifications from "../usernotifications/UserNotifications";
import { IUserNotifications } from "../context/NotificationsContext/types";
import * as FDN from "src/core";

const NotificationsButton: React.FunctionComponent = () => {
  const { APP, NOTIFICATIONS, userNotifications, buttonRef, menuRef, menuKey, actions } =
    useServiceUserNotifications();

  const user = APP.getUser();
  if (!user || !user.identifier) return null;

  const menuStatus = NOTIFICATIONS.getMenuStatus(menuKey);

  return (
    <>
      <div className="__topbar-notifications-button-wrapper">
        <button
          ref={buttonRef}
          className="__topbar-notifications-button"
          onClick={() => actions.toggleMenu()}
        >
          <div className="__topbar-notifications-button-content-wrapper">
            <FDN.Icon icon="bell-o" />
            {userNotifications && <NotificationsBadge userNotifications={userNotifications} />}
          </div>
        </button>
      </div>
      {menuStatus === true && (
        <UserNotifications
          userNotifications={userNotifications}
          menuRef={menuRef}
          actions={actions}
        />
      )}
    </>
  );
};

interface INotificationsBadge {
  userNotifications: IUserNotifications;
}

const NotificationsBadge: React.FunctionComponent<INotificationsBadge> = ({
  userNotifications,
}) => {
  if (userNotifications.unread === 0) return null;
  return (
    <span className="__topbar-badge __usernotifications-badge">
      {userNotifications.unread > 99 ? `99+` : userNotifications.unread}
    </span>
  );
};

export default NotificationsButton;
