import React, { useState } from "react";
import * as FDN from "src/core";
import {
  IBauvorhaben,
  IBauvorhabenList,
  ICategorizedBauvorhabenList,
  TActions,
} from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import CCApi from "src/api/CCApi";
import { cloneDeep } from "lodash";
import UrlService from "src/core/UrlService";
import { getValidationMissingFields } from "src/core/helpers/helpers";

const initTabs: FDN.ITabs = {
  PLANUNG: {
    label: FDN.I18n.t("ccBauvorhaben.list.tabs.PLANUNG.label"),
    icon: "signal",
  },
  PBFEHLT: {
    label: FDN.I18n.t("ccBauvorhaben.list.tabs.PBFEHLT.label"),
    icon: "exclamation-triangle",
  },
  LAUFEND: {
    label: FDN.I18n.t("ccBauvorhaben.list.tabs.LAUFEND.label"),
    icon: "clock-o",
  },
  UEBERGABEMIT: {
    label: FDN.I18n.t("ccBauvorhaben.list.tabs.UEBERGABEMIT.label"),
    icon: "wrench",
  },
  UEBERGABEOHNE: {
    label: FDN.I18n.t("ccBauvorhaben.list.tabs.UEBERGABEOHNE.label"),
    icon: "handshake-o",
  },
  ARCHIV: {
    label: FDN.I18n.t("ccBauvorhaben.list.tabs.ARCHIV.label"),
    icon: "archive",
  },
};

const defaultTab = "LAUFEND";

const useServiceCCBauvorhabenList = () => {
  const { NOTIFICATIONS, api } = useServiceCore();

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [bauvorhabenList, setBauvorhabenList] = useState<IBauvorhabenList>();
  const [virginBauvorhaben, setVirginBauvorhaben] = useState<IBauvorhaben>();
  const [newBauvorhaben, setNewBauvorhaben] = useState<IBauvorhaben>();
  const [missing, setMissing] = useState<string[]>([]);
  const [tabs, setTabs] = useState(initTabs);
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    CCApi.ccBauvorhabenList(api).then((response) => {
      if (response?.body?.bauvorhabenList) {
        const list = response?.body?.bauvorhabenList as IBauvorhabenList;
        setBauvorhabenList(list);

        const updatedTabs = cloneDeep(tabs);
        for (const tabKey of Object.keys(updatedTabs)) {
          const listItems = list.list[tabKey as keyof ICategorizedBauvorhabenList];
          if (listItems && listItems.length > 0)
            updatedTabs[tabKey as keyof typeof updatedTabs].badge = listItems.length;
          else updatedTabs[tabKey as keyof typeof updatedTabs].badge = "0";
        }
        setTabs(updatedTabs);
      }

      if (response?.body?.virginBauvorhaben) {
        setVirginBauvorhaben(response?.body?.virginBauvorhaben as IBauvorhaben);
        setNewBauvorhaben(cloneDeep(response?.body?.virginBauvorhaben as IBauvorhaben));
      }
    });
  };

  const onEdit = (property: string, value: any) => {
    if (!newBauvorhaben) return;
    newBauvorhaben[property] = value;
    setNewBauvorhaben(cloneDeep(newBauvorhaben));
    setUnsavedChanges(true);
  };

  const onCancelNew = () => {
    const doCancel = () => {
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSaveNew = () => {
    if (!newBauvorhaben) return;

    setMissing([]);

    CCApi.ccAddBauvorhaben(api, newBauvorhaben).then((response) => {
      if (response?.statusCode === StatusCode.INVALID_FORM_DATA) {
        setMissing(getValidationMissingFields(response?.body?.error));
      } else if (response?.statusCode === StatusCode.SUCCESS) {
        const bauvorhaben = response?.body?.bauvorhaben;
        const identifier = bauvorhaben?.identifier;

        window.location.href = UrlService.url("cc.bauvorhaben.show", { identifier });
      }
    });
  };

  const actions: TActions = {
    onEdit,
    onCancelNew,
    onSaveNew,
    setSelectedTab,
  };

  return {
    bauvorhabenList,
    newBauvorhaben,
    missing,
    tabs,
    selectedTab,
    virginBauvorhaben,
    actions,
  };
};

export default useServiceCCBauvorhabenList;
