import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import UrlService from "src/core/UrlService";
import { Link } from "react-router-dom";

const Footer: React.FunctionComponent = () => {
  return (
    <footer>
      <FDN.SingleRowCell margin="xy">
        <span
          dangerouslySetInnerHTML={{
            __html: Config.get("footer.copyright") as string,
          }}
        />{" "}
        &middot; <Link to={UrlService.url("legal")}>{FDN.I18n.t("main.legal")}</Link> &middot;{" "}
        <Link to={UrlService.url("dataprivacy")}>{FDN.I18n.t("main.dataprivacy")}</Link>
      </FDN.SingleRowCell>
    </footer>
  );
};

export default Footer;
