import * as React from "react";
import * as FDN from "src/core";
import { IBvFenster } from "src/types/types";
import BvProgressListBox from "../../parts/BvProgressList";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IBvFensterProps {
  fenster?: IBvFenster;
}

const BvFenster: React.FunctionComponent<IBvFensterProps> = ({ fenster }) => {
  if (!fenster) return <FDN.Loading box />;

  const APP = React.useContext(AppContext);
  const userView = APP.userView;

  return (
    <div className="__bv-fenster">
      <FDN.Box>
        <BvProgressListBox type="fenster" isEditable={userView === "cc"} fenster={fenster} />
      </FDN.Box>
    </div>
  );
};

export default BvFenster;
