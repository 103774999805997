import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben, IHausanschluesse } from "src/types/types";
import CCApi from "src/api/CCApi";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import { useNavigate } from "react-router-dom";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import BvHausanschluesse from "./hausanschluesse/BvHausanschluesse";
import useServiceCCBvHausanschluesse, {
  TActionsBauvorhabenHausanschluesse,
} from "src/services/cc/CCBvHausanschluesseService";

const tabs = {
  hausanschluesse: {
    label: FDN.I18n.t("ccBvHausanschluesse.tabs.hausanschluesse.label"),
    icon: "plug",
  },
};

const defaultTab = "hausanschluesse";

interface IBauvorhabenHausanschluesseProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BauvorhabenHausanschluesse: React.FunctionComponent<IBauvorhabenHausanschluesseProps> = ({
  bauvorhaben,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const {
    visibleTerminPopup,
    popupSettingsVisible,
    hausanschluesse,
    actions: hausanschluesseActions,
  } = useServiceCCBvHausanschluesse(actions);

  const moduleActions: TActionsBauvorhabenHausanschluesse = {
    ...actions,
    ...hausanschluesseActions,
  };

  React.useEffect(() => {
    if (!hausanschluesse) hausanschluesseActions.init(bauvorhaben);
  }, [bauvorhaben]);

  const navigate = useNavigate();

  if (!hausanschluesse) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-hausanschluesse">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">
        {FDN.I18n.t(`ccBvHausanschluesse.tabs.${selectedTab}.title`)}
      </h2>
      <FDN.TabsContent>
        {selectedTab === "hausanschluesse" && (
          <BvHausanschluesse
            popupSettingsVisible={popupSettingsVisible}
            visibleTerminPopup={visibleTerminPopup}
            hausanschluesse={hausanschluesse}
            actions={moduleActions}
          />
        )}
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenHausanschluesse;
