import * as React from "react";
import { AppContext } from "src/components/context/AppContext/AppContext";
import * as FDN from "src/core";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import useServiceCCBauvorhabenTabs from "src/services/cc/CCBauvorhabenTabsService";
import { IBauvorhaben } from "src/types/types";

interface IBvClientsContentProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BvClientsContent: React.FunctionComponent<IBvClientsContentProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  const { tabs } = useServiceCCBauvorhabenTabs();

  const { userView } = React.useContext(AppContext);

  if (userView !== "cc") return null;

  return (
    <div className="__cc-bauvorhaben-clients-content">
      <FDN.GridRow>
        <FDN.Cell md={12}>
          <FDN.FormGroup title={FDN.I18n.t("ccBvClients.content.title")}>
            <p>{FDN.I18n.t("ccBvClients.content.text")}</p>
            {tabs.map((tabsBlock, index) => (
              <React.Fragment key={index}>
                <ul>
                  {Object.keys(tabsBlock).map((tabKey) => {
                    const tab = tabsBlock[tabKey];
                    return (
                      <li key={tabKey}>
                        <FDN.Switch
                          value={bauvorhaben?.tabsForClients?.includes(tabKey)}
                          editMode={editMode}
                          label={tab.label as string}
                          labelPosition="right"
                          size="medium"
                          onUpdate={() => actions.toggleTabsForClients(tabKey)}
                        />
                      </li>
                    );
                  })}
                </ul>
              </React.Fragment>
            ))}
          </FDN.FormGroup>
          <FDN.CalloutBox type="warning">
            <div>
              <strong>{FDN.I18n.t("ccBvClients.content.warning.title")}</strong>
            </div>
            <div>{FDN.I18n.t("ccBvClients.content.warning.text")}</div>
          </FDN.CalloutBox>
        </FDN.Cell>
      </FDN.GridRow>
    </div>
  );
};

export default BvClientsContent;
