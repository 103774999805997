import { useState } from "react";
import {
  BvDetailTypeDate,
  BvDetailTypePercent,
  BvDetailTypeText,
  BvDetailTypeTrafficLight,
  BvDetailTypeYesNo,
  IBauvorhaben,
  IBvDetail,
  IBvFenster,
  IBvFertigungsbuergschaft,
  IBvRohbauCheck,
  IBvVermessung,
  TFunction,
} from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import CCApi from "src/api/CCApi";
import { cloneDeep } from "lodash";
import { TActionsBauvorhaben } from "./CCBauvorhabenService";

export interface TActionsBauvorhabenBauvorbereitung extends TActionsBauvorhaben {
  init: TFunction;
  onEditDetail: <P extends keyof IBvDetail, V extends IBvDetail[P]>(property: P, value: V) => void;
  updateSingleDetail: (
    detailIdentifier: string,
    value:
      | BvDetailTypeDate
      | BvDetailTypePercent
      | BvDetailTypeText
      | BvDetailTypeTrafficLight
      | BvDetailTypeYesNo
  ) => void;
}

const useServiceCCBvBauvorbereitung = (bvActions: TActionsBauvorhaben) => {
  const { api, APP } = useServiceCore();

  const [bauvorhaben, setBauvorhaben] = useState<IBauvorhaben>();
  const [details, setDetails] = useState<IBvDetail>();
  const [vermessung, setVermessung] = useState<IBvVermessung>();
  const [fenster, setFenster] = useState<IBvFenster>();
  const [rohbauCheck, setRohbauCheck] = useState<IBvRohbauCheck>();
  const [fertigungsbuergschaften, setFertigungsbuergschaften] =
    useState<IBvFertigungsbuergschaft>();

  const init = (bauvorhaben: IBauvorhaben) => {
    setBauvorhaben(cloneDeep(bauvorhaben));
    CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "bauvorbereitung").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setDetails(response?.body?.details as IBvDetail);
        setVermessung(response?.body?.vermessung as IBvVermessung);
        setFenster(response?.body?.fenster as IBvFenster);
        setRohbauCheck(response?.body?.rohbauCheck as IBvRohbauCheck);
        setFertigungsbuergschaften(
          response?.body?.fertigungsbuergschaften as IBvFertigungsbuergschaft
        );
        APP.setPageTitle(bauvorhaben.name);
      }
    });
  };

  function onEditDetail<P extends keyof IBvDetail, V extends IBvDetail[P]>(property: P, value: V) {
    if (!details) return;

    details[property] = value;
    setDetails(cloneDeep(details));
    bvActions.onEdit("details", cloneDeep(details));
  }

  const updateSingleDetail = (
    detailIdentifier: string,
    value:
      | BvDetailTypeDate
      | BvDetailTypePercent
      | BvDetailTypeText
      | BvDetailTypeTrafficLight
      | BvDetailTypeYesNo
  ) => {
    if (!bauvorhaben) return;

    CCApi.ccBvUpdateSingleDetail(api, bauvorhaben.identifier, detailIdentifier, value).then(
      (response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          setDetails(response?.body?.bvDetails);
        }
      }
    );
  };

  const actions: TActionsBauvorhabenBauvorbereitung = {
    ...bvActions,
    init,
    onEditDetail,
    updateSingleDetail,
  };

  return { details, vermessung, fenster, rohbauCheck, fertigungsbuergschaften, actions };
};

export default useServiceCCBvBauvorbereitung;
