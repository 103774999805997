import { isArray } from "lodash";
import * as React from "react";
import * as FDN from "src/core";
import { IBvDetail } from "src/types/types";
import BvDetail from "../../parts/BvDetail";
import { bvDetailsMapping } from "src/config/bauvorhaben";
import { Link } from "react-router-dom";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IStatusDetailsProps {
  details?: IBvDetail;
}

const StatusDetails: React.FunctionComponent<IStatusDetailsProps> = ({ details }) => {
  const [view, setView] = React.useState<"default" | "priority">("default");

  if (!details) return <FDN.Loading box />;

  const APP = React.useContext(AppContext);
  const userView = APP.userView;

  return (
    <div className="__bv-status-details">
      <FDN.Box noPadding>
        <div className="__box-padding">
          <h3 style={{ marginBottom: "0" }}>
            {userView === "cc" && (
              <div className="float-right button-goto-tab">
                <Link to={`?tab=bauvorbereitung`}>
                  <FDN.Icon icon="share-square-o" />
                </Link>
              </div>
            )}

            {FDN.I18n.t("ccBvStatus.details.title")}
          </h3>
        </div>
        <div className="__box-padding">
          {view === "default" && <ViewDefault details={details} />}
        </div>
      </FDN.Box>
    </div>
  );
};

const ViewDefault: React.FunctionComponent<IStatusDetailsProps> = ({ details }) => {
  if (!details) return null;

  let previousItemWasGroup = false;

  return (
    <div className="__bv-status-details-view-default">
      {bvDetailsMapping.map((mapValue, index) => {
        if (isArray(mapValue)) {
          const content = (
            <React.Fragment key={index}>
              {previousItemWasGroup === false && <DetailSeparator />}
              {mapValue.map((mapValueChild, childIndex) => {
                return (
                  <BvDetail
                    parent="details"
                    bvIdentifier={details.bvIdentifier}
                    type={mapValueChild}
                    value={details[mapValueChild as keyof typeof details]}
                    isEditable={false}
                    key={childIndex}
                  />
                );
              })}
              {index !== bvDetailsMapping.length - 1 && <DetailSeparator />}
            </React.Fragment>
          );

          previousItemWasGroup = true;

          return content;
        } else {
          previousItemWasGroup = false;
          return (
            <BvDetail
              parent="details"
              bvIdentifier={details.bvIdentifier}
              value={details[mapValue as keyof typeof details]}
              type={mapValue}
              isEditable={false}
              key={index}
            />
          );
        }
      })}
    </div>
  );
};

const DetailSeparator: React.FunctionComponent = () => {
  return (
    <div className="__bv-status-details-separator">
      <hr />
    </div>
  );
};

export default StatusDetails;
