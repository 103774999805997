import * as React from "react";
import * as FDN from "src/core";
import { IBvFenster, IBvVermessung } from "src/types/types";
import BvProgressListBox from "../../parts/BvProgressList";
import UrlService from "src/core/UrlService";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IStatusFensterProps {
  fenster?: IBvFenster;
}

const StatusFenster: React.FunctionComponent<IStatusFensterProps> = ({ fenster }) => {
  if (!fenster) return <FDN.Loading box />;

  const APP = React.useContext(AppContext);
  const userView = APP.userView;

  return (
    <div className="__bv-status-fenster">
      <FDN.Box>
        <BvProgressListBox
          type="fenster"
          isEditable={false}
          fenster={fenster}
          link={userView === "cc" ? "tab=bauvorbereitung" : undefined}
        />
      </FDN.Box>
    </div>
  );
};

export default StatusFenster;
