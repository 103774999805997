import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben, IBvClients } from "src/types/types";
import AddClientPopup from "./AddClientPopup";
import useServiceCore from "src/services/CoreService";
import { cloneDeep } from "lodash";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IBvClientsProps {
  bauvorhaben: IBauvorhaben;
  clients: IBvClients;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BvClients: React.FunctionComponent<IBvClientsProps> = ({
  bauvorhaben,
  clients,
  editMode,
  actions,
}) => {
  const [addClientPopupVisible, setAddClientPopupVisible] = React.useState(false);
  const [accessToDelete, setAccessToDelete] = React.useState<{ [key: string]: any }>();

  const { NOTIFICATIONS, APP } = useServiceCore();
  const { userView } = APP;

  React.useEffect(() => {
    if (!accessToDelete) return;

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("ccBvClients.deleteAccess.dialog.confirm", {
        name: accessToDelete.displayname,
        email: accessToDelete.email,
      }),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
            setAccessToDelete(undefined);
          },
        },
        {
          type: "ok",
          onClick: () => {
            actions.revokeAccess(accessToDelete.identifier, () => {
              setAccessToDelete(undefined);
              NOTIFICATIONS.hideDialog();
            });
          },
        },
      ],
    });
  }, [accessToDelete]);

  const onClose = () => {
    setAddClientPopupVisible(false);
  };

  const onDelete = (client: { [key: string]: any }) => {
    setAccessToDelete(cloneDeep(client));
  };

  actions.onDelete = onDelete;

  return (
    <>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={12}>
            <FDN.FormGroup title={FDN.I18n.t("ccBvClients.clients.group.title")}>
              <p>{FDN.I18n.t("ccBvClients.clients.group.text")}</p>

              {!bauvorhaben || !bauvorhaben.clients || bauvorhaben.clients.length === 0 ? (
                <div className="text-center">
                  <em>{FDN.I18n.t("ccBvClients.noClients")}</em>
                </div>
              ) : (
                <BvClientsList
                  bauvorhaben={bauvorhaben}
                  clients={clients}
                  editMode={editMode}
                  actions={actions}
                />
              )}
              {userView === "cc" && (
                <button
                  className="button-add-client"
                  onClick={() => setAddClientPopupVisible(true)}
                >
                  <FDN.Icon icon="plus" left /> {FDN.I18n.t("ccBvClients.buttons.addClient")}
                </button>
              )}
            </FDN.FormGroup>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      {addClientPopupVisible === true && userView === "cc" && (
        <AddClientPopup
          bauvorhaben={bauvorhaben}
          onClose={onClose}
          onSuccess={(bauvorhaben) => {
            actions.onUpdateBauvorhaben(bauvorhaben);
            setAddClientPopupVisible(false);
          }}
        />
      )}
    </>
  );
};

const BvClientsList: React.FunctionComponent<IBvClientsProps> = ({
  bauvorhaben,
  clients,
  actions,
}) => {
  const { userView } = React.useContext(AppContext);

  return (
    <ul className="__cc-bauvorhaben-clients-list">
      {bauvorhaben.clients.map((clientIdentifier: string, index: number) => {
        const client = clients.find((c) => c.identifier === clientIdentifier);
        if (!client) return null;
        return (
          <li key={clientIdentifier}>
            <div>{index + 1}.</div>
            <div>
              <div className="__cc-bauvorhaben-clients-list-clientname">{client.displayname}</div>
              <div className="__cc-bauvorhaben-clients-list-clientemail">{client.email}</div>
            </div>
            <div>
              {userView === "cc" && (
                <button onClick={() => actions.onDelete && actions.onDelete(client)}>
                  <FDN.Icon icon="times" />
                </button>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default BvClients;
