import * as React from "react";
import * as FDN from "src/core";
import { IBvRohbauCheck } from "src/types/types";
import BvProgressListBox from "../../parts/BvProgressList";
import UrlService from "src/core/UrlService";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IBvRohbauCheckProps {
  rohbauCheck?: IBvRohbauCheck;
}

const BvRohbauCheck: React.FunctionComponent<IBvRohbauCheckProps> = ({ rohbauCheck }) => {
  if (!rohbauCheck) return <FDN.Loading box />;

  const APP = React.useContext(AppContext);
  const userView = APP.userView;

  return (
    <div className="__bv-rohbauCheck">
      <FDN.Box>
        <BvProgressListBox
          type="rohbauCheck"
          isEditable={userView === "cc"}
          rohbauCheck={rohbauCheck}
        />
      </FDN.Box>
    </div>
  );
};

export default BvRohbauCheck;
