import * as React from "react";
import * as FDN from "src/core";
import { IFileBrowserFile, IFileBrowserFolder, IMstFolder, TActions } from "src/types/types";
import FileIcon from "./parts/FileIcon";

interface IFolderFilesListProps {
  mstFolder: IMstFolder;
  selectedFile?: IFileBrowserFile;
  actions: TActions;
}

const FolderFilesList: React.FunctionComponent<IFolderFilesListProps> = ({
  mstFolder,
  selectedFile,
  actions,
}) => {
  if (!mstFolder.items) return null;

  return (
    <div className="__filebrowser-list">
      <ul>
        <GoToParentFolder mstFolder={mstFolder} actions={actions} />
        {mstFolder.items.map((driveItem) => {
          return (
            <li key={driveItem.identifier}>
              {driveItem.type === "FOLDER" && <Folder folder={driveItem} actions={actions} />}
              {driveItem.type === "FILE" && (
                <File file={driveItem} selectedFile={selectedFile} actions={actions} />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

interface IFolderProps {
  folder: IFileBrowserFolder;
  actions: TActions;
}

const Folder: React.FunctionComponent<IFolderProps> = ({ folder, actions }) => {
  return (
    <button
      className={`__filebrowser-list-item`}
      onClick={() => actions.openFolder(folder._parentDriveId, folder._teamsId)}
    >
      <FileIcon item={folder} /> <div className="__filebrowser-list-item-name">{folder.name}</div>
    </button>
  );
};

interface IFileProps {
  file: IFileBrowserFile;
  actions: TActions;
  selectedFile?: IFileBrowserFile;
}

const File: React.FunctionComponent<IFileProps> = ({ file, actions, selectedFile }) => {
  return (
    <button
      className={`${
        selectedFile?.identifier === file.identifier ? `selected` : ` `
      } __filebrowser-list-item`}
      onClick={() => actions.selectFile(file)}
    >
      <FileIcon item={file} /> <div className="__filebrowser-list-item-name">{file.name}</div>
      <div className="__filebrowser-list-item-file-arrow">
        <FDN.Icon icon="angle-right" />
      </div>
    </button>
  );
};

interface IGoToParentFolderProps {
  mstFolder: IMstFolder;
  actions: TActions;
}

const GoToParentFolder: React.FunctionComponent<IGoToParentFolderProps> = ({
  mstFolder,
  actions,
}) => {
  if (!mstFolder.breadcrumbs || mstFolder.breadcrumbs.length < 2) return null;

  const parentFolder = mstFolder.breadcrumbs[mstFolder.breadcrumbs.length - 2];

  return (
    <li>
      <button
        className={`__filebrowser-list-item go-to-parent-item`}
        onClick={() => actions.openFolder(mstFolder._driveId, parentFolder._folderId)}
      >
        <FileIcon back={true} />
      </button>
    </li>
  );
};

export default FolderFilesList;
