import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { TActionsBauvorhabenPaymentPlan } from "src/services/cc/CCBvPaymentPlanService";
import { IBvInstallment } from "src/types/types";

interface IEditMehrMinderPopupProps {
  installment: IBvInstallment;
  actions: TActionsBauvorhabenPaymentPlan;
}

const EditMehrMinderPopup: React.FunctionComponent<IEditMehrMinderPopupProps> = ({
  installment,
  actions,
}) => {
  if (installment.type !== "mehr" && installment.type !== "minder") return null;

  const currency = Config.get("currency") as string;

  const editMode =
    installment.status === "APPROVED" ||
    installment.status === "INVOICED" ||
    installment.status === "PAID"
      ? false
      : true;

  return (
    <div className="paymentplan-edit-installment">
      <FDN.Popup
        size="medium"
        title={
          installment.identifier === "new"
            ? FDN.I18n.t(`ccBvPaymentPlan.edit.${installment.type}.new.title`)
            : FDN.I18n.t(`ccBvPaymentPlan.edit.${installment.type}.edit.title`)
        }
        onClose={actions.onCancelEditInstallment}
      >
        {editMode === false ? (
          <FDN.CalloutBox type="warning">
            <div>
              <strong>
                {FDN.I18n.t(`ccBvPaymentPlan.edit.${installment.type}.editMode.false.title`)}
              </strong>
            </div>
            <div>{FDN.I18n.t(`ccBvPaymentPlan.edit.${installment.type}.editMode.false.text`)}</div>
          </FDN.CalloutBox>
        ) : null}
        <FDN.Box>
          <FDN.Grid full>
            <FDN.Row margin="xy">
              <FDN.Cell sm={24} md={16}>
                <FDN.Input
                  type="text"
                  value={installment.text}
                  label={FDN.I18n.t("ccBvPaymentPlan.edit.form.text.label")}
                  placeholder={FDN.I18n.t("ccBvPaymentPlan.edit.form.text.placeholder")}
                  editMode={editMode}
                  editable={true}
                  onUpdate={(value) => actions.onEditInstallment("text", value)}
                />
              </FDN.Cell>
              <FDN.Cell sm={24} md={8}>
                <FDN.Input
                  type="currency"
                  options={{ currency }}
                  value={installment.sum}
                  showValue={FDN.formatNumber(installment.sum || 0, 2, 2)}
                  label={FDN.I18n.t(`ccBvPaymentPlan.edit.${installment.type}.form.sum.label`)}
                  editMode={editMode}
                  editable={true}
                  onUpdate={(value) => actions.onEditInstallment("sum", value)}
                />
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </FDN.Box>

        {editMode && (
          <FDN.FormButtons
            smallButtons
            onDelete={() => actions.onDeleteInstallment(installment)}
            onCancel={actions.onCancelEditInstallment}
            onSave={actions.onSaveEditInstallment}
          />
        )}
      </FDN.Popup>
    </div>
  );
};

export default EditMehrMinderPopup;
