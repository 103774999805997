import * as FDN from "src/core";
import {
  formatDate as ptfdnFormatDate,
  formatTime as ptfdnFormatTime,
  formatDateTime as ptfdnFormatDateTime,
  TFormatDateTimeDate,
  IFormatDateOptions,
  IFormatTimeOptions,
  IFormatDateTimeOptions,
} from "@tschirpke/ptfdn-frontend-components";
import { isArray } from "lodash";

/**
 *
 * HELPER FOR DATES AND TIMES
 *
 */

export const formatDate = (date: TFormatDateTimeDate, options?: IFormatDateOptions): string => {
  let dateStr = ptfdnFormatDate(date, options);
  dateStr = dateStrI18n(dateStr);
  return dateStr;
};

export const formatTime = (date: TFormatDateTimeDate, options?: IFormatTimeOptions): string => {
  return ptfdnFormatTime(date, options);
};

export const formatDateTime = (
  date: TFormatDateTimeDate,
  options?: IFormatDateTimeOptions
): string => {
  let dateStr = ptfdnFormatDateTime(date, options);
  dateStr = dateStrI18n(dateStr);
  return dateStr;
};

const dateStrI18n = (dateStr: string): string => {
  dateStr = dateStr.replace("Today", FDN.I18n.t(`main.date.today`));
  dateStr = dateStr.replace("Tomorrow", FDN.I18n.t(`main.date.tomorrow`));
  dateStr = dateStr.replace("Yesterday", FDN.I18n.t(`main.date.yesterday`));
  return dateStr;
};

/**
 *
 * HELPER FOR JOI VALIDATION FROM API SERVER
 *
 */

export const getValidationMissingFields = (joiValidationError?: {
  [key: string]: any;
}): string[] => {
  const missing: string[] = [];

  if (joiValidationError && joiValidationError.details && isArray(joiValidationError.details)) {
    for (const detail of joiValidationError.details) {
      if (detail.context?.key) missing.push(detail.context.key as string);
    }
  }

  return missing;
};
