import * as React from "react";
import * as FDN from "src/core";
import { IBvVermessung } from "src/types/types";
import BvProgressListBox from "../../parts/BvProgressList";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IBvVermessungProps {
  vermessung?: IBvVermessung;
}

const BvVermessung: React.FunctionComponent<IBvVermessungProps> = ({ vermessung }) => {
  if (!vermessung) return <FDN.Loading box />;

  const APP = React.useContext(AppContext);
  const userView = APP.userView;

  return (
    <div className="__bv-vermessung">
      <FDN.Box>
        <BvProgressListBox
          type="vermessung"
          isEditable={userView === "cc"}
          vermessung={vermessung}
        />
      </FDN.Box>
    </div>
  );
};

export default BvVermessung;
