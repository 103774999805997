import * as React from "react";
import * as FDN from "src/core";
import { BvLocation, ITodoTask } from "src/types/types";
import Conversation from "../../../conversation/Conversation";

interface ITaskCommentsProps {
  task: ITodoTask;
  location: BvLocation;
}

const TaskComments: React.FunctionComponent<ITaskCommentsProps> = ({ task, location }) => {
  return (
    <div className="__cc-bauvorhaben-aufgaben-comments">
      <h3>{FDN.I18n.t("ccBvAufgaben.comments.title")}</h3>
      <div>&nbsp;</div>
      <div className="__cc-bauvorhaben-aufgaben-comments-posts">
        <Conversation location={location} conversation={task.conversation} />
      </div>
    </div>
  );
};

export default TaskComments;
