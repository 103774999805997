import { useEffect } from "react";
import StatusCode from "../../config/statuscodes";
import UrlService from "../../core/UrlService";
import { IBauvorhabenMinimumInfo } from "../../types/types";
import useServiceCore from "../CoreService";

export const useServiceHome = () => {
  const { api } = useServiceCore();

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    api.get("hello").then((response) => {
      const roles = response?.user?.roles;
      const permissions = response?.user?.permissions;

      if (response?.statusCode === StatusCode.SUCCESS) {
        if (roles?.includes("CLIENT")) {
          if (response?.body?.ownBauvorhaben) {
            const ownBauvorhaben = response.body.ownBauvorhaben as IBauvorhabenMinimumInfo[];
            if (ownBauvorhaben.length > 0) {
              const firstBauvorhaben = ownBauvorhaben[0];
              window.location.href = UrlService.url("home.bauvorhaben", {
                identifier: firstBauvorhaben.identifier,
              });
              return;
            }
          }

          //window.location.href = UrlService.url("home.index");
          return;
        } else if (permissions?.adminPermissions?.includes("ccDashboard")) {
          window.location.href = UrlService.url("cc.dashboard");
          return;
        } else {
          window.location.href = UrlService.url("auth.login");
        }
      }

      return;
    });
  };

  return { init };
};
