import * as React from "react";
import * as FDN from "src/core";
import UserApi from "src/api/UserApi";
import useServiceCore from "src/services/CoreService";

const LanguageSwitchButton: React.FunctionComponent = () => {
  const { APP, api } = useServiceCore();
  const defaultLanguageKey = APP.getSetting("defaultLanguage");
  const allowLanguageSwitch = APP.getSetting("allowLanguageSwitch");
  const appI18n = APP.getI18n();

  const selectLanguage = (languageKey: string) => {
    console.log(`Switching to language [${languageKey}]`);
    UserApi.updateUserSetting(api, "language", languageKey).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="__topbar-notifications-button-wrapper">
      <FDN.LanguageSwitch
        appI18n={appI18n}
        I18n={FDN.I18n}
        activeLanguageKey={FDN.I18n.getLanguage()}
        defaultLanguageKey={defaultLanguageKey}
        allowLanguageSwitch={allowLanguageSwitch}
        location="topbar"
        onSelectLanguage={selectLanguage}
      />
    </div>
  );
};

export default LanguageSwitchButton;
