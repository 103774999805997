import * as React from "react";
import * as FDN from "src/core";
import { Link } from "react-router-dom";
import AccountsList from "./parts/AccountsList";
import AdminHeader from "../layouts/AdminHeader";
import AdminLayout from "../layouts/AdminLayout";
import { userHasPermission } from "src/core/AdminService/helpers";
import UrlService from "src/core/UrlService";
import useServiceAdminAccountsService from "src/services/admin/AccountsService";
import AccountSearch from "./parts/AccountSearch";

const AdminAccounts: React.FunctionComponent = () => {
  const { actions, accounts, user, search, totalFound, sortOptions, listLoading } =
    useServiceAdminAccountsService();

  if (!accounts) return <FDN.Loading />;

  return (
    <AdminLayout page="admin-accounts" selectedNav="accounts">
      <AdminHeader
        title={FDN.I18n.t("adminAccounts.header.title")}
        buttons={[
          <>
            {userHasPermission(user, "adminAccountsAdd") && (
              <Link
                to={UrlService.url("admin.accounts.account", { identifier: "new" })}
                className="primary button"
              >
                <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminAccounts.buttons.add.label")}
              </Link>
            )}
          </>,
        ]}
      />
      <AccountSearch search={search} actions={actions} />
      {listLoading === true ? (
        <FDN.Loading box />
      ) : (
        <>
          {totalFound !== undefined && <TotalFound totalFound={totalFound} />}
          <AccountsList
            accounts={accounts}
            sortBy={search.sort}
            sortOptions={sortOptions}
            sortOnSelect={actions.onSortSelect}
          />
        </>
      )}
    </AdminLayout>
  );
};

interface ITotalFoundProps {
  totalFound?: number;
}

const TotalFound: React.FunctionComponent<ITotalFoundProps> = ({ totalFound }) => {
  return (
    <div className="__admin-total-found">
      {FDN.I18n.t("adminAccounts.search.accountsCount.text", {
        accounts: `${
          totalFound === 0 ? FDN.I18n.t("adminAccounts.search.accountsCount.noFound") : totalFound
        } ${FDN.I18n.t(
          `adminAccounts.search.accountsCount.${totalFound === 1 ? `account` : `accounts`}`
        )}`,
      })}
    </div>
  );
};

export default AdminAccounts;
