import { useState } from "react";
import * as FDN from "src/core";
import { IBauvorhaben, IBvInstallment, IBvPaymentPlan, TFunction } from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import CCApi from "src/api/CCApi";
import { cloneDeep } from "lodash";
import { TActionsBauvorhaben } from "./CCBauvorhabenService";
import { addDays } from "date-fns";

export interface TActionsBauvorhabenPaymentPlan extends TActionsBauvorhaben {
  init: TFunction;
  selectForMarkAs: (type: "invoiced" | "paid", installment: IBvInstallment) => void;
  markAs: (type: "nothing" | "approved" | "invoiced" | "paid", installment: IBvInstallment) => void;
  revoke: (type: "approved" | "invoiced" | "paid", installment: IBvInstallment) => void;
  selectInstallment: (installment: IBvInstallment | "new" | "mehr" | "minder") => void;
  onEditInstallment: <P extends keyof IBvInstallment, V extends IBvInstallment[P]>(
    property: P,
    value: V
  ) => void;
  onCancelEditInstallment: () => void;
  onSaveEditInstallment: () => void;
  onDeleteInstallment: (installment: IBvInstallment) => void;
}

const useServiceCCBvPaymentPlan = (bvActions: TActionsBauvorhaben) => {
  const { api, NOTIFICATIONS } = useServiceCore();

  const [bauvorhaben, setBauvorhaben] = useState<IBauvorhaben>();
  const [paymentPlan, setPaymentPlan] = useState<IBvPaymentPlan>();

  const [virginInstallment, setVirginInstallment] = useState<IBvInstallment>();
  const [editInstallment, setEditInstallment] = useState<IBvInstallment>();
  const [unsavedInstallmentChanges, setUnsavedInstallmentChanges] = useState(false);

  const [markAsInvoiced, setMarkAsInvoiced] = useState<IBvInstallment>();
  const [markAsPaid, setMarkAsPaid] = useState<IBvInstallment>();

  const init = (bauvorhaben: IBauvorhaben) => {
    setBauvorhaben(cloneDeep(bauvorhaben));
    CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "paymentplan").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        if (response?.body?.paymentPlan)
          setPaymentPlan(response.body.paymentPlan as IBvPaymentPlan);
        if (response?.body?.virginInstallment)
          setVirginInstallment(response.body.virginInstallment as IBvInstallment);
      }
    });
  };

  const selectForMarkAs = (type: "invoiced" | "paid", installment: IBvInstallment) => {
    if (type === "invoiced") {
      if (!installment.due) {
        let dueDate = new Date();
        dueDate = addDays(dueDate, 7);
        installment.due = dueDate;
      }

      setMarkAsInvoiced(cloneDeep(installment));
    } else if (type === "paid") setMarkAsPaid(cloneDeep(installment));
  };

  const markAs = (
    type: "nothing" | "approved" | "invoiced" | "paid",
    installment: IBvInstallment
  ) => {
    if (!bauvorhaben) return;

    let data: IBvInstallment | undefined = undefined;
    if (type === "invoiced") data = markAsInvoiced;
    else if (type === "paid") data = markAsPaid;
    else data = editInstallment;

    const doMarkAs = () => {
      CCApi.ccBvPaymentPlanInstallmentMarkAs(
        api,
        bauvorhaben.identifier,
        installment.identifier,
        type,
        data
      ).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const paymentPlan = response?.body?.paymentPlan as IBvPaymentPlan;
          setPaymentPlan(cloneDeep(paymentPlan));
          setEditInstallment(undefined);
          setMarkAsInvoiced(undefined);
          setMarkAsPaid(undefined);
          NOTIFICATIONS.hideDialog();
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t(`ccBvPaymentPlan.actions.markas.${type}.confirm`),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            if (doMarkAs) doMarkAs();
          },
        },
      ],
    });
  };

  const revoke = (type: "approved" | "invoiced" | "paid", installment: IBvInstallment) => {
    if (!bauvorhaben) return;

    const doRevoke = () => {
      CCApi.ccBvPaymentPlanInstallmentRevoke(
        api,
        bauvorhaben.identifier,
        installment.identifier,
        type
      ).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const paymentPlan = response?.body?.paymentPlan as IBvPaymentPlan;
          setPaymentPlan(cloneDeep(paymentPlan));
          NOTIFICATIONS.hideDialog();
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t(`ccBvPaymentPlan.actions.revoke.${type}.confirm`),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            if (doRevoke) doRevoke();
          },
        },
      ],
    });
  };

  const selectInstallment = (installment: IBvInstallment | "new" | "mehr" | "minder") => {
    if (!virginInstallment) return;

    if (installment === "new") {
      const newInstallment = cloneDeep(virginInstallment);
      newInstallment.type = "building";
      setEditInstallment(newInstallment);
    } else if (installment === "mehr") {
      const mehrleistung = cloneDeep(virginInstallment);
      mehrleistung.type = "mehr";
      mehrleistung.sum = 0;
      setEditInstallment(mehrleistung);
    } else if (installment === "minder") {
      const minderleistung = cloneDeep(virginInstallment);
      minderleistung.type = "minder";
      minderleistung.sum = 0;
      setEditInstallment(minderleistung);
    } else setEditInstallment(cloneDeep(installment));
  };

  function onEditInstallment<P extends keyof IBvInstallment, V extends IBvInstallment[P]>(
    property: P,
    value: V
  ) {
    if (!editInstallment && !markAsInvoiced && !markAsPaid) return;

    if (editInstallment) {
      editInstallment[property] = value;
      setEditInstallment(cloneDeep(editInstallment));
    } else if (markAsInvoiced) {
      if (property === "invoicedAmount") {
        let newInvoicedAmount = (value as number) || 0.0;
        const installmentSum = markAsInvoiced.sum || 0.0;

        if (newInvoicedAmount > installmentSum) newInvoicedAmount = installmentSum;

        markAsInvoiced.invoicedAmount = newInvoicedAmount;
      } else {
        markAsInvoiced[property] = value;
      }

      setMarkAsInvoiced(cloneDeep(markAsInvoiced));
    } else if (markAsPaid) {
      if (property === "paidAmount") {
        let newPaidAmount = (value as number) || 0.0;
        const invoicedAmount = markAsPaid.invoicedAmount || 0.0;

        if (newPaidAmount > invoicedAmount) newPaidAmount = invoicedAmount;

        markAsPaid.paidAmount = newPaidAmount;
      } else {
        markAsPaid[property] = value;
      }

      setMarkAsPaid(cloneDeep(markAsPaid));
    }

    setUnsavedInstallmentChanges(true);
  }

  const onCancelEditInstallment = () => {
    const doCancel = () => {
      setEditInstallment(undefined);
      setMarkAsInvoiced(undefined);
      setMarkAsPaid(undefined);
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedInstallmentChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => {
              NOTIFICATIONS.hideDialog();
            },
          },
          {
            type: "ok",
            onClick: () => {
              if (doCancel) doCancel();
            },
          },
        ],
      });
    else if (doCancel) doCancel();
  };

  const onSaveEditInstallment = () => {
    if (!editInstallment || !bauvorhaben) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    CCApi.ccBvPaymentPlanUpdateInstallment(
      api,
      bauvorhaben.identifier,
      editInstallment.identifier,
      editInstallment
    ).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const paymentPlan = response?.body?.paymentPlan;
        setPaymentPlan(cloneDeep(paymentPlan));
        setEditInstallment(undefined);
        setUnsavedInstallmentChanges(false);
        NOTIFICATIONS.hideSaving();
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("ccBvPaymentPlan.edit.onSave.success.title"),
          FDN.I18n.t("ccBvPaymentPlan.edit.onSave.success.text")
        );
      }
    });
  };

  const onDeleteInstallment = (installment: IBvInstallment) => {
    if (!bauvorhaben) return;
    if (
      installment.type !== "minder" &&
      installment.type !== "mehr" &&
      installment.type !== "building" &&
      installment.type !== "bnk"
    )
      return;

    const doDelete = () => {
      CCApi.ccBvPaymentPlanDeleteInstallment(
        api,
        bauvorhaben.identifier,
        installment.identifier
      ).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const paymentPlan = response?.body?.paymentPlan as IBvPaymentPlan;
          setPaymentPlan(cloneDeep(paymentPlan));
          setEditInstallment(undefined);
          setMarkAsInvoiced(undefined);
          setMarkAsPaid(undefined);
          NOTIFICATIONS.hideDialog();
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t(`ccBvPaymentPlan.onDelete.${installment.type}.success.title`),
            FDN.I18n.t(`ccBvPaymentPlan.onDelete.${installment.type}.success.text`)
          );
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t(`ccBvPaymentPlan.onDelete.${installment.type}.confirm`),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            if (doDelete) doDelete();
          },
        },
      ],
    });
  };

  const actions: TActionsBauvorhabenPaymentPlan = {
    ...bvActions,
    selectForMarkAs,
    markAs,
    revoke,
    selectInstallment,
    onEditInstallment,
    onCancelEditInstallment,
    onSaveEditInstallment,
    onDeleteInstallment,
    init,
  };

  return { paymentPlan, editInstallment, markAsInvoiced, markAsPaid, actions };
};

export default useServiceCCBvPaymentPlan;
