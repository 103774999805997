import * as React from "react";
import * as FDN from "src/core";
import { IAccountState, IBauvorhaben, TActions, TFunction } from "src/types/types";
import CCApi from "src/api/CCApi";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import { cloneDeep } from "lodash";

type TView = "EMAIL" | "EMAIL_FOUND" | "HAS_ACCESS" | "NEW_CLIENT" | "LOADING" | "ERROR";

interface IAddClientPopupProps {
  bauvorhaben: IBauvorhaben;
  onClose: TFunction;
  onSuccess?: (bauvorhaben: IBauvorhaben) => void;
}

const AddClientPopup: React.FunctionComponent<IAddClientPopupProps> = ({
  bauvorhaben,
  onClose,
  onSuccess,
}) => {
  const [view, setView] = React.useState<TView>("EMAIL");
  const [email, setEmail] = React.useState("");
  const [account, setAccount] = React.useState<IAccountState>();

  const { api, APP, NOTIFICATIONS } = useServiceCore();

  const onEditEmail = (value: string) => {
    setEmail(value);
  };

  const onEdit = (property: string, value: any) => {
    if (!account) return;
    account[property] = value;
    setAccount(cloneDeep(account));
  };

  const onCheckEmail = () => {
    setView("LOADING");
    CCApi.ccBvCheckEmail(api, bauvorhaben.identifier, email).then((response) => {
      const statusCode = response?.statusCode;
      const account = response?.body?.account as IAccountState;

      if (statusCode === StatusCode.EMAIL_EXISTS) {
        setAccount(account);

        if (response?.body?.hasAccess && response?.body?.hasAccess === true) setView("HAS_ACCESS");
        else setView("EMAIL_FOUND");
      } else if (statusCode === StatusCode.EMAIL_DOES_NOT_EXIST) {
        account.email = email;
        setAccount(account);
        setView("NEW_CLIENT");
      }
    });
  };

  const onGrantAccess = () => {
    if (!account || !account.email) return;

    setView("LOADING");
    CCApi.ccBvGrantAccess(api, bauvorhaben.identifier, account).then((response) => {
      if (
        response?.statusCode === StatusCode.ERROR ||
        response?.statusCode === StatusCode.INVALID_FORM_DATA
      )
        setView("ERROR");
      else if (response?.statusCode === StatusCode.SUCCESS) {
        if (onSuccess) onSuccess(response?.body?.bauvorhaben as IBauvorhaben);
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("ccBvClients.addclientpopup.onSave.success.title"),
          FDN.I18n.t("ccBvClients.addclientpopup.onSave.success.text")
        );
      }
    });
  };

  const actions = {
    onEdit,
    onEditEmail,
    onCheckEmail,
    onGrantAccess,
    onClose,
    setView,
  };

  return (
    <FDN.Popup
      size="small"
      title={FDN.I18n.t("ccBvClients.addclientpopup.title")}
      onClose={onClose}
    >
      {view === "EMAIL" && <ViewEmail email={email} actions={actions} />}
      {view === "EMAIL_FOUND" && account ? (
        <ViewAccountFound account={account} actions={actions} />
      ) : null}
      {view === "HAS_ACCESS" && account ? (
        <ViewAccountHasAccess account={account} actions={actions} />
      ) : null}
      {view === "NEW_CLIENT" && account ? (
        <ViewAccountNotFound account={account} actions={actions} />
      ) : null}
      {view === "LOADING" && (
        <div style={{ padding: "60px 0" }}>
          <FDN.Loading box />
        </div>
      )}
      {view === "ERROR" && (
        <FDN.CalloutBox type="alert">
          <div>
            <strong>{FDN.I18n.t("errors.error500.title")}</strong>
          </div>
          <div dangerouslySetInnerHTML={{ __html: FDN.I18n.t("errors.error500.text") }} />
        </FDN.CalloutBox>
      )}
    </FDN.Popup>
  );
};

interface IViewEmailProps {
  email: string;
  actions: TActions;
}

const ViewEmail: React.FunctionComponent<IViewEmailProps> = ({ email, actions }) => {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: FDN.I18n.t("ccBvClients.addclientpopup.text") }} />
      <FDN.SingleRowCell full>
        <FDN.Input
          type="email"
          value={email}
          label={FDN.I18n.t("ccBvClients.addclientpopup.form.email.label")}
          placeholder={FDN.I18n.t("ccBvClients.addclientpopup.form.email.placeholder")}
          editable={true}
          editMode={true}
          onUpdate={(value) => actions.onEditEmail(value)}
        />
      </FDN.SingleRowCell>
      <FDN.FormButtons
        onCancel={actions.onClose}
        onSave={actions.onCheckEmail}
        saveIcon="angle-right"
        saveLabel={FDN.I18n.t("ccBvClients.addclientpopup.buttons.continue.label")}
        saveIconPosition="right"
        saveDisabled={!email || (email && !FDN.isEmail(email)) ? true : false}
      />
    </>
  );
};

interface IViewAccount {
  account: IAccountState;
  actions: TActions;
}

const ViewAccountFound: React.FunctionComponent<IViewAccount> = ({ account, actions }) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: FDN.I18n.t("ccBvClients.addclientpopup.accountFound.text"),
        }}
      />
      <p className="text-center">
        <strong>{account.displayname}</strong>
        <br />
        E-Mail: {account.email}
        <br />
        Erstellungsdatum: {FDN.formatDate(account.createdAt)}
      </p>
      <FDN.CalloutBox type="info">
        {FDN.I18n.t("ccBvClients.addclientpopup.accountFound.text2")}
      </FDN.CalloutBox>
      <FDN.FormButtons
        onCancel={() => actions.setView("EMAIL")}
        cancelLabel={FDN.I18n.t("ccBvClients.addclientpopup.buttons.back.label")}
        cancelIcon="angle-left"
        onSave={actions.onGrantAccess}
        saveLabel={FDN.I18n.t("ccBvClients.addclientpopup.buttons.add.label")}
      />
    </>
  );
};

const ViewAccountHasAccess: React.FunctionComponent<IViewAccount> = ({ account, actions }) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: FDN.I18n.t("ccBvClients.addclientpopup.accountHasAccess.text"),
        }}
      />
      <p className="text-center">
        <strong>{account.displayname}</strong>
        <br />
        E-Mail: {account.email}
        <br />
        Erstellungsdatum: {FDN.formatDate(account.createdAt)}
      </p>
      <FDN.FormButtons
        onCancel={() => actions.setView("EMAIL")}
        cancelLabel={FDN.I18n.t("ccBvClients.addclientpopup.buttons.back.label")}
        cancelIcon="angle-left"
      />
    </>
  );
};

const ViewAccountNotFound: React.FunctionComponent<IViewAccount> = ({ account, actions }) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: FDN.I18n.t("ccBvClients.addclientpopup.accountNotFound.text"),
        }}
      />
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={12}>
            <FDN.Input
              type="text"
              value={account.firstname}
              editable={true}
              editMode={true}
              label={FDN.I18n.t("ccBvClients.addclientpopup.form.firstname.label")}
              placeholder={FDN.I18n.t("ccBvClients.addclientpopup.form.firstname.placeholder")}
              onUpdate={(value) => actions.onEdit("firstname", value)}
            />
          </FDN.Cell>
          <FDN.Cell md={12}>
            <FDN.Input
              type="text"
              value={account.lastname}
              editable={true}
              editMode={true}
              label={FDN.I18n.t("ccBvClients.addclientpopup.form.lastname.label")}
              placeholder={FDN.I18n.t("ccBvClients.addclientpopup.form.lastname.placeholder")}
              onUpdate={(value) => actions.onEdit("lastname", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <FDN.Input
              type="text"
              value={account.email}
              editable={false}
              editMode={false}
              label={FDN.I18n.t("ccBvClients.addclientpopup.form.email.label")}
              placeholder={FDN.I18n.t("ccBvClients.addclientpopup.form.email.placeholder")}
              onUpdate={(value) => actions.onEdit("email", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      <FDN.CalloutBox type="info">
        {FDN.I18n.t("ccBvClients.addclientpopup.accountNotFound.text2")}
      </FDN.CalloutBox>
      <FDN.FormButtons
        onCancel={() => actions.setView("EMAIL")}
        cancelLabel={FDN.I18n.t("ccBvClients.addclientpopup.buttons.back.label")}
        cancelIcon="angle-left"
        onSave={actions.onGrantAccess}
        saveLabel={FDN.I18n.t("ccBvClients.addclientpopup.buttons.add.label")}
      />
    </>
  );
};

export default AddClientPopup;
