import * as React from "react";
import * as FDN from "src/core";
import { IFileBrowserFile } from "src/types/types";
import { Document } from "react-pdf";

interface IPdfViewerProps {
  file: IFileBrowserFile;
}

const PdfViewer: React.FunctionComponent<IPdfViewerProps> = ({ file }) => {
  if (!file || !file.webUrl) return <FDN.Loading box />;

  console.log("file", file);

  return (
    <div className="__pdfviewer">
      <Document file={file.webUrl} />
    </div>
  );
};

export default PdfViewer;
