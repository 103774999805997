import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import Config from "src/core/Config";
import LocalStorageService from "src/core/LocalStorageService";
import UrlService from "src/core/UrlService";
import { IAppContext, IHandleApiResponseProps } from "./types";
import * as FDN from "src/core";
import { userHasPermission } from "src/core/AdminService/helpers";

interface IAppContextProviderProps {
  children?: any;
}

const initialContextValue: IAppContext = {
  settings: undefined,
  user: undefined,
  language: undefined,
  i18n: undefined,
  tmp: {},
  userView: undefined,
  handleApiResponse: () => null,
  getSetting: () => null,
  getUser: () => null,
  getLanguage: () => null,
  getI18n: () => null,
  setPageTitle: () => null,
  logout: () => null,
};

export const AppContext = React.createContext<IAppContext>(initialContextValue);

export const AppContextProvider = (props: IAppContextProviderProps) => {
  const [contextValue, setContextValue] = useState(initialContextValue);

  /**
   * Wait for the app settings to be loaded and
   * check if there are cached changes for the page title
   */
  useEffect(() => {
    if (contextValue.tmp?.pageTitle && contextValue.settings?.defaultPageTitle)
      setPageTitle(contextValue.tmp.pageTitle);
  }, [contextValue.settings?.defaultPageTitle]);

  /**
   * Wait for the app settings to be loaded and
   * update the favicon if there is one in the app settings
   */
  useEffect(() => {
    if (contextValue.settings?.faviconUrl && document) {
      const elFavicon = document.getElementById("favicon");
      const elFaviconApple = document.getElementById("faviconApple");

      if (elFavicon && elFavicon instanceof HTMLLinkElement)
        elFavicon.href = contextValue.settings?.faviconUrl;
      if (elFaviconApple && elFaviconApple instanceof HTMLLinkElement)
        elFaviconApple.href = contextValue.settings?.faviconUrl;
    }
  }, [contextValue.settings?.faviconUrl]);

  /**
   * This method gets called after every API call
   * to store the app settings, language settings, logged in user etc.
   * in the state of this Context
   */
  const handleApiResponse = (props?: IHandleApiResponseProps) => {
    if (!props) return;
    const { app, user } = props;

    if (app) {
      if (app.settings) {
        contextValue.settings = app.settings;
        setContextValue(cloneDeep(contextValue));
      }

      if (app.language) {
        contextValue.language = app.language;
        setContextValue(cloneDeep(contextValue));

        FDN.I18n.setLanguage(app.language);
      }

      if (app.i18n) {
        contextValue.i18n = app.i18n;
        setContextValue(cloneDeep(contextValue));
      }
    }

    if (user || user === null) {
      contextValue.user = user;

      if (userHasPermission(user, "ccBauvorhabenEdit")) contextValue.userView = "cc";
      else contextValue.userView = "home";

      setContextValue(cloneDeep(contextValue));
    }

    const preloading = document.getElementById("preloading");
    if (preloading) preloading.style.display = "none";
  };

  /**
   * Return the logged in user
   */
  const getUser = () => {
    return contextValue.user;
  };

  /**
   * Return the value of a specific app setting
   */
  const getSetting = (key: string) => {
    if (contextValue.settings && contextValue.settings[key]) return contextValue.settings[key];
    return null;
  };

  /**
   * Return the loaded language identifier, eg. "de"
   */
  const getLanguage = () => {
    return contextValue.language || null;
  };

  /**
   * Return all I18n multi language information,
   * e.g. all available languages etc.
   */
  const getI18n = () => {
    return contextValue.i18n || null;
  };

  /**
   * Set the page title (HTML: <title />)
   * If this method is being called before the app settings are retrieved
   * from the API, the page title will be cached and applied as soon
   * as the app settings are loaded (see useEffect above)
   */
  const setPageTitle = (title: string | null) => {
    const mainPageTitle = getSetting("defaultPageTitle") as string;
    if (!mainPageTitle) {
      setTmp("pageTitle", title);
      return;
    }

    let pageTitle = "";

    if (!title) pageTitle = mainPageTitle;
    else pageTitle = `${title} | ${mainPageTitle}`;

    document.title = pageTitle;

    setTmp("pageTitle", null);
  };

  /**
   * Delete the access token from the LocalStorage to log out
   * the user currently logged in
   */
  const logout = () => {
    LocalStorageService.remove("ACCESS_TOKEN");
    window.location.href = UrlService.url(Config.get("auth.redirectAfterLogout") as string);
  };

  /**
   * Cache specific details that will be applied
   * after the app settings have been loaded
   * (e.g. page title)
   */
  const setTmp = (key: string, value: any) => {
    if (!contextValue.tmp) contextValue.tmp = {};

    contextValue.tmp[key] = value;
    setContextValue(cloneDeep(contextValue));
  };

  const context = {
    ...contextValue,
    handleApiResponse,
    getSetting,
    getUser,
    getLanguage,
    getI18n,
    setPageTitle,
    logout,
  };

  return <AppContext.Provider value={context}>{props.children}</AppContext.Provider>;
};
