import * as React from "react";
import * as FDN from "src/core";
import useServiceHomeBauvorhaben from "../../../services/home/HomeBauvorhabenService";
import LayoutWithTopbar from "../../layouts/LayoutWithTopbar";
import BauvorhabenHeader from "../cc/bauvorhaben/layout/BauvorhabenHeader";
import BauvorhabenTabs from "../cc/bauvorhaben/layout/BauvorhabenTabs";
import BauvorhabenContent from "../cc/bauvorhaben/layout/BauvorhabenContent";
import useServiceCCBauvorhabenTabs from "../../../services/cc/CCBauvorhabenTabsService";

const HomeBauvorhaben: React.FunctionComponent = () => {
  const { bauvorhaben, actions } = useServiceHomeBauvorhaben();
  const { tabs, selectedTab, onSelectTab } = useServiceCCBauvorhabenTabs();

  if (!bauvorhaben || !bauvorhaben.tabsForClients?.includes(selectedTab)) return <FDN.Loading />;

  return (
    <div className="__home-bauvorhaben">
      <LayoutWithTopbar page="home-bauvorhaben">
        <main>
          <FDN.SingleRowCell>
            <BauvorhabenHeader bauvorhaben={bauvorhaben} />
            <div className="__cc-bauvorhaben-bv-layout">
              <BauvorhabenTabs
                tabs={tabs}
                visibleTabs={bauvorhaben.tabsForClients || []}
                selectedTab={selectedTab}
                onSelectTab={onSelectTab}
              />
              <BauvorhabenContent
                location="HOME"
                bauvorhaben={bauvorhaben}
                editMode={false}
                selectedTab={selectedTab}
                actions={actions}
              />
            </div>
          </FDN.SingleRowCell>
        </main>
      </LayoutWithTopbar>
    </div>
  );
};

export default HomeBauvorhaben;
