import * as React from "react";
import CookieBanner from "../dataprivacy/CookieBanner";

interface IMasterProps {
  page: string;
  children?: any;
}

const Master: React.FunctionComponent<IMasterProps> = (props) => {
  return (
    <div className={`__page-${props.page}`}>
      {props.children}
      <CookieBanner />
    </div>
  );
};

export default Master;
