import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben } from "src/types/types";
import { useNavigate } from "react-router-dom";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import useServiceCCBvPaymentPlan, {
  TActionsBauvorhabenPaymentPlan,
} from "src/services/cc/CCBvPaymentPlanService";
import BvPaymentPlan from "./paymentplan/BvPaymentPlan";
import EditInstallmentPopup from "./paymentplan/parts/EditInstallmentPopup";
import EditMehrMinderPopup from "./paymentplan/parts/EditMehrMinderPopup";
import MarkAsPopup from "./paymentplan/parts/MarkAsPopup";

const tabs = {
  paymentplan: {
    label: FDN.I18n.t("ccBvPaymentPlan.tabs.paymentplan.label"),
    icon: "euro",
  },
};

const defaultTab = "paymentplan";

interface IBauvorhabenPaymentPlanProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BauvorhabenPaymentPlan: React.FunctionComponent<IBauvorhabenPaymentPlanProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const {
    paymentPlan,
    editInstallment,
    markAsInvoiced,
    markAsPaid,
    actions: paymentPlanActions,
  } = useServiceCCBvPaymentPlan(actions);

  const moduleActions: TActionsBauvorhabenPaymentPlan = { ...actions, ...paymentPlanActions };

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!paymentPlan) paymentPlanActions.init(bauvorhaben);
  }, [bauvorhaben]);

  if (!paymentPlan) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-paymentplan">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">
        {FDN.I18n.t(`ccBvPaymentPlan.tabs.${selectedTab}.title`)}
      </h2>
      <FDN.TabsContent>
        {selectedTab === "paymentplan" && (
          <BvPaymentPlan
            bauvorhaben={bauvorhaben}
            paymentPlan={paymentPlan}
            editMode={editMode}
            actions={moduleActions}
          />
        )}
        {editInstallment &&
        (editInstallment.type === "bnk" || editInstallment.type === "building") ? (
          <EditInstallmentPopup installment={editInstallment} actions={moduleActions} />
        ) : null}
        {editInstallment &&
        (editInstallment.type === "mehr" || editInstallment.type === "minder") ? (
          <EditMehrMinderPopup installment={editInstallment} actions={moduleActions} />
        ) : null}

        {markAsInvoiced ? (
          <MarkAsPopup type="invoiced" installment={markAsInvoiced} actions={moduleActions} />
        ) : null}
        {markAsPaid ? (
          <MarkAsPopup type="paid" installment={markAsPaid} actions={moduleActions} />
        ) : null}
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenPaymentPlan;
