import * as React from "react";
import * as FDN from "src/core";
import { IBvTermine } from "src/types/types";

type MappingValueMap = (string | null)[];

const mapping: { [key: string]: MappingValueMap } = {
  verbraucherbauvertrag: [null, null, "verbraucherbauvertrag"],
  projektbesprechung: [null, null, "projektbesprechung"],
  baubeginn: ["baubeginn_soll", null, "baubeginn_ist"],
  richtfest: ["richtfest_soll", null, "richtfest_ist"],
  vorbegehung: ["vorbegehung_soll", "vorbegehung_plan", "vorbegehung_ist"],
  uebergabe: ["uebergabe_soll", "uebergabe_plan", "uebergabe_ist"],
};

interface IStatusTermineProps {
  termine?: IBvTermine;
}

const StatusTermine: React.FunctionComponent<IStatusTermineProps> = ({ termine }) => {
  if (!termine) return <FDN.Loading box />;

  return (
    <div className="__bv-status-termine">
      <FDN.Box noPadding>
        <div className="__box-padding">
          <h3 style={{ marginBottom: "0" }}>{FDN.I18n.t("ccBvStatus.termine.title")}</h3>
        </div>
        <div className="__box-padding">
          {Object.keys(mapping).map((mapKey) => {
            return <StatusTermin termine={termine} type={mapKey} key={mapKey} />;
          })}
        </div>
      </FDN.Box>
    </div>
  );
};

interface IStatusTerminProps {
  termine: IBvTermine;
  type: string;
}

const StatusTermin: React.FunctionComponent<IStatusTerminProps> = ({ termine, type }) => {
  return (
    <div className="__bv-status-termine-termin">
      <h4>{FDN.I18n.t(`ccBvTermine.terminGroups.${type}.title`)}</h4>
      <div className="__box-padding">
        <FDN.Grid full>
          <FDN.Row>
            {mapping[type].map((entry, index) => {
              return (
                <FDN.Cell sm={24} md={8} key={index}>
                  {entry ? (
                    <FDN.Input
                      type="text"
                      value={termine[type] ? FDN.formatDate(termine[type]) : null}
                      editMode={false}
                      label={FDN.I18n.t(`ccBvStatus.termine.type.${type}.${entry}.label`)}
                    />
                  ) : null}
                </FDN.Cell>
              );
            })}
          </FDN.Row>
        </FDN.Grid>
      </div>
    </div>
  );
};

export default StatusTermine;
