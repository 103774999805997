import * as React from "react";
import CCApi from "src/api/CCApi";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";
import {
  BvDetailTypeTrafficLight,
  BvDetailTypeYesNo,
  IBvDetail,
  IBvFenster,
  IBvFertigungsbuergschaft,
  IBvRohbauCheck,
  IBvVermessung,
} from "src/types/types";

export const bvDetailTypes: { [key: string]: string } = {
  abriss: "yesno",
  abriss_erfolgt: "trafficlight",
  abriss_link: "text",
  architektenvollmacht: "trafficlight",
  asl_link: "text",
  asl_percent: "percent",
  baederplanung: "trafficlight",
  bauantrag: "trafficlight",
  baubeginnanzeige: "trafficlight",
  baugenehmigung_percent: "percent",
  bauschild: "trafficlight",
  baustellentoilette: "trafficlight",
  baustellenueberfahrt: "trafficlight",
  baustrom: "trafficlight",
  bauteilnachweis: "trafficlight",
  bauversicherung: "trafficlight",
  bauvorbereitung: "trafficlight",
  bauvorbereitung_link: "text",
  bauwasser: "trafficlight",
  bauzaun: "trafficlight",
  begruessungsanruf: "trafficlight",
  begruessungsschreiben: "trafficlight",
  begruessungsschreiben2: "trafficlight",
  bodengutachten: "trafficlight",
  buergschaft__1: "yesno",
  buergschaft__2: "yesno",
  buergschaft__reinschrift: "yesno",
  buergschaft_percent: "percent",
  datenschutzvereinbarung: "yesno",
  details_architektenhaus: "text",
  details_bauherr: "text",
  details_besonderheiten: "text",
  details_prio: "text",
  drive_bv_intern: "text",
  drive_waermeschutz: "text",
  dsgvo_unterzeichnet: "trafficlight",
  energiestandard: "text",
  entfeuchtungskonzept: "trafficlight",
  entwaesserungsgenehmigung_percent: "percent",
  entwaesserungsplanung_percent: "percent",
  fenster_beauftragt: "trafficlight",
  fenster_energieberater: "trafficlight",
  fenster_genehmigt: "trafficlight",
  fenster_genehmigt_bl: "trafficlight",
  fensterbaenke_bemustert: "trafficlight",
  fensterblankett: "trafficlight",
  fensterfolien_entfernt: "trafficlight",
  hausanschluesse_type: "text",
  hauskategorie: "text",
  haustechnikraum_freigegeben: "trafficlight",
  haustechnischeplanung_percent: "percent",
  hoehenplan_von_bh: "trafficlight",
  ingbericht_link: "text",
  ingenieursbericht: "trafficlight",
  kampfmittelfreigabe: "trafficlight",
  kfw_baubegleitung_percent: "percent",
  kranbaustelle: "yesno",
  kranbaustelle_fortschritt: "trafficlight",
  kuechenplanung: "trafficlight",
  projektbesprechung: "date",
  projektbesprechung_soll: "date",
  rendering: "text",
  rohbaubegehung: "trafficlight",
  rohbaubegehung_notwendig: "yesno",
  rohbauueberpruefung: "trafficlight",
  sigeko_beauftragt: "trafficlight",
  sigeko_erforderlich: "yesno",
  statik_percent: "percent",
  tueren_aufgemessen: "trafficlight",
  tueren_beauftragt: "trafficlight",
  tueren_genehmigt: "trafficlight",
  tueren_geprueft: "trafficlight",
  tuerenblankett: "trafficlight",
  uebergabe: "text",
  verblendsteine: "yesno",
  verblendsteine_reserviert: "trafficlight",
  verbraucherbauvertrag: "date",
  vergabe_status: "trafficlight",
  vergabe_status_reset: "date",
  waermepass: "trafficlight",
  waermepumpe_bestellt: "text",
  waermepumpe_eingebaut: "text",
  waermepumpe_liefertermin: "text",
  waermepumpe_typ: "text",
  waermepumpe_waermebedarf: "text",
  waermeschutz_bdt_erfolgreich: "yesno",
  waermeschutz_bdt_erforderlich: "yesno",
  waermeschutz_berechnet: "yesno",
  waermeschutz_energieausweis: "yesno",
  waermeschutz_enew: "yesno",
  waermeschutz_heizlastberechnung: "yesno",
  waermeschutz_heizlastberechnung_person: "text",
  waermeschutz_hydraulik: "yesno",
  waermeschutz_schlussrechnung: "yesno",
  waermeschutznachweis: "trafficlight",
  wasserabweiser: "trafficlight",
  zimmertueren_beauftragt: "trafficlight",

  vermessung__beauftragt: "yesno",
  vermessung__vermessungsvorschlag: "yesno",
  vermessung__grobabsteckung: "yesno",
  vermessung__hoehenprotokoll: "yesno",
  vermessung__vermessungsprotokoll: "yesno",
  vermessung__vermessungsprotokoll_abgezeichnet: "yesno",

  rohbau__meterriss: "yesno",
  rohbau__estrich: "yesno",
  rohbau__betontreppe: "yesno",
  rohbau__durchbrueche: "yesno",
  rohbau__stuerze: "yesno",
  rohbau__fenster: "yesno",
  rohbau__tueren: "yesno",
  rohbau__waende: "yesno",
};

export type AllowedBvDetailValues = string | boolean | Date | number | null | undefined;

function getValue<P extends keyof IBvDetail>(details: IBvDetail, property: P) {
  return details[property] || undefined;
}

interface IBvDetailProps {
  parent: "details" | "vermessung" | "fenster" | "rohbauCheck" | "fertigungsbuergschaften";
  bvIdentifier: string;
  type: string;
  value: AllowedBvDetailValues;
  isEditable: boolean;
  onUpdate?: (
    value:
      | IBvDetail
      | IBvVermessung
      | IBvFenster
      | IBvRohbauCheck
      | IBvFertigungsbuergschaft
      | undefined
      | false
  ) => void;
}

const BvDetail: React.FunctionComponent<IBvDetailProps> = ({
  parent,
  bvIdentifier,
  type,
  value,
  isEditable = false,
  onUpdate,
}) => {
  const [detailValue, setDetailValue] = React.useState<AllowedBvDetailValues>(value);
  const [loadingStatus, setLoadingStatus] = React.useState<false | "LOADING" | "ERROR">(false);

  const bvDetailType = bvDetailTypes[type];

  const { api } = useServiceCore();

  const handleClick = () => {
    if (!isEditable || loadingStatus !== false || !bvIdentifier) return;

    setLoadingStatus("LOADING");

    CCApi.ccBvDetailNextValue(api, bvIdentifier, type, parent).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const bvDetails = response?.body?.bvDetails;
        if (bvDetails) {
          setDetailValue(bvDetails[type]);
          setLoadingStatus(false);
          if (onUpdate) onUpdate(bvDetails);
        } else {
          setLoadingStatus("ERROR");
        }
      } else {
        setLoadingStatus("ERROR");
      }
    });
  };

  if (!bvDetailType) return null;

  return (
    <div className="__bvdetail">
      <div className="__bvdetail-icon">
        <button
          className={`__bvdetail-${bvDetailType} ${
            isEditable === true ? `is-editable` : `is-not-editable`
          }`}
          onClick={handleClick}
        >
          {loadingStatus === "LOADING" ? (
            <FDN.Icon icon="spinner fa-spin" />
          ) : (
            <>
              {bvDetailType === "yesno" && (
                <BvDetailYesNo value={detailValue as BvDetailTypeYesNo} />
              )}
              {bvDetailType === "trafficlight" && (
                <BvDetailTrafficLight value={detailValue as BvDetailTypeTrafficLight} />
              )}
            </>
          )}
        </button>
      </div>
      <div className="__bvdetail-label">{FDN.I18n.t(`ccBvDetails.type.${type}.label`)}</div>
    </div>
  );
};

interface IBvDetailTrafficLightProps {
  value: BvDetailTypeTrafficLight;
}

const BvDetailTrafficLight: React.FunctionComponent<IBvDetailTrafficLightProps> = ({ value }) => {
  if (!value) value = "red";

  return <span className={`__bvdetail-trafficlight-icon type-${value}`}> </span>;
};

interface IBvDetailYesNoProps {
  value: BvDetailTypeYesNo;
}

const BvDetailYesNo: React.FunctionComponent<IBvDetailYesNoProps> = ({ value }) => {
  if (value === true)
    return (
      <span className="__bvdetail-yesno-icon success-text">
        <FDN.Icon icon="check" />
      </span>
    );
  else if (value === false)
    return (
      <span className="__bvdetail-yesno-icon error-text">
        <FDN.Icon icon="times" />
      </span>
    );
  return (
    <span className="__bvdetail-yesno-icon">
      <FDN.Icon icon="question" />
    </span>
  );
};

export default BvDetail;
