import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { Link } from "react-router-dom";
import UrlService from "src/core/UrlService";
import { userHasAtLeastOnePermission } from "src/core/AdminService/helpers";
import { AppContext } from "src/components/context/AppContext/AppContext";
import packageJSON from "src/../package.json";

interface ICCNavigationProps {
  page: string;
}

const CCNavigation: React.FunctionComponent<ICCNavigationProps> = ({ page }) => {
  const navItems = Config.get("cc.nav.items") as { [key: string]: { [key: string]: any } };

  const APP = React.useContext(AppContext);
  const user = APP.getUser();

  return (
    <div className="__cc-navigation">
      <ul>
        {Object.keys(navItems).map((navItemKey) => {
          const navItem = navItems[navItemKey];

          if (!userHasAtLeastOnePermission(user, navItem.permissions)) return null;

          return (
            <li key={navItemKey}>
              <Link
                to={UrlService.url(navItem.route)}
                className={`${
                  page.replace("cc-", "") === navItemKey ? `selected` : `unselected`
                } __cc-navigation-item`}
              >
                <div className="__cc-navigation-item-content">
                  <div className="__cc-navigation-item-icon">
                    <FDN.Icon icon={navItem.icon} />
                  </div>
                  <div className="__cc-navigation-item-label">
                    {FDN.I18n.t(`ccMain.navigation.${navItemKey}.label`)}
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
      <div className="__-navigation-version">v{packageJSON.version}</div>
    </div>
  );
};

export default CCNavigation;
