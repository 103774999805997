import React from "react";
import * as FDN from "src/core";
import { TActionsBauvorhabenLists } from "src/services/cc/CCBvListsService";
import { IMstListItem } from "src/types/types";

interface IBvListRowProps {
  mstListItem: IMstListItem;
  actions: TActionsBauvorhabenLists;
}

const BvListRow = ({ mstListItem }: IBvListRowProps) => {
  return (
    <div className="bv-list-row">
      <FDN.Grid full>
        <FDN.Row>
          <FDN.Cell sm={24} md={4}>
            <div className="padding">{mstListItem.title}</div>
          </FDN.Cell>
          <FDN.Cell sm={24} md={4}>
            <div className="padding">{mstListItem.description}</div>
          </FDN.Cell>
          <FDN.Cell sm={24} md={4}>
            <div className="padding">{mstListItem.progress}</div>
          </FDN.Cell>
          <FDN.Cell sm={24} md={4}>
            <div className="padding">{mstListItem.priority}</div>
          </FDN.Cell>
          <FDN.Cell sm={24} md={4}>
            <div className="padding">{FDN.formatDate(mstListItem.dueDate)}</div>
          </FDN.Cell>
          <FDN.Cell sm={24} md={4}>
            <div className="padding">{mstListItem.notes}</div>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default BvListRow;
