import * as React from "react";
import * as FDN from "src/core";
import CCLayout from "../layouts/CCLayout";
import useServiceCCTasks from "../../../../services/cc/CCTasksService";

const CCTasks: React.FunctionComponent = () => {
  const { pageLoaded, actions } = useServiceCCTasks();

  if (!pageLoaded) return <FDN.Loading />;

  return (
    <CCLayout page="cc-tasks">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "40px",
        }}
      >
        <FDN.Box>CC TASKS following...</FDN.Box>
      </div>
    </CCLayout>
  );
};

export default CCTasks;
