import React from "react";
import * as FDN from "src/core";
import { TActionsBauvorhabenLists } from "src/services/cc/CCBvListsService";
import { IMstList } from "src/types/types";
import BvListRow from "./BvListRow";

interface IBvListProps {
  mstList: IMstList;
  actions: TActionsBauvorhabenLists;
}

const BvList = ({ mstList, actions }: IBvListProps) => {
  return (
    <div className="bv-list">
      {!mstList || !mstList.items || mstList.items.length === 0 ? (
        <div className="text-center">Keine Einträge vorhanden</div>
      ) : (
        <>
          <div className="bv-list-header">
            <FDN.Grid full>
              <FDN.Row>
                <FDN.Cell sm={24} md={4}>
                  <div className="padding">{FDN.I18n.t("ccBvLists.list.header.title.title")}</div>
                </FDN.Cell>
                <FDN.Cell sm={24} md={4}>
                  <div className="padding">
                    {FDN.I18n.t("ccBvLists.list.header.description.title")}
                  </div>
                </FDN.Cell>
                <FDN.Cell sm={24} md={4}>
                  <div className="padding">
                    {FDN.I18n.t("ccBvLists.list.header.progress.title")}
                  </div>
                </FDN.Cell>
                <FDN.Cell sm={24} md={4}>
                  <div className="padding">
                    {FDN.I18n.t("ccBvLists.list.header.priority.title")}
                  </div>
                </FDN.Cell>
                <FDN.Cell sm={24} md={4}>
                  <div className="padding">{FDN.I18n.t("ccBvLists.list.header.dueDate.title")}</div>
                </FDN.Cell>
                <FDN.Cell sm={24} md={4}>
                  <div className="padding">{FDN.I18n.t("ccBvLists.list.header.notes.title")}</div>
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </div>
          {mstList.items.map((mstListItem) => {
            return (
              <BvListRow key={mstListItem._teamsId} mstListItem={mstListItem} actions={actions} />
            );
          })}
        </>
      )}
    </div>
  );
};

export default BvList;
