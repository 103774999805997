import * as React from "react";
import * as FDN from "src/core";
import { formatDateTime } from "src/core/helpers/helpers";
import { IMstFolder, TActions } from "src/types/types";

interface IFolderInfoLineProps {
  mstFolder: IMstFolder;
  lastSync?: Date;
  actions: TActions;
}

const FolderInfoLine: React.FunctionComponent<IFolderInfoLineProps> = ({
  mstFolder,
  lastSync,
  actions,
}) => {
  return (
    <div className="__filebrowser-infoline">
      <div className="__filebrowser-infoline-left">
        {/*
        <FDN.Icon icon="clock" left />
        {FDN.I18n.t("filebrowser.lastSync.text", {
          date: lastSync ? formatDateTime(lastSync, { seconds: false }) : "?",
        })}{" "}
        &nbsp;{" "}
        <button onClick={() => actions.openFolder(mstFolder._driveId, mstFolder._folderId, "1")}>
          <FDN.Icon icon="refresh" left /> {FDN.I18n.t("filebrowser.lastSync.button")}
      </button>*/}
      </div>
      <div className="__filebrowser-infoline-middle"></div>
      <div className="__filebrowser-infoline-right"></div>
    </div>
  );
};

export default FolderInfoLine;
