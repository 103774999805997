import { INewConversationPost } from "src/types/types";
import Api from "./Api";

export default class MstApi {
  public static reloadTeamsAndChannels(api: Api) {
    return api.get("mstConnect.reloadTeams");
  }

  public static filesOpenFolder(
    api: Api,
    _driveId: string,
    _folderId: string,
    forceReload: "0" | "1" = "0"
  ) {
    return api.get("mstFiles.folder.open", { _driveId, _folderId, forceReload });
  }

  public static filesOpenFolderByChannel(api: Api, teamsChannelIdentifier: string) {
    return api.get("mstFiles.folder.openByChannel", { teamsChannelIdentifier });
  }

  public static tasksReloadTask(api: Api, identifier: string) {
    return api.get("mstTasks.task.reload", { identifier });
  }

  public static conversationNewPost(api: Api, post: INewConversationPost) {
    return api.post("mstPosts.newPost", {}, { post });
  }

  public static conversationRefresh(api: Api, groupId: string, threadId: string) {
    return api.get("mstPosts.refresh", { groupId, threadId });
  }
}
