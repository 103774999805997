import { useState } from "react";
import { IBauvorhaben, IHausanschluesse, IHausanschluss, TFunction } from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import CCApi from "src/api/CCApi";
import { TActionsBauvorhaben } from "./CCBauvorhabenService";
import { cloneDeep } from "lodash";

export type HausanschlussTermine = "termin_soll" | "termin_ist" | "termin_installation";

export interface TActionsBauvorhabenHausanschluesse extends TActionsBauvorhaben {
  init: TFunction;
  hausanschluesseToggleRequired: (hausanschlussIdentifier: string) => void;
  hausanschluesseToggleStatus: (type: string, statusType: string) => void;
  showSettingsPopup: () => void;
  hideSettingsPopup: () => void;
  showTerminPopup: (hausanschlussIdentifier: string) => void;
  hideTerminPopup: () => void;
  updateTermine: (hausanschluss: IHausanschluss) => void;
}

const useServiceCCBvHausanschluesse = (bvActions: TActionsBauvorhaben) => {
  const { api, NOTIFICATIONS } = useServiceCore();

  const [bauvorhaben, setBauvorhaben] = useState<IBauvorhaben>();
  const [hausanschluesse, setHausanschluesse] = useState<IHausanschluesse>();

  const [popupSettingsVisible, setPopupSettingsVisible] = useState(false);

  const [visibleTerminPopup, setVisibleTerminPopup] = useState<string>();

  const init = (bauvorhaben: IBauvorhaben) => {
    setBauvorhaben(cloneDeep(bauvorhaben));
    CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "hausanschluesse").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        if (response?.body?.hausanschluesse)
          setHausanschluesse(response.body.hausanschluesse as IHausanschluesse);
      }
    });
  };

  const hausanschluesseToggleRequired = (hausanschlussIdentifier: string) => {
    if (!bauvorhaben) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    CCApi.ccBvHausanschluesseToggleRequired(
      api,
      bauvorhaben.identifier,
      hausanschlussIdentifier
    ).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        if (response?.body?.hausanschluesse)
          setHausanschluesse(response.body.hausanschluesse as IHausanschluesse);

        NOTIFICATIONS.showSaving();
      }
    });
  };

  const hausanschluesseToggleStatus = (type: string, statusType: string) => {
    if (!bauvorhaben) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    CCApi.ccBvHausanschluesseToggleStatus(api, bauvorhaben.identifier, type, statusType).then(
      (response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          if (response?.body?.hausanschluesse)
            setHausanschluesse(response.body.hausanschluesse as IHausanschluesse);

          NOTIFICATIONS.showSaving();
        }
      }
    );
  };

  const showSettingsPopup = () => {
    setPopupSettingsVisible(true);
  };

  const hideSettingsPopup = () => {
    setPopupSettingsVisible(false);
  };

  const showTerminPopup = (hausanschlussIdentifier: string) => {
    setVisibleTerminPopup(hausanschlussIdentifier);
  };

  const hideTerminPopup = () => {
    setVisibleTerminPopup(undefined);
  };

  const updateTermine = (hausanschluss: IHausanschluss) => {
    if (!bauvorhaben) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    CCApi.ccBvHausanschluesseUpdateTermine(
      api,
      bauvorhaben.identifier,
      hausanschluss.identifier,
      hausanschluss
    ).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        if (response?.body?.hausanschluesse)
          setHausanschluesse(response.body.hausanschluesse as IHausanschluesse);

        hideTerminPopup();
        NOTIFICATIONS.showSaving();
      }
    });
  };

  const actions: TActionsBauvorhabenHausanschluesse = {
    init,
    ...bvActions,
    hausanschluesseToggleRequired,
    hausanschluesseToggleStatus,
    showSettingsPopup,
    hideSettingsPopup,
    showTerminPopup,
    hideTerminPopup,
    updateTermine,
  };

  return { visibleTerminPopup, hausanschluesse, popupSettingsVisible, actions };
};

export default useServiceCCBvHausanschluesse;
