import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { TActionsBauvorhabenPaymentPlan } from "src/services/cc/CCBvPaymentPlanService";
import { IBvInstallment } from "src/types/types";

interface IMarkAsPopupProps {
  type: "invoiced" | "paid";
  installment: IBvInstallment;
  actions: TActionsBauvorhabenPaymentPlan;
}

const MarkAsPopup: React.FunctionComponent<IMarkAsPopupProps> = ({
  type,
  installment,
  actions,
}) => {
  const currency = Config.get("currency") as string;

  const installmentTitle = `${installment.number} ${FDN.I18n.t(
    "ccBvPaymentPlan.installments.rate.label"
  )} - ${FDN.formatNumber(installment.percent || 0, 2, 2)} % ${
    installment.text
  } (${currency}${FDN.formatNumber(installment.sum || 0, 2, 2)})`;

  return (
    <div className="paymentplan-markas-popup">
      <FDN.Popup
        size="medium"
        title={FDN.I18n.t(`ccBvPaymentPlan.markaspopup.${type}.title`)}
        onClose={actions.onCancelEditInstallment}
      >
        {type === "invoiced" && (
          <ContentMarkAsInvoiced
            installment={installment}
            installmentTitle={installmentTitle}
            actions={actions}
          />
        )}
        {type === "paid" && (
          <ContentMarkAsPaid
            installment={installment}
            installmentTitle={installmentTitle}
            actions={actions}
          />
        )}

        <FDN.FormButtons
          smallButtons
          onCancel={actions.onCancelEditInstallment}
          saveLabel={FDN.I18n.t(`ccBvPaymentPlan.markaspopup.${type}.buttons.save.label`)}
          onSave={() => actions.markAs(type, installment)}
        />
      </FDN.Popup>
    </div>
  );
};

interface IContentProps {
  installment: IBvInstallment;
  installmentTitle: string;
  actions: TActionsBauvorhabenPaymentPlan;
}

const ContentMarkAsInvoiced: React.FunctionComponent<IContentProps> = ({
  installment,
  installmentTitle,
  actions,
}) => {
  const currency = Config.get("currency") as string;

  return (
    <>
      <FDN.Box>
        <div className="text-center">{installmentTitle}</div>
      </FDN.Box>
      <FDN.Box>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="date"
                value={installment.due}
                showValue={
                  installment.due
                    ? FDN.formatDate(installment.due, { useHumanReadableDate: false })
                    : ""
                }
                label={FDN.I18n.t("ccBvPaymentPlan.markaspopup.invoiced.form.due.label")}
                editMode={true}
                editable={true}
                onUpdate={(value) => actions.onEditInstallment("due", value)}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="currency"
                options={{ currency }}
                value={installment.invoicedAmount}
                showValue={FDN.formatNumber(installment.invoicedAmount || 0, 2, 2)}
                label={FDN.I18n.t(`ccBvPaymentPlan.markaspopup.invoiced.form.invoicedAmount.label`)}
                editMode={true}
                editable={true}
                onUpdate={(value) => actions.onEditInstallment("invoicedAmount", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
    </>
  );
};

const ContentMarkAsPaid: React.FunctionComponent<IContentProps> = ({
  installment,
  installmentTitle,
  actions,
}) => {
  const currency = Config.get("currency") as string;

  return (
    <>
      <FDN.Box>
        <div className="text-center">{installmentTitle}</div>
      </FDN.Box>
      <FDN.Box>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={11}>
              <FDN.Input
                type="currency"
                options={{ currency }}
                value={installment.invoicedAmount}
                label={FDN.I18n.t("ccBvPaymentPlan.markaspopup.paid.form.invoicedAmount.label")}
                editMode={false}
                editable={false}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={2}>
              <button
                onClick={() =>
                  actions.onEditInstallment("paidAmount", installment.invoicedAmount || 0)
                }
                className="primary hollow button"
              >
                <FDN.Icon icon="long-arrow-right" />
              </button>
            </FDN.Cell>
            <FDN.Cell sm={24} md={11}>
              <FDN.Input
                type="currency"
                options={{ currency }}
                value={installment.paidAmount}
                showValue={FDN.formatNumber(installment.paidAmount || 0, 2, 2)}
                label={FDN.I18n.t(`ccBvPaymentPlan.markaspopup.paid.form.paidAmount.label`)}
                editMode={true}
                editable={true}
                onUpdate={(value) => actions.onEditInstallment("paidAmount", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
    </>
  );
};

export default MarkAsPopup;
