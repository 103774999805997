import { IHausanschluss } from "src/types/types";

export const getHausanschlussPercent = (hausanschluss: IHausanschluss): number => {
  let percent = 0;

  if (!hausanschluss.statuses || hausanschluss.statuses.length === 0) return 0;

  const perStatus = 100 / hausanschluss.statuses.length;

  for (const status of hausanschluss.statuses) {
    if (status.checked === true) percent += perStatus;
  }

  return percent;
};
