import {
  BvDetailTypeDate,
  BvDetailTypePercent,
  BvDetailTypeText,
  BvDetailTypeTrafficLight,
  BvDetailTypeYesNo,
  IAccountState,
  IBauvorhaben,
  IBvInstallment,
  IHausanschluss,
  MstSyncTypes,
} from "src/types/types";
import Api from "./Api";

export default class CCApi {
  public static ccDashboard(api: Api) {
    return api.get("cc.dashboard.index");
  }

  public static ccBauvorhabenList(api: Api) {
    return api.get("cc.bauvorhaben.index");
  }

  public static ccBauvorhabenJumpToList(api: Api) {
    return api.get("cc.bauvorhaben.jumpto");
  }

  public static ccBauvorhaben(api: Api, identifier: string) {
    return api.get("cc.bauvorhaben.show", { identifier });
  }

  public static ccBauvorhabenContent(api: Api, identifier: string, type: string) {
    return api.get("cc.bauvorhaben.content", { identifier, type });
  }
  public static ccAddBauvorhaben(api: Api, bauvorhaben: IBauvorhaben) {
    return api.post("cc.bauvorhaben.index", null, { bauvorhaben });
  }

  public static ccUpdateBauvorhaben(api: Api, identifier: string, bauvorhaben: IBauvorhaben) {
    return api.post("cc.bauvorhaben.show", { identifier }, { bauvorhaben });
  }

  public static ccBvCheckEmail(api: Api, identifier: string, email: string) {
    return api.post("cc.bauvorhaben.checkemail", { identifier }, { email });
  }

  public static ccBvGrantAccess(api: Api, identifier: string, account: IAccountState) {
    return api.post("cc.bauvorhaben.grantaccess", { identifier }, { account });
  }

  public static ccBvRevokeAccess(api: Api, identifier: string, userIdentifier: string) {
    return api.post("cc.bauvorhaben.revokeaccess", { identifier, userIdentifier });
  }

  public static ccBvHausanschluesseToggleRequired(
    api: Api,
    identifier: string,
    hausanschlussIdentifier: string
  ) {
    return api.post("cc.bauvorhaben.hausanschluesseToggleRequired", {
      identifier,
      hausanschlussIdentifier,
    });
  }

  public static ccBvHausanschluesseToggleStatus(
    api: Api,
    identifier: string,
    type: string,
    statusType: string
  ) {
    return api.post("cc.bauvorhaben.hausanschluesseToggleStatus", {
      identifier,
      type,
      statusType,
    });
  }

  public static ccBvHausanschluesseUpdateTermine(
    api: Api,
    identifier: string,
    hausanschlussIdentifier: string,
    hausanschluss: IHausanschluss
  ) {
    return api.post(
      "cc.bauvorhaben.hausanschluesseUpdate",
      {
        identifier,
        hausanschlussIdentifier,
      },
      { hausanschluss }
    );
  }

  public static ccBvDetailNextValue(
    api: Api,
    identifier: string,
    detailIdentifier: string,
    parent: string
  ) {
    return api.post("cc.bauvorhaben.detailNextValue", { identifier, detailIdentifier }, { parent });
  }

  public static ccBvUpdateSingleDetail(
    api: Api,
    identifier: string,
    detailIdentifier: string,
    value:
      | BvDetailTypeDate
      | BvDetailTypePercent
      | BvDetailTypeText
      | BvDetailTypeTrafficLight
      | BvDetailTypeYesNo
  ) {
    return api.post(
      "cc.bauvorhaben.updateSingleDetail",
      { identifier, detailIdentifier },
      { value }
    );
  }

  public static ccBvPaymentPlanInstallmentMarkAs(
    api: Api,
    identifier: string,
    installmentIdentifier: string,
    type: string,
    data: IBvInstallment | undefined
  ) {
    return api.post(
      "cc.bauvorhaben.paymentplan.installmentMarkAs",
      {
        identifier,
        installmentIdentifier,
        type,
      },
      { data }
    );
  }

  public static ccBvPaymentPlanInstallmentRevoke(
    api: Api,
    identifier: string,
    installmentIdentifier: string,
    type: string
  ) {
    return api.post("cc.bauvorhaben.paymentplan.installmentRevoke", {
      identifier,
      installmentIdentifier,
      type,
    });
  }

  public static ccBvPaymentPlanUpdateInstallment(
    api: Api,
    identifier: string,
    installmentIdentifier: string,
    data: IBvInstallment
  ) {
    return api.post(
      "cc.bauvorhaben.paymentplan.installment",
      {
        identifier,
        installmentIdentifier,
      },
      { installment: data }
    );
  }

  public static ccBvPaymentPlanDeleteInstallment(
    api: Api,
    identifier: string,
    installmentIdentifier: string
  ) {
    return api.delete("cc.bauvorhaben.paymentplan.installment", {
      identifier,
      installmentIdentifier,
    });
  }

  public static ccBvPaymentPlanUpdateInstallmentsOrder(
    api: Api,
    identifier: string,
    installments: string[]
  ) {
    return api.post(
      "cc.bauvorhaben.paymentplan.installmentsUpdateOrder",
      {
        identifier,
      },
      { installments }
    );
  }

  public static ccBvForceResync(api: Api, identifier: string, type: MstSyncTypes) {
    return api.post("cc.bauvorhaben.forceResync", {
      identifier,
      type,
    });
  }
}
