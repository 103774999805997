import * as React from "react";
import * as FDN from "src/core";
import { IMstFolder } from "src/types/types";
import FolderFilesList from "./components/FolderFilesList";
import { useServiceFileBrowser } from "./service/FileBrowser.service";
import FolderBreadcrumbs from "./components/FolderBreadcrumbs";
import FolderInfoLine from "./components/FolderInfoLine";
import FolderFileInfo from "./components/FolderFileInfo";
import PdfViewer from "src/components/main/PdfViewer";

interface IFileBrowserProps {
  initMstFolder: IMstFolder;
  lastSync?: Date;
}

const FileBrowser: React.FunctionComponent<IFileBrowserProps> = ({ initMstFolder, lastSync }) => {
  const { mstFolder, pdfViewFile, selectedFile, listLoading, actions } =
    useServiceFileBrowser(initMstFolder);

  return (
    <div className="__filebrowser">
      {listLoading === true ? (
        <FDN.Loading box />
      ) : (
        <>
          <FolderBreadcrumbs mstFolder={mstFolder} actions={actions} />
          <div className="__filebrowser-wrapper">
            <FolderFilesList mstFolder={mstFolder} selectedFile={selectedFile} actions={actions} />
            <FolderFileInfo file={selectedFile} actions={actions} />
          </div>
          <FolderInfoLine mstFolder={mstFolder} lastSync={lastSync} actions={actions} />
          {pdfViewFile && (
            <FDN.Popup size="medium" onClose={() => actions.viewPdf()}>
              <PdfViewer file={pdfViewFile} />
            </FDN.Popup>
          )}
        </>
      )}
    </div>
  );
};

export default FileBrowser;
