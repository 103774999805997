import * as React from "react";
import * as FDN from "src/core";
import { IMstFolder, IMstFolderBreadcrumb, TActions } from "src/types/types";

interface IFolderBreadcrumbsProps {
  mstFolder: IMstFolder;
  actions: TActions;
}

const FolderBreadcrumbs: React.FunctionComponent<IFolderBreadcrumbsProps> = ({
  mstFolder,
  actions,
}) => {
  if (!mstFolder.breadcrumbs || mstFolder.breadcrumbs.length === 0) return null;

  return (
    <div className={`__filebrowser-breadcrumbs`}>
      <FDN.Box small noMargin>
        {mstFolder.breadcrumbs.map((breadcrumb, index) => {
          const isLast = index + 1 === mstFolder.breadcrumbs.length;
          return (
            <React.Fragment key={index}>
              <Breadcrumb
                breadcrumb={breadcrumb}
                _driveId={mstFolder._driveId}
                isFirst={index === 0}
                isLast={isLast}
                actions={actions}
              />
              {!isLast && <Separator />}
            </React.Fragment>
          );
        })}
      </FDN.Box>
    </div>
  );
};

interface IBreadcrumbProps {
  breadcrumb: IMstFolderBreadcrumb;
  _driveId: string;
  isFirst: boolean;
  isLast: boolean;
  actions: TActions;
}

const Breadcrumb: React.FunctionComponent<IBreadcrumbProps> = ({
  breadcrumb,
  _driveId,
  isFirst,
  isLast,
  actions,
}) => {
  const disabled = isLast;
  return (
    <button
      className={`${disabled && `disabled`} __filebrowser-breadcrumbs-breadcrumb`}
      onClick={() => {
        if (!disabled) actions.openFolder(_driveId, breadcrumb._folderId);
      }}
    >
      {isFirst && <HomeIcon />} {breadcrumb.folderName}
    </button>
  );
};

const HomeIcon = () => <FDN.Icon icon="home" left />;

const Separator = () => (
  <span className="__filebrowser-breadcrumbs-separator">
    <FDN.Icon icon="angle-right" />
  </span>
);

export default FolderBreadcrumbs;
