import * as React from "react";
import CCApi from "src/api/CCApi";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import UrlService from "src/core/UrlService";
import useServiceCore from "src/services/CoreService";
import { IBauvorhaben, ICategorizedBauvorhabenList } from "src/types/types";

interface IJumpToBauvorhabenProps {
  bauvorhaben: IBauvorhaben;
}

const JumpToBauvorhaben: React.FunctionComponent<IJumpToBauvorhabenProps> = ({ bauvorhaben }) => {
  const [list, setList] = React.useState<ICategorizedBauvorhabenList>();

  const { api } = useServiceCore();

  React.useEffect(() => {
    if (!list) {
      CCApi.ccBauvorhabenJumpToList(api).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          setList(response?.body?.list as ICategorizedBauvorhabenList);
        }
      });
    }
  }, []);

  const jumpToBauvorhaben = (identifier: string) => {
    window.location.href = UrlService.url("cc.bauvorhaben.show", { identifier });
  };

  if (!list) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-jump-to-bauvorhaben">
      <select value={bauvorhaben.identifier} onChange={(e) => jumpToBauvorhaben(e.target.value)}>
        {Object.keys(list).map((_listSectionKey, index) => {
          const listSectionKey = _listSectionKey as keyof ICategorizedBauvorhabenList;
          if (!listSectionKey) return null;

          const listItems = list[listSectionKey];
          if (listItems?.length === 0) return null;

          return (
            <React.Fragment key={index}>
              {listSectionKey !== "PLANUNG" && <option disabled></option>}
              <option disabled>
                {FDN.I18n.t(`ccBauvorhaben.list.tabs.${listSectionKey}.label`)}
              </option>
              {listSectionKey && list[listSectionKey] ? (
                <>
                  {list[listSectionKey]?.map((item, index) => {
                    return (
                      <option key={index} value={item.identifier}>
                        &nbsp;&nbsp; {item.name}, {item.zip} {item.city}
                      </option>
                    );
                  })}
                </>
              ) : null}
            </React.Fragment>
          );
        })}
      </select>
    </div>
  );
};

export default JumpToBauvorhaben;
