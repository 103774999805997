import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { TActionsBauvorhabenPaymentPlan } from "src/services/cc/CCBvPaymentPlanService";
import { IBauvorhaben, IBvInstallment, IBvPaymentPlan } from "src/types/types";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CCApi from "src/api/CCApi";
import Api from "src/api/Api";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IInstallmentsListProps {
  bauvorhaben: IBauvorhaben;
  paymentPlan: IBvPaymentPlan;
  editMode: boolean;
  actions: TActionsBauvorhabenPaymentPlan;
}

const InstallmentsList: React.FunctionComponent<IInstallmentsListProps> = ({
  bauvorhaben,
  paymentPlan,
  editMode,
  actions,
}) => {
  return (
    <div className="paymentplan-installments">
      <Header />
      <Body
        bauvorhaben={bauvorhaben}
        paymentPlan={paymentPlan}
        editMode={editMode}
        actions={actions}
      />
      <Footer paymentPlan={paymentPlan} />
    </div>
  );
};

const Header: React.FunctionComponent = () => (
  <div className="__adminlist-head hide-for-small-only">
    <FDN.Grid full>
      <FDN.Row>
        <FDN.Cell md={11}>
          <div className="__adminlist-cell">
            {FDN.I18n.t("ccBvPaymentPlan.installments.head.name.title")}
          </div>
        </FDN.Cell>
        <FDN.Cell md={4}>
          <div className="__adminlist-cell text-center">
            {FDN.I18n.t("ccBvPaymentPlan.installments.head.sum.title")}
          </div>
        </FDN.Cell>
        <FDN.Cell md={3}>
          <div className="__adminlist-cell text-center">
            {FDN.I18n.t("ccBvPaymentPlan.installments.head.rate.title")}
          </div>
        </FDN.Cell>
        <FDN.Cell md={3}>
          <div className="__adminlist-cell text-center">
            {FDN.I18n.t("ccBvPaymentPlan.installments.head.bnk.title")}
          </div>
        </FDN.Cell>
        <FDN.Cell md={1}>
          <div className="__adminlist-cell text-center">
            <FDN.ToolTip tooltip={FDN.I18n.t("ccBvPaymentPlan.installments.head.approved.title")}>
              <FDN.Icon icon="thumbs-o-up" />
            </FDN.ToolTip>
          </div>
        </FDN.Cell>
        <FDN.Cell md={1}>
          <div className="__adminlist-cell text-center">
            <FDN.ToolTip tooltip={FDN.I18n.t("ccBvPaymentPlan.installments.head.invoiced.title")}>
              <FDN.Icon icon="file-text-o" />
            </FDN.ToolTip>
          </div>
        </FDN.Cell>
        <FDN.Cell md={1}>
          <div className="__adminlist-cell text-center">
            <FDN.ToolTip tooltip={FDN.I18n.t("ccBvPaymentPlan.installments.head.paid.title")}>
              <FDN.Icon icon="euro" />
            </FDN.ToolTip>
          </div>
        </FDN.Cell>
      </FDN.Row>
    </FDN.Grid>
  </div>
);

const Body: React.FunctionComponent<IInstallmentsListProps> = ({
  bauvorhaben,
  paymentPlan,
  editMode,
  actions,
}) => {
  const [items, setItems] = React.useState<string[]>([]);

  const { api, NOTIFICATIONS } = useServiceCore();

  React.useEffect(() => {
    if (!paymentPlan.installments) {
      setItems([]);
      return;
    }

    const updatedItems = paymentPlan.installments.map((i) => i.identifier);
    if (updatedItems.length !== items.length) setItems(updatedItems);
  }, [paymentPlan.installments]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    const updateOrder = (updatedItems: string[]) => {
      CCApi.ccBvPaymentPlanUpdateInstallmentsOrder(api, bauvorhaben.identifier, updatedItems).then(
        (response) => {
          if (response?.statusCode === StatusCode.SUCCESS) {
            NOTIFICATIONS.showNotification(
              "success",
              FDN.I18n.t("ccBvPaymentPlan.onUpdateInstallmentsOrder.success.title"),
              FDN.I18n.t("ccBvPaymentPlan.onUpdateInstallmentsOrder.success.text")
            );
          }
        }
      );
    };

    if (!active?.id || !over?.id) return items;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id as string);
        const newIndex = items.indexOf(over.id as string);

        const updatedItems = arrayMove(items, oldIndex, newIndex);

        updateOrder(updatedItems);

        return updatedItems;
      });
    }
  }

  return (
    <div>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((id, index) => {
            return (
              <SortableItem
                key={id}
                id={id}
                installment={paymentPlan.installments?.find((i) => i.identifier === id)}
                actions={actions}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
};

interface ISortableItemProps {
  id: string;
  installment?: IBvInstallment;
  actions: TActionsBauvorhabenPaymentPlan;
}

const SortableItem: React.FunctionComponent<ISortableItemProps> = ({
  id,
  installment,
  actions,
}) => {
  if (!installment) return null;

  const { userView } = React.useContext(AppContext);

  const { setNodeRef, transform, transition, listeners, attributes } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="__adminlist-row">
      <FDN.Grid full>
        <FDN.Row>
          <FDN.Cell md={1}>
            <div
              className="__adminlist-cell text-center __admin-list-drag-handle drag-handle"
              {...listeners}
              {...attributes}
            >
              <FDN.Icon icon="bars" />
            </div>
          </FDN.Cell>
          <FDN.Cell md={10}>
            <div className="__adminlist-cell nopadding">
              <button
                className={`edit-button view-${userView}`}
                onClick={() => {
                  if (userView === "cc") actions.selectInstallment(installment);
                }}
              >
                {installment.type === "bnk" || installment.type === "building" ? (
                  <>
                    {installment.type === "bnk" ? (
                      <>
                        <span className="label">BNK</span>&nbsp;
                      </>
                    ) : null}
                    {installment.number} {FDN.I18n.t("ccBvPaymentPlan.installments.rate.label")} -{" "}
                    {FDN.formatNumber(installment.percent || 0, 2, 2)} % {installment.text}
                  </>
                ) : (
                  <>
                    {installment.type === "mehr" ? "+" : "-"} {installment.text}
                  </>
                )}
              </button>
            </div>
          </FDN.Cell>
          <FDN.Cell md={4}>
            <div className="__adminlist-cell text-center">
              <Sum installment={installment} />
            </div>
          </FDN.Cell>
          <FDN.Cell md={3}>
            <div className="__adminlist-cell text-center">
              {installment.type === "building" ? (
                <>{FDN.formatNumber(installment.percent || 0, 2, 2)} %</>
              ) : null}
            </div>
          </FDN.Cell>
          <FDN.Cell md={3}>
            <div className="__adminlist-cell text-center">
              {installment.type === "bnk" ? (
                <>{FDN.formatNumber(installment.percent || 0, 2, 2)} %</>
              ) : null}
            </div>
          </FDN.Cell>
          <FDN.Cell md={1}>
            <div className="__adminlist-cell nopadding text-center">
              <FDN.ToolTip tooltip={FDN.I18n.t("ccBvPaymentPlan.installments.head.approved.title")}>
                <CheckBoxContent installment={installment} type="approved" actions={actions} />
              </FDN.ToolTip>
            </div>
          </FDN.Cell>
          <FDN.Cell md={1}>
            <div className="__adminlist-cell nopadding  text-center">
              <FDN.ToolTip tooltip={FDN.I18n.t("ccBvPaymentPlan.installments.head.invoiced.title")}>
                <CheckBoxContent installment={installment} type="invoiced" actions={actions} />
              </FDN.ToolTip>
            </div>
          </FDN.Cell>
          <FDN.Cell md={1}>
            <div className="__adminlist-cell nopadding text-center">
              <FDN.ToolTip tooltip={FDN.I18n.t("ccBvPaymentPlan.installments.head.paid.title")}>
                <CheckBoxContent installment={installment} type="paid" actions={actions} />
              </FDN.ToolTip>
            </div>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface ISumProps {
  installment: IBvInstallment;
}

const Sum: React.FunctionComponent<ISumProps> = ({ installment }) => {
  const currency = Config.get("currency") as string;

  if (installment.status === "APPROVED") {
    return (
      <>
        <strong>
          {currency}
          {FDN.formatNumber(installment.sum || 0, 2, 2)}
        </strong>
      </>
    );
  } else if (installment.status === "INVOICED") {
    return (
      <>
        <strong>
          {currency}
          {FDN.formatNumber(installment.invoicedAmount || 0, 2, 2)}
        </strong>
        {installment.sum !== installment.invoicedAmount ? (
          <div>
            <small>
              von {currency}
              {FDN.formatNumber(installment.sum || 0, 2, 2)}
            </small>
          </div>
        ) : null}
      </>
    );
  } else if (installment.status === "PAID") {
    return (
      <>
        {installment.sum === installment.paidAmount ? (
          <strong className="success-text">
            <FDN.Icon icon="check" left />
            {currency}
            {FDN.formatNumber(installment.paidAmount || 0, 2, 2)}
          </strong>
        ) : (
          <>
            <strong>
              {currency}
              {FDN.formatNumber(installment.paidAmount || 0, 2, 2)}
            </strong>

            <div>
              <small>
                von {currency}
                {FDN.formatNumber(installment.sum || 0, 2, 2)}
              </small>
            </div>
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <span>
          {currency}
          {FDN.formatNumber(installment.sum || 0, 2, 2)}
        </span>
      </>
    );
  }
};

interface ICheckBoxContentProps {
  installment: IBvInstallment;
  type: "approved" | "invoiced" | "paid";
  actions: TActionsBauvorhabenPaymentPlan;
}

const CheckBoxContent: React.FunctionComponent<ICheckBoxContentProps> = ({
  installment,
  type,
  actions,
}) => {
  const { userView } = React.useContext(AppContext);

  if (type === "approved") {
    if (installment.approved)
      return (
        <button
          className={`checkbox-button checked view-${userView}`}
          onClick={() => {
            if (userView === "cc") actions.revoke("approved", installment);
          }}
        >
          <FDN.Icon icon="check" />
        </button>
      );
    else
      return (
        <FDN.ToolTip tooltip={FDN.I18n.t("ccBvPaymentPlan.actions.markas.approved.tooltip")}>
          <button
            className={`checkbox-button not-checked view-${userView}`}
            onClick={() => {
              if (userView === "cc") actions.markAs("approved", installment);
            }}
          >
            <FDN.Icon icon="thumbs-o-up" />
          </button>
        </FDN.ToolTip>
      );
  }

  if (type === "invoiced") {
    if (installment.invoiced)
      return (
        <button
          className={`checkbox-button checked view-${userView}`}
          onClick={() => {
            if (userView === "cc") actions.revoke("invoiced", installment);
          }}
        >
          <FDN.Icon icon="check" />
        </button>
      );
    else if (installment.approved)
      return (
        <FDN.ToolTip tooltip={FDN.I18n.t("ccBvPaymentPlan.actions.markas.approved.tooltip")}>
          <button
            className={`checkbox-button not-checked view-${userView}`}
            onClick={() => {
              if (userView === "cc") actions.selectForMarkAs("invoiced", installment);
            }}
          >
            <FDN.Icon icon="file-text-o" />
          </button>
        </FDN.ToolTip>
      );
    else return null;
  }
  if (type === "paid") {
    if (installment.paid)
      return (
        <button
          className={`checkbox-button checked view-${userView}`}
          onClick={() => {
            if (userView === "cc") actions.revoke("paid", installment);
          }}
        >
          <FDN.Icon icon="check" />
        </button>
      );
    else if (installment.approved && installment.invoiced)
      return (
        <FDN.ToolTip tooltip={FDN.I18n.t("ccBvPaymentPlan.actions.markas.approved.tooltip")}>
          <button
            className={`checkbox-button not-checked view-${userView}`}
            onClick={() => {
              if (userView === "cc") actions.selectForMarkAs("paid", installment);
            }}
          >
            <FDN.Icon icon="euro" />
          </button>
        </FDN.ToolTip>
      );
    else return null;
  }

  return <>?</>;
};

interface IFooterProps {
  paymentPlan: IBvPaymentPlan;
}

const Footer: React.FunctionComponent<IFooterProps> = ({ paymentPlan }) => {
  const currency = Config.get("currency") as string;

  return (
    <div className="__adminlist-head paymentplan-installments-footer hide-for-small-only">
      <FDN.Grid full>
        <FDN.Row>
          <FDN.Cell md={11}>
            <div className="__adminlist-cell">&nbsp;</div>
          </FDN.Cell>
          <FDN.Cell md={4}>
            <div className="__adminlist-cell text-center">
              {currency}
              {FDN.formatNumber(paymentPlan.sumInstallments, 2, 2)}
              {paymentPlan.sumInstallments !== paymentPlan.sumTotal ? (
                <span className="error-text">
                  {" "}
                  <FDN.Icon icon="exclamation-triangle" />
                </span>
              ) : null}
            </div>
          </FDN.Cell>
          <FDN.Cell md={3}>
            <div className="__adminlist-cell text-center">
              {FDN.formatNumber(paymentPlan.percentBuildingInstallments, 2, 2)} %
              {paymentPlan.percentBuildingInstallments !== 100 ? (
                <span className="error-text">
                  {" "}
                  <FDN.Icon icon="exclamation-triangle" />
                </span>
              ) : null}
            </div>
          </FDN.Cell>
          <FDN.Cell md={3}>
            <div className="__adminlist-cell text-center">
              {FDN.formatNumber(paymentPlan.percentBnkInstallments, 2, 2)} %
              {paymentPlan.percentBnkInstallments !== 100 ? (
                <span className="error-text">
                  {" "}
                  <FDN.Icon icon="exclamation-triangle" />
                </span>
              ) : null}
            </div>
          </FDN.Cell>
          <FDN.Cell md={1}>
            <div className="__adminlist-cell text-center">&nbsp;</div>
          </FDN.Cell>
          <FDN.Cell md={1}>
            <div className="__adminlist-cell text-center">&nbsp;</div>
          </FDN.Cell>
          <FDN.Cell md={1}>
            <div className="__adminlist-cell text-center">&nbsp;</div>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default InstallmentsList;
