import React, { useState } from "react";
import * as FDN from "src/core";
import { useNavigate } from "react-router-dom";

const tabs: FDN.ITabs[] = [
  {
    status: {
      icon: "th-large",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.status.label"),
    },
    bauakte: {
      icon: "files-o",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.bauakte.label"),
    },
    aufgaben: {
      icon: "server",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.aufgaben.label"),
    },
    lists: {
      icon: "list",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.lists.label"),
    },
    termine: {
      icon: "calendar-o",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.termine.label"),
    },
    fotos: {
      icon: "file-picture-o",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.fotos.label"),
      disabled: true,
    },
    dateien: {
      icon: "inbox",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.dateien.label"),
    },
  },
  {
    knowledgebase: {
      icon: "book",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.knowledgebase.label"),
    },
    formulare: {
      icon: "file-text-o",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.documents.label"),
    },
  },
  {
    bauvorbereitung: {
      icon: "signal",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.bauvorbereitung.label"),
    },
    apl: {
      icon: "object-group",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.apl.label"),
      disabled: true,
    },
    hausanschluesse: {
      icon: "plug",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.hausanschluesse.label"),
    },
    waermeschutz: {
      icon: "snowflake-o",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.waermeschutz.label"),
      disabled: true,
    },
  },
  // {
  //   vergabe: {
  //     icon: "handshake-o",
  //     label: FDN.I18n.t("ccBauvorhaben.bv.tabs.vergabe.label"),
  //     disabled: true,
  //   },
  //   foerderungen: {
  //     icon: "database",
  //     label: FDN.I18n.t("ccBauvorhaben.bv.tabs.foerderungen.label"),
  //     disabled: true,
  //   },
  // },
  {
    paymentplan: {
      icon: "euro",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.paymentplan.label"),
    },
    nachtraege: {
      icon: "plus-square-o",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.nachtraege.label"),
      disabled: true,
    },
    maengelanzeigen: {
      icon: "exclamation-triangle",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.maengelanzeigen.label"),
      disabled: true,
    },
  },
  {
    informationen: {
      icon: "id-card-o",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.informationen.label"),
    },
    verantwortliche: {
      icon: "users",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.verantwortliche.label"),
      disabled: true,
    },
    clients: {
      icon: "key",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.clients.label"),
    },
    einstellungen: {
      icon: "cog",
      label: FDN.I18n.t("ccBauvorhaben.bv.tabs.einstellungen.label"),
    },
  },
];

const defaultTab = "status";

const useServiceCCBauvorhabenTabs = () => {
  const [selectedTab, setSelectedTab] = useState<string>(defaultTab);
  const [tabsLoaded, setTabsLoaded] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get("tab");

    if (tab && tab !== selectedTab) setSelectedTab(tab);
  }, [window.location.search]);

  React.useEffect(() => {
    if (tabsLoaded !== true) {
      setTabsLoaded(true);
    } else {
      const params = new URLSearchParams(window.location.search);

      params.set("tab", selectedTab);
      navigate({ search: params.toString() });
    }
  }, [selectedTab]);

  const onSelectTab = (tabKey: string) => {
    setSelectedTab(tabKey);
  };

  return { tabs, selectedTab, onSelectTab };
};

export default useServiceCCBauvorhabenTabs;
