import Api from "./Api";

export default class UserApi {
  public static myAccount(api: Api) {
    return api.get("user.myaccount");
  }

  public static saveMyAccount(api: Api, account: { [key: string]: any }) {
    return api.post("user.myaccount", null, { account });
  }

  public static setPassword(api: Api, oldpassword: string, password: string) {
    return api.post(
      "user.setPassword",
      {},
      { oldpassword, password },
      { pass401: true }
    );
  }

  public static updateUserSetting(api: Api, key: string, value: any) {
    return api.post(
      "user.usersetting",
      null,
      { key, value },
      { ignoreHandleApiResponse: true }
    );
  }
}
