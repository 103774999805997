import { isArray } from "lodash";
import * as React from "react";
import * as FDN from "src/core";
import { IBvDetail } from "src/types/types";
import BvDetail from "../../parts/BvDetail";
import { bvDetailsMapping } from "src/config/bauvorhaben";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { userHasPermission } from "src/core/AdminService/helpers";

interface IBvDetailsProps {
  details?: IBvDetail;
}

const BvDetails: React.FunctionComponent<IBvDetailsProps> = ({ details }) => {
  const [view, setView] = React.useState<"default" | "priority">("default");

  if (!details) return <FDN.Loading box />;

  return (
    <div className="__bv-status-details">
      <FDN.Box noPadding>
        <div className="__box-padding">
          <h3 style={{ marginBottom: "0" }}>{FDN.I18n.t("ccBvStatus.details.title")}</h3>
        </div>
        <div className="__box-padding">
          {view === "default" && <ViewDefault details={details} />}
        </div>
      </FDN.Box>
    </div>
  );
};

const ViewDefault: React.FunctionComponent<IBvDetailsProps> = ({ details }) => {
  if (!details) return null;

  const APP = React.useContext(AppContext);
  const userView = APP.userView;

  let previousItemWasGroup = false;

  return (
    <div className="__bv-status-details-view-default">
      {bvDetailsMapping.map((mapValue, index) => {
        if (isArray(mapValue)) {
          const content = (
            <React.Fragment key={index}>
              {previousItemWasGroup === false && <DetailSeparator />}
              {mapValue.map((mapValueChild, childIndex) => {
                return (
                  <BvDetail
                    parent="details"
                    bvIdentifier={details.bvIdentifier}
                    type={mapValueChild}
                    value={details[mapValueChild as keyof typeof details]}
                    isEditable={userView === "cc"}
                    key={childIndex}
                  />
                );
              })}
              {index !== bvDetailsMapping.length - 1 && <DetailSeparator />}
            </React.Fragment>
          );

          previousItemWasGroup = true;

          return content;
        } else {
          previousItemWasGroup = false;
          return (
            <BvDetail
              parent="details"
              bvIdentifier={details.bvIdentifier}
              value={details[mapValue as keyof typeof details]}
              type={mapValue}
              isEditable={userView === "cc"}
              key={index}
            />
          );
        }
      })}
    </div>
  );
};

const DetailSeparator: React.FunctionComponent = () => {
  return (
    <div className="__bv-status-details-separator">
      <hr />
    </div>
  );
};

export default BvDetails;
