import * as React from "react";
import * as FDN from "src/core";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";
import { EmailTemplatesState, TActions } from "src/types/types";
import { cloneDeep } from "lodash";
import UrlService from "src/core/UrlService";
import { AppContext } from "src/components/context/AppContext/AppContext";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: {
      md: 14,
    },
    content: FDN.I18n.t("adminEmailTemplates.list.head.name.title"),
  },
  {
    size: {
      md: 10,
    },
    content: FDN.I18n.t("adminEmailTemplates.list.head.subject.title"),
  },
];

const listSchema: IAdminListSchema = {
  rowMargin: undefined,
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

interface IBuildAdminList {
  emailTemplates: EmailTemplatesState;
  actions: TActions;
  availableLanguages: { [key: string]: any };
}

const buildAdminList = ({ emailTemplates, availableLanguages }: IBuildAdminList) => {
  if (!emailTemplates) return;

  const adminListSchema = cloneDeep(listSchema);

  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const emailTemplateIdentifier of Object.keys(emailTemplates)) {
    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.url = UrlService.url(`admin.emailtemplates.show`, { identifier: emailTemplateIdentifier });

    row.columns[0].content = (
      <>
        {Object.keys(availableLanguages).map((availableLanguageKey) => {
          const availableLanguage = availableLanguages[availableLanguageKey];
          const emailTemplate = emailTemplates[emailTemplateIdentifier][availableLanguageKey];

          return (
            <div className="__admin-emailtemplates-list-line" key={availableLanguageKey}>
              {availableLanguage.icon} {emailTemplate?.name || "?"}
            </div>
          );
        })}
      </>
    );

    row.columns[1].content = (
      <>
        {Object.keys(availableLanguages).map((availableLanguageKey) => {
          const availableLanguage = availableLanguages[availableLanguageKey];
          const emailTemplate = emailTemplates[emailTemplateIdentifier][availableLanguageKey];

          return (
            <div className="__admin-emailtemplates-list-line" key={availableLanguageKey}>
              {availableLanguage.icon} {emailTemplate?.rawSubject || "?"}
            </div>
          );
        })}
      </>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IEmailTemplatesListProps {
  emailTemplates?: EmailTemplatesState;
  actions?: TActions;
}

const EmailTemplatesList: React.FunctionComponent<IEmailTemplatesListProps> = ({
  emailTemplates,
  actions,
}) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  const APP = React.useContext(AppContext);

  React.useEffect(() => {
    if (!emailTemplates || !actions) return;

    setAdminListSchema(buildAdminList({ emailTemplates, actions, availableLanguages }));
    setListLoading(false);
  }, [emailTemplates]);

  const appI18n = APP.getI18n();
  if (!appI18n || !appI18n.I18n.availableLanguages) return null;
  const { availableLanguages } = appI18n;

  if (!emailTemplates || !actions) return null;

  return <>{adminListSchema && <FDN.AdminList schema={adminListSchema} loading={listLoading} />}</>;
};

export default EmailTemplatesList;
