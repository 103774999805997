import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben } from "src/types/types";
import Config from "src/core/Config";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";

interface IBvInformationenProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BvInformationen: React.FunctionComponent<IBvInformationenProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  return (
    <div className="__cc-bauvorhaben-informationen-info">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={14}>
            <FormGroupStatus bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
            <FormGroupBauvorhaben bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
          </FDN.Cell>
          <FDN.Cell md={10}>
            <FormGroupImage bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

//interface IFormGroupProps extends IBvInformationenProps {}

const FormGroupStatus: React.FunctionComponent<IBvInformationenProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  const configBvStatus = Config.get("bauvorhaben.status") as string[];

  const selectValues: { [key: string]: string | null } = {};
  for (const statusKey of configBvStatus) {
    selectValues[statusKey] = FDN.I18n.t(`ccBauvorhaben.status.${statusKey}.label`);
  }

  return (
    <FDN.FormGroup title={FDN.I18n.t("ccBvInformationen.info.group.status.title")}>
      <FDN.SingleRowCell full>
        <FDN.Input
          editable={true}
          editMode={editMode}
          type="select"
          selectValues={selectValues}
          value={bauvorhaben.status}
          showValue={FDN.I18n.t(`ccBauvorhaben.status.${bauvorhaben.status}.label`)}
          label={FDN.I18n.t("ccBauvorhaben.form.status.label")}
          placeholder={FDN.I18n.t("ccBauvorhaben.form.status.placeholder")}
          onUpdate={(value) => actions.onEdit("status", value)}
        />
      </FDN.SingleRowCell>
    </FDN.FormGroup>
  );
};

const FormGroupImage: React.FunctionComponent<IBvInformationenProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("ccBvInformationen.info.group.image.title")}>
      <FDN.SingleRowCell full>
        <FDN.SingleImageUploadWithImageWrapper
          imageUrl={bauvorhaben.imageUrl}
          cropAspect={16 / 10}
          editMode={editMode}
          onDelete={() => actions.onEdit("imageUrl", "")}
          onUpdate={(imageUrl) => actions.onEdit("imageUrl", imageUrl)}
        />
      </FDN.SingleRowCell>
    </FDN.FormGroup>
  );
};

const FormGroupBauvorhaben: React.FunctionComponent<IBvInformationenProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  const selectValuesKategorien: { [key: string]: string } = {
    "": "",
  };

  for (const kategorie of Config.get("bauvorhaben.kategorien") as string[]) {
    selectValuesKategorien[kategorie] = FDN.I18n.t(`ccBauvorhaben.kategorie.${kategorie}.long`);
  }

  const selectValuesEnergiestandards: { [key: string]: string } = {
    "": "",
  };

  for (const kategorie of Config.get("bauvorhaben.energiestandards") as string[]) {
    selectValuesEnergiestandards[kategorie] = FDN.I18n.t(
      `ccBauvorhaben.energiestandard.${kategorie}.long`
    );
  }

  return (
    <FDN.FormGroup title={FDN.I18n.t("ccBvInformationen.info.group.bauvorhaben.title")}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <FDN.Input
              editable={true}
              editMode={editMode}
              type="text"
              value={bauvorhaben.name}
              label={FDN.I18n.t("ccBvInformationen.info.form.name.label")}
              placeholder={FDN.I18n.t("ccBvInformationen.info.form.name.placeholder")}
              onUpdate={(value) => actions.onEdit("name", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell md={12}>
            <FDN.Input
              editable={true}
              editMode={editMode}
              type="select"
              selectValues={selectValuesKategorien}
              value={bauvorhaben.kategorie}
              showValue={
                bauvorhaben.kategorie
                  ? FDN.I18n.t(`ccBauvorhaben.kategorie.${bauvorhaben.kategorie}.long`)
                  : ""
              }
              label={FDN.I18n.t("ccBvInformationen.info.form.kategorie.label")}
              placeholder={FDN.I18n.t("ccBvInformationen.info.form.kategorie.placeholder")}
              onUpdate={(value) => actions.onEdit("kategorie", value)}
            />
          </FDN.Cell>
          <FDN.Cell md={12}>
            <FDN.Input
              editable={true}
              editMode={editMode}
              type="select"
              selectValues={selectValuesEnergiestandards}
              value={bauvorhaben.energiestandard}
              showValue={
                bauvorhaben.energiestandard
                  ? FDN.I18n.t(`ccBauvorhaben.energiestandard.${bauvorhaben.energiestandard}.long`)
                  : ""
              }
              label={FDN.I18n.t("ccBvInformationen.info.form.energiestandard.label")}
              placeholder={FDN.I18n.t("ccBvInformationen.info.form.energiestandard.placeholder")}
              onUpdate={(value) => actions.onEdit("energiestandard", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell md={12}>
            <FDN.Input
              editable={true}
              editMode={editMode}
              type="currency"
              options={{ allowNegative: false, currency: Config.get("currency") as string }}
              value={bauvorhaben.hauspreis}
              showValue={bauvorhaben.hauspreis}
              label={FDN.I18n.t("ccBvInformationen.info.form.hauspreis.label")}
              placeholder={FDN.I18n.t("ccBvInformationen.info.form.hauspreis.placeholder")}
              onUpdate={(value) => actions.onEdit("hauspreis", value)}
            />
          </FDN.Cell>
          <FDN.Cell md={12}>
            <FDN.Input
              editable={true}
              editMode={editMode}
              type="currency"
              options={{ allowNegative: false, currency: Config.get("currency") as string }}
              value={bauvorhaben.bnk}
              showValue={bauvorhaben.bnk}
              label={FDN.I18n.t("ccBvInformationen.info.form.bnk.label")}
              placeholder={FDN.I18n.t("ccBvInformationen.info.form.bnk.placeholder")}
              onUpdate={(value) => actions.onEdit("bnk", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.FormGroup>
  );
};

export default BvInformationen;
