import React, { useContext, useEffect, useRef, useState } from "react";
import "react-notifications-component/dist/theme.css";
import SocketStatusCode from "src/config/socket/SocketConnectionStatus";
import io, { Socket } from "socket.io-client";
import Api from "src/api/Api";
import { AppContext } from "../AppContext/AppContext";
import { cloneDeep } from "lodash";
import { FGetUserNotifications, IUserNotifications } from "../NotificationsContext/types";
import { SocketEvents } from "src/config/socket/SocketEvents";
import { FEmit, FGetUserOnlineStatus, FUserNotificationsToggleRead } from "./types";
import { IMstTasksPlan, OnlineUsers, TFunction, UserOnlineStatus } from "src/types/types";
import { Logger } from "src/core";
import Config from "src/core/Config";
interface ISocketContextProviderProps {
  children?: any;
}

interface ISocketContext {
  connectionStatus: SocketStatusCode;
  getUserOnlineStatus: FGetUserOnlineStatus;
  getUserNotifications: FGetUserNotifications;
  refreshTasksPlan: TFunction;
  emit: FEmit;
  userNotificationsToggleRead: FUserNotificationsToggleRead;
  tasksPlans: { [key: string]: IMstTasksPlan };
}

const initialContextValue: ISocketContext = {
  connectionStatus: SocketStatusCode.NO_STATUS,
  getUserOnlineStatus: () => "OFFLINE" as UserOnlineStatus,
  getUserNotifications: () => undefined,
  refreshTasksPlan: () => null,
  emit: () => null,
  userNotificationsToggleRead: () => null,
  tasksPlans: {},
};

export const SocketContext = React.createContext<ISocketContext>(initialContextValue);

export const SocketContextProvider = (props: ISocketContextProviderProps) => {
  const [contextValue, setContextValue] = useState(initialContextValue);

  const [onlineUsers, setOnlineUsers] = useState<OnlineUsers>();
  const [userNotifications, setUserNotifications] = useState<IUserNotifications>();

  const userRef = useRef<{ [key: string]: any }>();
  const socketRef = useRef<Socket>();

  const APP = useContext(AppContext);
  const user = APP.getUser();

  useEffect(() => {
    const api = new Api();

    if (!api.isSocketEnabled()) return;

    const socketEndpoint = api.getSocketHost();

    if (!user || user?.email === userRef?.current?.email) return;

    userRef.current = user;

    if (!socketEndpoint) return;

    const query = {
      user_identifier: user.identifier ? user.identifier : "",
      user_firstname: user.firstname ? user.firstname : "",
      user_lastname: user.lastname ? user.lastname : "",
      user_displayname: user.displayname ? user.displayname : "",
      user_avatar: user.avatar ? user.avatar : "",
    };

    if (Config.isDebug()) Logger.info("[SOCKETIO] Connecting to websocket server...");

    socketRef.current = io(socketEndpoint, { query });

    socketRef.current.on("connect", () => {
      contextValue.connectionStatus = SocketStatusCode.CONNECTED;
      setContextValue(cloneDeep(contextValue));
    });

    // Connected users
    socketRef.current.on(SocketEvents.ONLINEUSERS_UPDATE, (value) => {
      //if (Config.isDebug()) Logger.success(`[SOCKETIO] ${SocketEvents.ONLINEUSERS_UPDATE}`, value);
      if (value) setOnlineUsers(value as OnlineUsers);
    });

    // UserNotifications
    socketRef.current.on(SocketEvents.USERNOTIFICATIONS_UPDATE, (value) => {
      if (Config.isDebug())
        Logger.success(`[SOCKETIO] ${SocketEvents.USERNOTIFICATIONS_UPDATE}`, value);
      if (value) setUserNotifications(value as IUserNotifications);
    });

    // TasksPlan Refresh Percent
    socketRef.current.on(SocketEvents.MSTTASKSPLAN_REFRESH_PERCENT, (value) => {
      console.log("value percent", value?.syncPercent);
      if (value && value.identifier) {
        contextValue.tasksPlans[value.identifier] = value;
        setContextValue(cloneDeep(contextValue));
      }
    });

    socketRef.current.on("disconnect", () => {
      if (Config.isDebug()) Logger.info("[SOCKETIO] Disconnecting...");
      contextValue.connectionStatus = SocketStatusCode.DISCONNECTED;
      setContextValue(cloneDeep(contextValue));
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.off("connect");
        socketRef.current.off("disconnect");
      }
    };
  }, [user?.identifier]);

  useEffect(() => {
    if (Config.isDebug())
      Logger.info("[SOCKETIO] ConnectionStatus changed to: ", contextValue.connectionStatus);
  }, [contextValue.connectionStatus]);

  const getUserNotifications = () => {
    return userNotifications;
  };

  const getUserOnlineStatus = (userIdentifier: string): UserOnlineStatus => {
    let onlineStatus: UserOnlineStatus = "UNKNOWN";

    if (!onlineUsers || contextValue.connectionStatus !== SocketStatusCode.CONNECTED)
      return onlineStatus;

    if (userIdentifier in onlineUsers) {
      onlineStatus = onlineUsers[userIdentifier].onlineStatus;
    } else {
      onlineStatus = "OFFLINE";
    }

    return onlineStatus;
  };

  const refreshTasksPlan = (mstTasksPlanIdentifier: string): IMstTasksPlan | undefined | null => {
    Logger.info(`[SOCKETIO] Refreshing tasksPlan [Identifier: ${mstTasksPlanIdentifier}]`);
    socketRef.current?.emit(SocketEvents.MSTTASKSPLAN_REFRESH, mstTasksPlanIdentifier);

    return null;
  };

  const emit: FEmit = (eventName, value) => {
    console.log("eventname", value);
    //socketRef.current?.emit(eventName, value);
  };

  const userNotificationsToggleRead: FUserNotificationsToggleRead = (
    userNotificationIdentifier
  ) => {
    socketRef.current?.emit(SocketEvents.USERNOTIFICATIONS_TOGGLEREAD, userNotificationIdentifier);
  };

  const context = {
    ...contextValue,
    getUserOnlineStatus,
    getUserNotifications,
    refreshTasksPlan,
    emit,
    userNotificationsToggleRead,
  };

  return <SocketContext.Provider value={context}>{props.children}</SocketContext.Provider>;
};
