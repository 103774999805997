import React from "react";
import * as FDN from "src/core";
import { TActions, IUserSettingsState, ITeamsUserInfo } from "src/types/types";
import useServiceCore from "./CoreService";
import StatusCode from "src/config/statuscodes";
import UserApi from "src/api/UserApi";

const useServiceMySettings = () => {
  const [userSettings, setUserSettings] = React.useState<IUserSettingsState>();
  const [teamsUserInfo, setTeamsUserInfo] = React.useState<ITeamsUserInfo>();
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);

  const { APP, api } = useServiceCore();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    UserApi.myAccount(api).then((response) => {
      APP.setPageTitle(FDN.I18n.t("usersettings.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        setUserSettings(response?.body?.userSettings as IUserSettingsState);
        if (response?.body?.teamsUserInfo)
          setTeamsUserInfo(response?.body?.teamsUserInfo as ITeamsUserInfo);
      }
    });
  };

  const onEdit = (property: string, value: any): void => {
    setUserSettings({ ...userSettings, [property]: value });
    setUnsavedChanges(true);
  };

  const actions: TActions = {
    onEdit,
  };

  return { actions, userSettings, unsavedChanges, teamsUserInfo, APP };
};

export default useServiceMySettings;
