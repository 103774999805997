import * as React from "react";
import * as FDN from "src/core";

interface IBvPercentBarProps {
  percent: number;
}

const BvPercentBar: React.FunctionComponent<IBvPercentBarProps> = ({ percent }) => {
  let color = "red";
  if (percent >= 100) color = "green";
  else if (percent > 50) color = "orange";

  return <div className={`__percentbar status-${color}`}>{FDN.formatNumber(percent, 0, 2)} %</div>;
};

export default BvPercentBar;
