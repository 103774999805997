export const bvDetailsMapping: (string | string[])[] = [
  "dsgvo_unterzeichnet",
  ["abriss", "abriss_erfolgt"],
  "bauantrag",
  "baubeginnanzeige",
  "begruessungsschreiben2",
  "begruessungsanruf",
  "bauschild",
  "baustellentoilette",
  "bauzaun",
  "baustellenueberfahrt",
  "baustrom",
  "bauwasser",
  "kampfmittelfreigabe",
  ["kranbaustelle", "kranbaustelle_fortschritt"],
  "bodengutachten",
  "ingenieursbericht",
  ["sigeko_erforderlich", "sigeko_beauftragt"],
  "begruessungsschreiben",
  "bauversicherung",
  "architektenvollmacht",
  "haustechnikraum_freigegeben",
  "kuechenplanung",
  "baederplanung",
  [
    "tuerenblankett",
    "tueren_genehmigt",
    "tueren_aufgemessen",
    "tueren_geprueft",
    "tueren_beauftragt",
  ],
  "entfeuchtungskonzept",
  ["rohbaubegehung_notwendig", "rohbaubegehung"],
  ["verblendsteine", "verblendsteine_reserviert"],
  "wasserabweiser",
];
