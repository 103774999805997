import * as React from "react";
import * as FDN from "src/core";
import { IBvVermessung } from "src/types/types";
import BvProgressListBox from "../../parts/BvProgressList";
import UrlService from "src/core/UrlService";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IStatusVermessungProps {
  vermessung?: IBvVermessung;
}

const StatusVermessung: React.FunctionComponent<IStatusVermessungProps> = ({ vermessung }) => {
  if (!vermessung) return <FDN.Loading box />;

  const APP = React.useContext(AppContext);
  const userView = APP.userView;

  return (
    <div className="__bv-status-vermessung">
      <FDN.Box>
        <BvProgressListBox
          type="vermessung"
          isEditable={false}
          vermessung={vermessung}
          link={userView === "cc" ? "tab=bauvorbereitung" : undefined}
        />
      </FDN.Box>
    </div>
  );
};

export default StatusVermessung;
