import React, { useState } from "react";
import { IBauvorhaben, TFunction } from "../../types/types";
import useServiceCore from "../CoreService";
import StatusCode from "../../config/statuscodes";
import HomeApi from "../../api/HomeApi";
import { useParams } from "react-router-dom";
import { TActionsBauvorhaben } from "../cc/CCBauvorhabenService";

const useServiceHomeBauvorhaben = () => {
  const { api } = useServiceCore();
  const [bauvorhaben, setBauvorhaben] = useState<IBauvorhaben>();

  const params = useParams();
  const bvIdentifier = params.identifier as string;

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    HomeApi.getBauvorhaben(api, bvIdentifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        if (response.body?.bauvorhaben) setBauvorhaben(response.body.bauvorhaben as IBauvorhaben);
      }
    });
  };

  const onEdit: TFunction = () => {
    //
  };

  const setEditMode: TFunction = () => {
    //
  };

  const onUpdateBauvorhaben: TFunction = () => {
    //
  };

  const revokeAccess: TFunction = () => {
    //
  };

  const toggleTabsForClients: TFunction = () => {
    //
  };

  const onCancel: TFunction = () => {
    //
  };

  const actions: TActionsBauvorhaben = {
    onEdit,
    setEditMode,
    onUpdateBauvorhaben,
    revokeAccess,
    toggleTabsForClients,
    onCancel,
  };

  return { bauvorhaben, actions };
};

export default useServiceHomeBauvorhaben;
