import * as React from "react";
import { Link } from "react-router-dom";
import * as FDN from "src/core";
import { TActionsBauvorhabenBauvorbereitung } from "src/services/cc/CCBvBauvorbereitungService";
import { IBvDetail } from "src/types/types";

interface IBvWaermepumpeProps {
  details?: IBvDetail;
  link?: string;
  editMode: boolean;
  actions?: TActionsBauvorhabenBauvorbereitung;
}

const BvWaermepumpe: React.FunctionComponent<IBvWaermepumpeProps> = ({
  details,
  link,
  editMode,
  actions,
}) => {
  if (!details) return null;

  return (
    <div className="__bv-status-waermepumpe">
      <FDN.Box>
        <h3>
          {link ? (
            <div className="cc-bv-status-link-to-tab float-right">
              <Link to={link}>
                <FDN.Icon icon="share-square-o" />
              </Link>
            </div>
          ) : null}
          {FDN.I18n.t(`ccBvStatus.waermepumpe.box.title`)}
        </h3>
        <FDN.Grid full>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <FDN.Input
                value={details.waermepumpe_typ || "Erdwärme"}
                type="select"
                selectValues={["Erdwärme", "Luft/Wasser-Wärmepumpe"]}
                label={FDN.I18n.t("ccBvBauvorbereitung.waermepumpe.waermepumpe_typ.label")}
                editMode={editMode}
                onUpdate={(value) => {
                  if (actions?.onEditDetail) actions.onEditDetail("waermepumpe_typ", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <FDN.Input
                value={details.waermepumpe_waermebedarf || ""}
                type="text"
                label={FDN.I18n.t("ccBvBauvorbereitung.waermepumpe.waermepumpe_waermebedarf.label")}
                editMode={editMode}
                onUpdate={(value) => {
                  if (actions?.onEditDetail)
                    actions.onEditDetail("waermepumpe_waermebedarf", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <FDN.Input
                value={details.waermepumpe_bestellt || undefined}
                showValue={
                  details.waermepumpe_bestellt
                    ? FDN.formatDate(details.waermepumpe_bestellt, { useHumanReadableDate: false })
                    : undefined
                }
                type="date"
                label={FDN.I18n.t("ccBvBauvorbereitung.waermepumpe.waermepumpe_bestellt.label")}
                editMode={editMode}
                onUpdate={(value) => {
                  if (actions?.onEditDetail) actions.onEditDetail("waermepumpe_bestellt", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <FDN.Input
                value={details.waermepumpe_liefertermin || undefined}
                showValue={
                  details.waermepumpe_liefertermin
                    ? FDN.formatDate(details.waermepumpe_liefertermin, {
                        useHumanReadableDate: false,
                      })
                    : undefined
                }
                type="date"
                label={FDN.I18n.t("ccBvBauvorbereitung.waermepumpe.waermepumpe_liefertermin.label")}
                editMode={editMode}
                onUpdate={(value) => {
                  if (actions?.onEditDetail)
                    actions.onEditDetail("waermepumpe_liefertermin", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <FDN.Input
                value={details.waermepumpe_eingebaut || undefined}
                showValue={
                  details.waermepumpe_eingebaut
                    ? FDN.formatDate(details.waermepumpe_eingebaut, { useHumanReadableDate: false })
                    : undefined
                }
                type="date"
                label={FDN.I18n.t("ccBvBauvorbereitung.waermepumpe.waermepumpe_eingebaut.label")}
                editMode={editMode}
                onUpdate={(value) => {
                  if (actions?.onEditDetail) actions.onEditDetail("waermepumpe_eingebaut", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
    </div>
  );
};

export default BvWaermepumpe;
