import * as React from "react";
import * as FDN from "src/core";
import { useServiceHome } from "src/services/home/HomeService";

const HomeOverview: React.FunctionComponent = () => {
  const { init } = useServiceHome();

  React.useEffect(() => {
    init();
  }, []);

  return <FDN.Loading />;

  //   return (
  //     <div className="__home-overview">
  //       <LayoutWithTopbar page="home-overview"><main>overview<</main>/LayoutWithTopbar>
  //     </div>
  //   );
};

export default HomeOverview;
