import * as React from "react";
import * as FDN from "src/core";
import {
  IBauvorhaben,
  IMstChannels,
  IMstFolder,
  IMstLists,
  IMstSites,
  IMstTasksPlans,
  IMstTeams,
  TFunction,
} from "src/types/types";
import { cloneDeep } from "lodash";
import MstApi from "src/api/MstApi";
import useServiceCore from "src/services/CoreService";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";

const initialSelectValuesTeams: TSelectValuesTeams = {
  none: FDN.I18n.t("ccBvEinstellungen.teamschannel.noAssignment"),
};

const initialSelectValuesChannels: TSelectValuesChannels = {
  none: FDN.I18n.t("ccBvEinstellungen.teamschannel.noAssignment"),
};

const initialSelectValuesTasksPlans: TSelectValuesTasksPlans = {
  none: FDN.I18n.t("ccBvEinstellungen.teamschannel.noAssignment"),
};

const initialSelectValuesLists: TSelectValuesLists = {
  none: FDN.I18n.t("ccBvEinstellungen.teamschannel.noAssignment"),
};

const initialSelectValuesBauakteFolders: TSelectValuesBauakteFolders = {
  none: FDN.I18n.t("ccBvEinstellungen.teamschannel.noAssignment"),
};

type TSelectValuesTeams = { [key: string]: string };
type TSelectValuesChannels = { [key: string]: string };
type TSelectValuesTasksPlans = { [key: string]: string };
type TSelectValuesLists = { [key: string]: string };
type TSelectValuesBauakteFolders = { [key: string]: string };

interface IBvTeamsChannelProps {
  bauvorhaben: IBauvorhaben;
  mstTeams: IMstTeams;
  mstTaskPlans: IMstTasksPlans;
  mstSites: IMstSites;
  mstFoldersLoaded: boolean;
  mstChannelFolders?: IMstFolder;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BvTeamsChannel: React.FunctionComponent<IBvTeamsChannelProps> = ({
  bauvorhaben,
  mstTeams,
  mstTaskPlans,
  mstSites,
  mstFoldersLoaded,
  editMode,
  actions,
}) => {
  const [selectValuesTeams, setSelectValuesTeams] = React.useState<TSelectValuesTeams>(
    cloneDeep(initialSelectValuesTeams)
  );
  const [selectValuesChannels, setSelectValuesChannels] = React.useState<TSelectValuesChannels>();

  const [selectValuesTasksPlans, setSelectValuesTasksPlans] =
    React.useState<TSelectValuesTasksPlans>();

  const [selectValuesLists, setSelectValuesLists] = React.useState<TSelectValuesLists>();

  const [selectValuesBauakteFolders, setSelectValuesBauakteFolders] =
    React.useState<TSelectValuesChannels>();

  const [reloadingTeamsAndChannels, setReloadingTeamsAndChannels] = React.useState(false);

  const { api, NOTIFICATIONS } = useServiceCore();

  React.useEffect(() => {
    const selectValuesTeams = resetSelectValuesTeams();
    if (!mstTeams) return;

    for (const mstTeam of mstTeams) {
      selectValuesTeams[mstTeam.identifier] = mstTeam.name;
    }

    setSelectValuesTeams(cloneDeep(selectValuesTeams));
  }, [mstTeams]);

  React.useEffect(() => {
    const selectValuesChannels = resetSelectValuesChannels();
    if (!mstTeams || bauvorhaben.teamsTeamIdentifier === "none") return;

    const selectedTeam = mstTeams.find((t) => t.identifier === bauvorhaben.teamsTeamIdentifier);

    if (selectedTeam && selectedTeam.channels) {
      for (const mstChannel of selectedTeam.channels) {
        selectValuesChannels[mstChannel.identifier] = mstChannel.name;
      }
    }

    setSelectValuesChannels(cloneDeep(selectValuesChannels));
  }, [bauvorhaben.teamsTeamIdentifier]);

  React.useEffect(() => {
    const selectValuesTasksPlans = resetSelectValuesTasksPlans();
    const selectValuesLists = resetSelectValuesLists();
    if (
      !mstTeams ||
      bauvorhaben.teamsTeamIdentifier === "none" ||
      bauvorhaben.teamsChannelIdentifier === "none"
    )
      return;

    const selectedTeam = mstTeams.find((t) => t.identifier === bauvorhaben.teamsTeamIdentifier);

    if (selectedTeam && selectedTeam.channels) {
      const selectedChannel = (selectedTeam.channels as IMstChannels).find(
        (c) => c.identifier === bauvorhaben.teamsChannelIdentifier
      );

      if (
        selectedChannel &&
        selectedChannel._teamsGroupId &&
        selectedChannel._teamsGroupId in mstTaskPlans
      ) {
        for (const mstTaskPlan of mstTaskPlans[selectedChannel._teamsGroupId] as IMstTasksPlans) {
          selectValuesTasksPlans[mstTaskPlan.identifier] = mstTaskPlan.title;
        }
      }

      for (const mstSite of mstSites) {
        if (mstSite.lists) {
          for (const mstList of mstSite.lists) {
            selectValuesLists[mstList.identifier] = `${mstSite.name} - ${mstList.name}`;
          }
        }
      }
    }

    setSelectValuesTasksPlans(cloneDeep(selectValuesTasksPlans));
    setSelectValuesLists(cloneDeep(selectValuesLists));

    if (bauvorhaben.teamsChannelIdentifier && bauvorhaben.teamsChannelIdentifier !== "none")
      actions.loadMstFolders &&
        actions.loadMstFolders(
          bauvorhaben.teamsChannelIdentifier,
          (folder: IMstFolder | null | undefined) => {
            const selectValuesBauakteFolders = resetSelectValuesBauakteFolders();
            if (folder?.items) {
              for (const item of folder.items) {
                if (item.type === "FOLDER") selectValuesBauakteFolders[item._teamsId] = item.name;
              }
            }
          }
        );
  }, [bauvorhaben.teamsChannelIdentifier]);

  const resetSelectValuesTeams = () => {
    const selectValuesTeams = cloneDeep(initialSelectValuesTeams);
    setSelectValuesTeams(selectValuesTeams);
    return selectValuesTeams;
  };

  const resetSelectValuesChannels = () => {
    const selectValuesChannels = cloneDeep(initialSelectValuesChannels);
    setSelectValuesChannels(selectValuesChannels);
    return selectValuesChannels;
  };

  const resetSelectValuesTasksPlans = () => {
    const selectValuesTasksPlans = cloneDeep(initialSelectValuesTasksPlans);
    setSelectValuesTasksPlans(selectValuesTasksPlans);
    return selectValuesTasksPlans;
  };

  const resetSelectValuesLists = () => {
    const selectValuesLists = cloneDeep(initialSelectValuesLists);
    setSelectValuesLists(selectValuesLists);
    return selectValuesLists;
  };

  const resetSelectValuesBauakteFolders = () => {
    const selectValuesBauakteFolders = cloneDeep(initialSelectValuesBauakteFolders);
    setSelectValuesBauakteFolders(selectValuesBauakteFolders);
    return selectValuesBauakteFolders;
  };

  const reloadTeams = async () => {
    if (window.confirm(FDN.I18n.t("ccBvEinstellungen.teamschannel.reloadTeams.confirm"))) {
      setReloadingTeamsAndChannels(true);
      await MstApi.reloadTeamsAndChannels(api).then(async () => {
        if (actions.loadTeamsAndChannels) await actions.loadTeamsAndChannels();
        setReloadingTeamsAndChannels(false);
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("ccBvEinstellungen.teamschannel.reloadTeams.success.title"),
          FDN.I18n.t("ccBvEinstellungen.teamschannel.reloadTeams.success.text")
        );
      });
    }
  };

  return (
    <>
      {reloadingTeamsAndChannels === true ? (
        <FDN.Loading
          text={FDN.I18n.t("ccBvEinstellungen.teamschannel.reloadTeams.reloading")}
          box
        />
      ) : (
        <>
          {editMode === true && <SyncInfoLine reloadTeams={reloadTeams} />}
          <FDN.Grid full>
            <FDN.Row margin="xy">
              <FDN.Cell sm={24} md={12}>
                <FDN.FormGroup title={FDN.I18n.t("ccBvEinstellungen.teamschannel.title")}>
                  <FDN.SingleRowCell margin="xy">
                    <FDN.Input
                      editable={true}
                      editMode={editMode}
                      type="select"
                      selectValues={selectValuesTeams}
                      value={bauvorhaben.teamsTeamIdentifier || "none"}
                      showValue={selectValuesTeams[bauvorhaben.teamsTeamIdentifier || "none"]}
                      label={FDN.I18n.t("ccBvEinstellungen.teamschannel.form.team.label")}
                      onUpdate={(value) => actions.onEdit("teamsTeamIdentifier", value)}
                    />
                  </FDN.SingleRowCell>
                  {selectValuesChannels && (
                    <FDN.SingleRowCell margin="xy">
                      <FDN.Input
                        editable={true}
                        editMode={editMode}
                        type="select"
                        selectValues={selectValuesChannels}
                        value={bauvorhaben.teamsChannelIdentifier || "none"}
                        showValue={
                          selectValuesChannels[bauvorhaben.teamsChannelIdentifier || "none"]
                        }
                        label={FDN.I18n.t("ccBvEinstellungen.teamschannel.form.channel.label")}
                        onUpdate={(value) => actions.onEdit("teamsChannelIdentifier", value)}
                      />
                    </FDN.SingleRowCell>
                  )}
                </FDN.FormGroup>
                {selectValuesTasksPlans &&
                bauvorhaben.teamsChannelIdentifier &&
                bauvorhaben.teamsChannelIdentifier !== "none" ? (
                  <FDN.FormGroup title={FDN.I18n.t("ccBvEinstellungen.teamstasks.title")}>
                    <FDN.SingleRowCell margin="xy">
                      <FDN.Input
                        editable={true}
                        editMode={editMode}
                        type="select"
                        selectValues={selectValuesTasksPlans}
                        value={bauvorhaben.teamsTaskPlanIdentifier || "none"}
                        showValue={
                          selectValuesTasksPlans[bauvorhaben.teamsTaskPlanIdentifier || "none"]
                        }
                        label={FDN.I18n.t("ccBvEinstellungen.teamschannel.form.taskplan.label")}
                        onUpdate={(value) => actions.onEdit("teamsTaskPlanIdentifier", value)}
                      />
                    </FDN.SingleRowCell>
                  </FDN.FormGroup>
                ) : null}
                {selectValuesLists &&
                bauvorhaben.teamsChannelIdentifier &&
                bauvorhaben.teamsChannelIdentifier !== "none" ? (
                  <FDN.FormGroup title={FDN.I18n.t("ccBvEinstellungen.teamslists.title")}>
                    <FDN.SingleRowCell margin="xy">
                      <FDN.Input
                        editable={true}
                        editMode={editMode}
                        type="select"
                        selectValues={selectValuesLists}
                        value={bauvorhaben.teamsListIdentifier || "none"}
                        showValue={selectValuesLists[bauvorhaben.teamsListIdentifier || "none"]}
                        label={FDN.I18n.t("ccBvEinstellungen.teamslists.form.list.label")}
                        onUpdate={(value) => actions.onEdit("teamsListIdentifier", value)}
                      />
                    </FDN.SingleRowCell>
                  </FDN.FormGroup>
                ) : null}
                {selectValuesChannels &&
                selectValuesBauakteFolders &&
                bauvorhaben.teamsChannelIdentifier &&
                bauvorhaben.teamsChannelIdentifier !== "none" ? (
                  <FDN.FormGroup title={FDN.I18n.t("ccBvEinstellungen.teamsbauakte.title")}>
                    {!mstFoldersLoaded ? (
                      <FDN.Loading box />
                    ) : (
                      <FDN.SingleRowCell margin="xy">
                        <FDN.Input
                          editable={true}
                          editMode={editMode}
                          type="select"
                          selectValues={selectValuesBauakteFolders}
                          value={bauvorhaben.teamsBauakteFolderIdentifier || "none"}
                          showValue={
                            selectValuesBauakteFolders[
                              bauvorhaben.teamsBauakteFolderIdentifier || "none"
                            ]
                          }
                          label={FDN.I18n.t("ccBvEinstellungen.teamsbauakte.form.folder.label")}
                          onUpdate={(value) =>
                            actions.onEdit("teamsBauakteFolderIdentifier", value)
                          }
                        />
                      </FDN.SingleRowCell>
                    )}
                  </FDN.FormGroup>
                ) : null}
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </>
      )}
    </>
  );
};

interface ISyncInfoLineProps {
  reloadTeams: TFunction;
}

const SyncInfoLine: React.FunctionComponent<ISyncInfoLineProps> = ({ reloadTeams }) => {
  return (
    <div className="__cc-bauvorhaben-aufgaben-syncinfoline">
      <button onClick={() => reloadTeams()}>
        <FDN.Icon icon="refresh" left /> {FDN.I18n.t("ccBvEinstellungen.teamschannel.reloadTeams")}
      </button>
    </div>
  );
};

export default BvTeamsChannel;
