import * as React from "react";
import CCApi from "src/api/CCApi";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import { formatDateTime } from "src/core/helpers/helpers";
import useServiceCore from "src/services/CoreService";
import { MstSyncTypes } from "src/types/types";

interface IMstSyncInfoProps {
  lastSync?: Date;
  bvIdentifier: string;
  type: MstSyncTypes;
  disableResync?: boolean;
}

const MstSyncInfo = ({ lastSync, bvIdentifier, type, disableResync }: IMstSyncInfoProps) => {
  const { NOTIFICATIONS, api } = useServiceCore();

  const onForceSync = () => {
    const doForceSync = () =>
      CCApi.ccBvForceResync(api, bvIdentifier, type).then((response) => {
        if (response?.statusCode === StatusCode.ERROR)
          NOTIFICATIONS.showNotification(
            "danger",
            FDN.I18n.t("ccMain.errors.forceresync.title"),
            FDN.I18n.t("ccMain.errors.forceresync.text")
          );
        else if (response?.statusCode === StatusCode.SUCCESS) {
          window.location.reload();
        }
      });

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("ccMain.forceresync.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            doForceSync();
          },
        },
      ],
    });
  };

  return (
    <div className="__mst-syncinfo">
      <FDN.Icon icon="clock-o" left />
      {FDN.I18n.t("filebrowser.lastSync.text", {
        date: lastSync
          ? formatDateTime(lastSync, { seconds: false, useHumanReadableDate: true })
          : "?",
      })}{" "}
      &nbsp;{" "}
      {disableResync !== true && (
        <button onClick={onForceSync}>
          <FDN.Icon icon="refresh" left /> {FDN.I18n.t("filebrowser.lastSync.button")}
        </button>
      )}
    </div>
  );
};

export default MstSyncInfo;
