import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben } from "src/types/types";

import { useNavigate } from "react-router-dom";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import useServiceCCBvBauvorbereitung, {
  TActionsBauvorhabenBauvorbereitung,
} from "src/services/cc/CCBvBauvorbereitungService";
import BvVorBaubeginn from "./bauvorbereitung/BvVorBaubeginn";

const tabs = {
  vorbaubeginn: {
    label: FDN.I18n.t("ccBvBauvorbereitung.tabs.vorbaubeginn.label"),
    icon: "signal",
  },
};

const defaultTab = "vorbaubeginn";

interface IBauvorhabenBauvorbereitungProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BauvorhabenBauvorbereitung: React.FunctionComponent<IBauvorhabenBauvorbereitungProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const navigate = useNavigate();

  const {
    details,
    vermessung,
    fenster,
    rohbauCheck,
    fertigungsbuergschaften,
    actions: bauvorbereitungActions,
  } = useServiceCCBvBauvorbereitung(actions);

  const moduleActions: TActionsBauvorhabenBauvorbereitung = {
    ...actions,
    ...bauvorbereitungActions,
  };

  React.useEffect(() => {
    if (!details && !vermessung) bauvorbereitungActions.init(bauvorhaben);
  }, [bauvorhaben]);

  if (!details || !vermessung || !fenster || !rohbauCheck || !fertigungsbuergschaften)
    return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-empty">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">
        {FDN.I18n.t(`ccBvBauvorbereitung.tabs.${selectedTab}.title`)}
      </h2>
      <FDN.TabsContent>
        {selectedTab === "vorbaubeginn" && (
          <BvVorBaubeginn
            bauvorhaben={bauvorhaben}
            details={details}
            vermessung={vermessung}
            fenster={fenster}
            rohbauCheck={rohbauCheck}
            fertigungsbuergschaften={fertigungsbuergschaften}
            editMode={editMode}
            actions={moduleActions}
          />
        )}
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenBauvorbereitung;
