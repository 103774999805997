import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben } from "src/types/types";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";

interface IBvAdresseProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BvAdresse: React.FunctionComponent<IBvAdresseProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  return (
    <div className="__cc-bauvorhaben-informationen-adresse">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={14}>
            <FormGroupAdresse bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

//interface IFormGroupProps extends IBvAdresseProps {}

const FormGroupAdresse: React.FunctionComponent<IBvAdresseProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("ccBvInformationen.adresse.group.adresse.title")}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              editable={false}
              editMode={editMode}
              type="text"
              value={bauvorhaben.street}
              label={FDN.I18n.t("ccBauvorhaben.form.street.label")}
              onUpdate={(value) => actions.onEdit("street", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              editable={false}
              editMode={editMode}
              type="text"
              value={bauvorhaben.streetAdditional}
              label={FDN.I18n.t("ccBauvorhaben.form.streetAdditional.label")}
              onUpdate={(value) => actions.onEdit("streetAdditional", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={8}>
            <FDN.Input
              editable={false}
              editMode={editMode}
              type="text"
              value={bauvorhaben.zip}
              label={FDN.I18n.t("ccBauvorhaben.form.zip.label")}
              onUpdate={(value) => actions.onEdit("zip", value)}
            />
          </FDN.Cell>
          <FDN.Cell sm={16}>
            <FDN.Input
              editable={false}
              editMode={editMode}
              type="text"
              value={bauvorhaben.city}
              label={FDN.I18n.t("ccBauvorhaben.form.city.label")}
              onUpdate={(value) => actions.onEdit("city", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              editable={false}
              editMode={editMode}
              type="country"
              value={bauvorhaben.country}
              label={FDN.I18n.t("ccBauvorhaben.form.country.label")}
              onUpdate={(value) => actions.onEdit("country", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.FormGroup>
  );
};

export default BvAdresse;
