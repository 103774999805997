import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben, IMstFolder, IMstSites, IMstTasksPlans, IMstTeams } from "src/types/types";
import CCApi from "src/api/CCApi";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import BvTeamsChannel from "./einstellungen/BvTeamsChannel";
import { useNavigate } from "react-router-dom";
import MstApi from "src/api/MstApi";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import MstSyncInfo from "src/components/thirdparty/microsoft/MstSyncInfo";

const tabs = {
  teams: {
    label: FDN.I18n.t("ccBvEinstellungen.tabs.teams.label"),
    icon: "windows",
  },
};

const defaultTab = "teams";

interface IBauvorhabenEinstellungenProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BauvorhabenEinstellungen: React.FunctionComponent<IBauvorhabenEinstellungenProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);
  const [mstTeams, setMstTeams] = React.useState<IMstTeams>();
  const [mstTaskPlans, setMstTaskPlans] = React.useState<IMstTasksPlans>();
  const [mstSites, setMstSites] = React.useState<IMstSites>();
  const [mstChannelFolders, setMstChannelFolders] = React.useState<IMstFolder>();
  const [mstFoldersLoaded, setMstFoldersLoaded] = React.useState(false);
  const [lastSync, setLastSync] = React.useState<Date>();

  const { api } = useServiceCore();

  const navigate = useNavigate();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "einstellungen").then((response) => {
      console.log("I am done.");

      if (response?.statusCode === StatusCode.SUCCESS) {
        if (response?.body?.mstTeams) setMstTeams(response.body.mstTeams as IMstTeams);
        if (response?.body?.mstTaskPlans)
          setMstTaskPlans(response.body.mstTaskPlans as IMstTasksPlans);
        if (response?.body?.mstSites) setMstSites(response.body.mstSites as IMstSites);
        if (response?.body?.mstChannelFolders)
          setMstChannelFolders(response.body.mstChannelFolders as IMstFolder);
        if (response?.body?.lastSync) setLastSync(response.body.lastSync as Date);
      }
    });
  };

  const loadMstFolders = (
    teamsChannelIdentifier: string,
    callback: (folder: IMstFolder | null | undefined) => void
  ) => {
    setMstFoldersLoaded(false);
    MstApi.filesOpenFolderByChannel(api, teamsChannelIdentifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        if (response?.body?.folder) {
          setMstChannelFolders(response.body.folder as IMstFolder);
          if (callback) callback(response.body.folder as IMstFolder);
        }
        setMstFoldersLoaded(true);
      }
    });
  };

  actions.loadMstFolders = loadMstFolders;
  actions.loadTeamsAndChannels = init;

  if (!mstTeams || !mstTaskPlans || !mstSites) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-einstellungen">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">
        {FDN.I18n.t(`ccBvEinstellungen.tabs.${selectedTab}.title`)}
      </h2>
      <FDN.TabsContent>
        <MstSyncInfo lastSync={lastSync} bvIdentifier="system" type="channels" />
        {selectedTab === "teams" && (
          <BvTeamsChannel
            bauvorhaben={bauvorhaben}
            editMode={editMode}
            mstTeams={mstTeams}
            mstTaskPlans={mstTaskPlans}
            mstSites={mstSites}
            mstFoldersLoaded={mstFoldersLoaded}
            mstChannelFolders={mstChannelFolders}
            actions={actions}
          />
        )}
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenEinstellungen;
