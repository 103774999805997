import * as React from "react";
import { IFileBrowserFile, TActions } from "../../../../../../types/types";
import FileIcon from "./FileIcon";

interface IFileInfoPreviewProps {
  file: IFileBrowserFile;
  actions: TActions;
}

const FileInfoPreview: React.FunctionComponent<IFileInfoPreviewProps> = ({ file, actions }) => {
  return (
    <div className="__filebrowser-fileinfo-preview">
      {file.fileType === "image" && <PreviewImage file={file} actions={actions} />}
      {!["image"].includes(file.fileType) && <PreviewIcon file={file} actions={actions} />}
    </div>
  );
};

const PreviewImage: React.FunctionComponent<IFileInfoPreviewProps> = ({ file, actions }) => {
  return (
    <div className="__filebrowser-fileinfo-preview-image">
      <img src={file.downloadUrl} alt={file.name} />
    </div>
  );
};

const PreviewIcon: React.FunctionComponent<IFileInfoPreviewProps> = ({ file, actions }) => {
  return (
    <div className="__filebrowser-fileinfo-preview-icon">
      <FileIcon item={file} />
    </div>
  );
};

export default FileInfoPreview;
