import * as React from "react";
import * as FDN from "src/core";
import { IFileBrowserFile, TActions } from "src/types/types";
import FileInfoInfo from "./parts/FileInfoInfo";

interface IFolderFileInfoProps {
  file?: IFileBrowserFile;
  lastSync?: Date;
  actions: TActions;
}

const FolderFileInfo: React.FunctionComponent<IFolderFileInfoProps> = ({
  file,
  lastSync,
  actions,
}) => {
  return (
    <div className="__filebrowser-fileinfo">
      <FDN.Box>
        {!file ? (
          <div className="text-center">{FDN.I18n.t("filebrowser.fileInfo.noFileSelected")}</div>
        ) : (
          <div>
            <div className="__filebrowser-fileinfo-close-button">
              <button onClick={() => actions.selectFile()}>
                <FDN.Icon icon="gg-close" />
              </button>
            </div>
            <FileInfoInfo file={file} lastSync={lastSync} actions={actions} />
          </div>
        )}
      </FDN.Box>
    </div>
  );
};

export default FolderFileInfo;
