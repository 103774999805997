import React, { useState } from "react";
import { TActions } from "../../types/types";
import useServiceCore from "../CoreService";
import StatusCode from "../../config/statuscodes";
import CCApi from "../../api/CCApi";

const useServiceCCTasks = () => {
  const { APP, NOTIFICATIONS, api } = useServiceCore();
  const [pageLoaded, setPageLoaded] = useState(false);

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    CCApi.ccDashboard(api).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) setPageLoaded(true);
    });
  };

  const actions: TActions = {};

  return { pageLoaded, actions };
};

export default useServiceCCTasks;
