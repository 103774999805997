import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben } from "src/types/types";

interface IBauvorhabenLabelsProps {
  bauvorhaben: IBauvorhaben;
  hide?: string[];
}

const BauvorhabenLabels: React.FunctionComponent<IBauvorhabenLabelsProps> = ({
  bauvorhaben,
  hide,
}) => {
  if (!hide) hide = [];

  return (
    <span className="__cc-bauvorhaben-labels">
      {bauvorhaben.status && !hide?.includes("status") ? (
        <LabelStatus bauvorhaben={bauvorhaben} />
      ) : null}
      {bauvorhaben.kategorie && !hide?.includes("kategorie") ? (
        <LabelHauskategorie bauvorhaben={bauvorhaben} />
      ) : null}
      {bauvorhaben.energiestandard && !hide?.includes("energiestandard") ? (
        <LabelEnergiestandard bauvorhaben={bauvorhaben} />
      ) : null}
    </span>
  );
};

const LabelStatus: React.FunctionComponent<IBauvorhabenLabelsProps> = ({ bauvorhaben }) => {
  let icon = "clock-o";

  switch (bauvorhaben.status) {
    case "PLANUNG":
      icon = "signal";
      break;
    case "LAUFEND":
      icon = "clock-o";
      break;
    case "NACHUEBERGABE":
      icon = "check";
      break;
    case "ARCHIV":
      icon = "archive";
      break;
  }
  return (
    <span
      className={`__cc-bauvorhaben-labels-label label-type-status label-type-status-${bauvorhaben.status}`}
    >
      <FDN.Icon icon={icon} /> {FDN.I18n.t(`ccBauvorhaben.status.${bauvorhaben.status}.label`)}
    </span>
  );
};

const LabelHauskategorie: React.FunctionComponent<IBauvorhabenLabelsProps> = ({ bauvorhaben }) => {
  const icon = "home";

  return (
    <span
      className={`__cc-bauvorhaben-labels-label label-type-kategorie label-type-kategorie-${bauvorhaben.kategorie}`}
    >
      <FDN.Icon icon={icon} />{" "}
      {FDN.I18n.t(`ccBauvorhaben.kategorie.${bauvorhaben.kategorie}.short`)}
    </span>
  );
};

const LabelEnergiestandard: React.FunctionComponent<IBauvorhabenLabelsProps> = ({
  bauvorhaben,
}) => {
  const icon = "leaf";

  return (
    <span
      className={`__cc-bauvorhaben-labels-label label-type-energiestandard label-type-energiestandard-${bauvorhaben.energiestandard}`}
    >
      <FDN.Icon icon={icon} />{" "}
      {FDN.I18n.t(`ccBauvorhaben.energiestandard.${bauvorhaben.energiestandard}.short`)}
    </span>
  );
};

export default BauvorhabenLabels;
