import { useEffect, useState } from "react";
import { IBauvorhaben, IMstList, TFunction } from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import CCApi from "src/api/CCApi";
import { cloneDeep } from "lodash";
import { TActionsBauvorhaben } from "./CCBauvorhabenService";

export interface TActionsBauvorhabenLists extends TActionsBauvorhaben {
  init: TFunction;
}

const initTabs = {
  list: {
    label: "-",
    icon: "list",
  },
};

const defaultTab = "list";

const useServiceCCBvLists = (bvActions: TActionsBauvorhaben) => {
  const { api } = useServiceCore();

  const [bauvorhaben, setBauvorhaben] = useState<IBauvorhaben>();
  const [mstList, setMstList] = useState<IMstList>();
  const [lastSync, setLastSync] = useState<Date>();

  const [tabs, setTabs] = useState(initTabs);
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  useEffect(() => {
    const tabs = cloneDeep(initTabs);
    tabs.list.label = mstList?.name || "-";
    setTabs(cloneDeep(tabs));
  }, [mstList?.name]);

  const init = (bauvorhaben: IBauvorhaben) => {
    setBauvorhaben(cloneDeep(bauvorhaben));
    CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "lists").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setMstList(response?.body?.list as IMstList);
        if (response?.body?.lastSync) setLastSync(response.body.lastSync as Date);
      }
    });
  };

  const actions: TActionsBauvorhabenLists = {
    ...bvActions,
    init,
  };

  return { tabs, selectedTab, setSelectedTab, mstList, lastSync, actions };
};

export default useServiceCCBvLists;
