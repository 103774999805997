import * as React from "react";
import * as FDN from "src/core";
import { IUserNotification } from "src/components/context/NotificationsContext/types";
import { TActions } from "src/types/types";
import { formatDateTime } from "src/core/helpers/helpers";

interface IUserNotificationsListItemProps {
  userNotification: IUserNotification;
  actions: TActions;
}

const UserNotificationsListItem: React.FunctionComponent<IUserNotificationsListItemProps> = ({
  userNotification,
  actions,
}) => {
  if (!userNotification.userInfo) return null;

  return (
    <div
      className={`${userNotification.read ? `read` : `unread`} __usernotifications-list-item`}
      onClick={() => actions.onClickItem(userNotification)}
    >
      <div className="__usernotifications-list-item-content-wrapper">
        <div className="__usernotifications-list-item-avatar">
          <FDN.Avatar user={userNotification.userInfo} size={40} />
        </div>
        <div className="__usernotifications-list-item-content">
          <div dangerouslySetInnerHTML={{ __html: actions.buildMessage(userNotification) }} />
          <div className="__usernotifications-list-item-markas">
            {formatDateTime(userNotification.notificationDate)} &middot;{" "}
            <button
              onClick={(e) => {
                e.stopPropagation();
                actions.toggleRead(userNotification);
              }}
            >
              {FDN.I18n.t(
                `usernotifications.popup.markAs${userNotification.read ? `Unread` : `Read`}`
              )}
            </button>
          </div>
        </div>
        {!userNotification.read && (
          <div className="__usernotifications-list-item-new-indicator">
            <span />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserNotificationsListItem;
