import * as React from "react";
import * as FDN from "src/core";
import { TActionsBauvorhabenTermine } from "src/services/cc/CCBvTermineService";
import { IBauvorhaben, IBvTermine } from "src/types/types";

const terminGroupsL = {
  verbraucherbauvertrag: ["verbraucherbauvertrag"],
  projektbesprechung: ["projektbesprechung"],
  apl_freigabe: ["apl_freigabe"],
  baubeginn: ["baubeginn_soll", "baubeginn_ist"],
};

const terminGroupsR = {
  richtfest: ["richtfest_soll", "richtfest_ist"],
  vorbegehung: ["vorbegehung_soll", "vorbegehung_ist", "vorbegehung_plan"],
  uebergabe: ["uebergabe_soll", "uebergabe_ist", "uebergabe_plan"],
};

interface IBvTermineProps {
  bauvorhaben: IBauvorhaben;
  termine: IBvTermine;
  editMode: boolean;
  actions: TActionsBauvorhabenTermine;
}

const BvTermine: React.FunctionComponent<IBvTermineProps> = ({
  bauvorhaben,
  termine,
  editMode,
  actions,
}) => {
  if (!termine) return <FDN.Loading box />;

  return (
    <FDN.GridRow margin="xy">
      <FDN.Cell md={12}>
        <BvterminGroups
          terminGroups={terminGroupsL}
          bauvorhaben={bauvorhaben}
          termine={termine}
          editMode={editMode}
          actions={actions}
        />
      </FDN.Cell>
      <FDN.Cell md={12}>
        <BvterminGroups
          terminGroups={terminGroupsR}
          bauvorhaben={bauvorhaben}
          termine={termine}
          editMode={editMode}
          actions={actions}
        />
      </FDN.Cell>
    </FDN.GridRow>
  );
};

interface IBvTerminGroupsProps {
  terminGroups: { [key: string]: string[] };
  bauvorhaben: IBauvorhaben;
  termine: IBvTermine;
  editMode: boolean;
  actions: TActionsBauvorhabenTermine;
}

const BvterminGroups: React.FunctionComponent<IBvTerminGroupsProps> = ({
  terminGroups,
  termine,
  editMode,
  actions,
}) => {
  return (
    <>
      {Object.keys(terminGroups).map((terminGroupKey) => {
        const terminGroup = terminGroups[terminGroupKey];
        return (
          <FDN.FormGroup
            key={terminGroupKey}
            title={FDN.I18n.t(`ccBvTermine.terminGroups.${terminGroupKey}.title`)}
          >
            <FDN.Grid full>
              <FDN.Row margin="xy">
                {terminGroup.map((terminKey) => (
                  <BvTermin
                    key={terminKey}
                    termin={terminKey}
                    date={termine[terminKey]}
                    editMode={editMode}
                    actions={actions}
                  />
                ))}
              </FDN.Row>
            </FDN.Grid>
          </FDN.FormGroup>
        );
      })}
    </>
  );
};

interface IBvTerminProps {
  termin: string;
  date: Date | null | undefined;
  editMode: boolean;
  actions: TActionsBauvorhabenTermine;
}

const BvTermin: React.FunctionComponent<IBvTerminProps> = ({ termin, date, editMode, actions }) => {
  return (
    <FDN.Cell sm={24} md={24} lg={12}>
      <FDN.Input
        type="date"
        editable={true}
        editMode={editMode}
        value={date}
        showValue={date ? FDN.formatDate(date) : ""}
        label={FDN.I18n.t(`ccBvTermine.termine.${termin}.label`)}
        onUpdate={(value) => actions.onEditTermin(termin, value)}
      />
    </FDN.Cell>
  );
};

export default BvTermine;
