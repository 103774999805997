import * as React from "react";
import { IBauvorhaben } from "../../../../../types/types";
import I18n from "../../../../../core/I18n";

interface IBauvorhabenImageProps {
  bauvorhaben: IBauvorhaben;
}

const BauvorhabenImage: React.FunctionComponent<IBauvorhabenImageProps> = ({ bauvorhaben }) => {
  const style = {
    backgroundImage: "",
  };

  if (bauvorhaben.imageUrl) style.backgroundImage = `url("${bauvorhaben.imageUrl}")`;

  return (
    <div className="__cc-bauvorhaben-image" style={style}>
      <span>{bauvorhaben.imageUrl ? null : I18n.t("ccBauvorhaben.noImage")}</span>
    </div>
  );
};

export default BauvorhabenImage;
