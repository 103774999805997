import * as React from "react";
import AdminBackButton, { IAdminBackButton } from "./AdminBackButton";

interface IAdminHeaderProps {
  title?: string | React.ReactNode;
  backButton?: IAdminBackButton;
  buttons?: React.ReactNode[];
}

const AdminHeader: React.FunctionComponent<IAdminHeaderProps> = ({
  title,
  backButton,
  buttons,
}) => {
  return (
    <div className="__admin-header">
      <div className="__admin-header-content">
        <div className="__admin-header-title">
          {backButton && (
            <AdminBackButton label={backButton.label} url={backButton.url} />
          )}
          {title && <h1>{title}</h1>}
        </div>
        {buttons && (
          <div className="__admin-header-buttons">
            {buttons.map((button, index) => (
              <span key={index} className="__admin-header-buttons-item">
                {button}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminHeader;
