import * as React from "react";
import * as FDN from "src/core";
import LayoutWithTopbar from "src/components/layouts/LayoutWithTopbar";
import PageContent from "src/components/layouts/PageContent";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { NotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";
import { useServiceHome } from "src/services/home/HomeService";

const IndexPage: React.FunctionComponent = () => {
  const [pageLoaded, setPageLoaded] = React.useState(false);

  const APP = React.useContext(AppContext);
  const NOTIFICATIONS = React.useContext(NotificationsContext);

  const { init } = useServiceHome();

  React.useEffect(() => {
    init();
  }, []);

  if (!pageLoaded) return <FDN.Loading />;

  return (
    <LayoutWithTopbar page="index">
      <PageContent>Index</PageContent>
    </LayoutWithTopbar>
  );
};

export default IndexPage;
