import * as React from "react";
import * as FDN from "src/core";
import { BvLocation, IBauvorhaben } from "src/types/types";
import BvAufgaben from "./aufgaben/BvAufgaben";
import TaskPopup from "./aufgaben/TaskPopup";
import { useNavigate } from "react-router-dom";
import useServiceCCBvAufgaben, {
  TActionsBauvorhabenAufgaben,
} from "src/services/cc/CCBvAufgabenService";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";

const tabs = {
  aufgaben: {
    label: FDN.I18n.t("ccBvAufgaben.tabs.aufgaben.label"),
    icon: "tasks",
  },
  finished: {
    label: FDN.I18n.t("ccBvAufgaben.tabs.finished.label"),
    icon: "check-square-o",
  },
};

const defaultTab = "aufgaben";

interface IBauvorhabenAufgabenProps {
  bauvorhaben: IBauvorhaben;
  location: BvLocation;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BauvorhabenAufgaben: React.FunctionComponent<IBauvorhabenAufgabenProps> = ({
  bauvorhaben,
  location,
  editMode,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const {
    todo,
    updatingTasksPlan,
    selectedTask,
    taskEditMode,
    actions: aufgabenActions,
  } = useServiceCCBvAufgaben(actions);

  const moduleActions: TActionsBauvorhabenAufgaben = { ...actions, ...aufgabenActions };

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!todo) aufgabenActions.init(bauvorhaben);
  }, [bauvorhaben]);

  if (!todo) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-tab-aufgaben">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">
        {FDN.I18n.t(`ccBvAufgaben.tabs.${selectedTab}.title`)}
      </h2>
      <FDN.TabsContent>
        {selectedTab === "aufgaben" && (
          <BvAufgaben
            type="unfinished"
            bauvorhaben={bauvorhaben}
            location={location}
            todo={todo}
            updatingTasksPlan={updatingTasksPlan}
            editMode={editMode}
            actions={moduleActions}
          />
        )}
        {selectedTab === "finished" && (
          <BvAufgaben
            type="finished"
            bauvorhaben={bauvorhaben}
            location={location}
            todo={todo}
            updatingTasksPlan={updatingTasksPlan}
            editMode={editMode}
            actions={moduleActions}
          />
        )}
        {selectedTask && (
          <TaskPopup
            location={location}
            task={selectedTask}
            todo={todo}
            taskEditMode={taskEditMode}
            actions={moduleActions}
          />
        )}
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenAufgaben;
