import { useState } from "react";
import { BvStatusResult, IBauvorhaben, IBvTermine, TFunction } from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import CCApi from "src/api/CCApi";
import { cloneDeep } from "lodash";
import { TActionsBauvorhaben } from "./CCBauvorhabenService";

export interface TActionsBauvorhabenStatus extends TActionsBauvorhaben {
  init: TFunction;
}

const useServiceCCBvStatus = (bvActions: TActionsBauvorhaben) => {
  const { api, APP } = useServiceCore();

  const [bauvorhaben, setBauvorhaben] = useState<IBauvorhaben>();
  const [bvStatus, setBvStatus] = useState<IBvTermine>();

  const init = (bauvorhaben: IBauvorhaben) => {
    setBauvorhaben(cloneDeep(bauvorhaben));
    CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "status").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setBvStatus(response?.body?.status as BvStatusResult);
        APP.setPageTitle(bauvorhaben.name);
      }
    });
  };

  const actions: TActionsBauvorhabenStatus = {
    ...bvActions,
    init,
  };

  return { bvStatus, actions };
};

export default useServiceCCBvStatus;
