import * as React from "react";
import * as FDN from "src/core";
import { IHausanschluesse, IHausanschluss } from "src/types/types";
import {
  HausanschlussTermine,
  TActionsBauvorhabenHausanschluesse,
} from "src/services/cc/CCBvHausanschluesseService";
import { getHausanschlussPercent } from "src/services/helpers/bv.helpers";
import { cloneDeep } from "lodash";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { userHasPermission } from "src/core/AdminService/helpers";

interface IBvHausanschluesseProps {
  hausanschluesse: IHausanschluesse;
  visibleTerminPopup?: string;
  popupSettingsVisible: boolean;
  actions: TActionsBauvorhabenHausanschluesse;
}

const BvHausanschluesse: React.FunctionComponent<IBvHausanschluesseProps> = ({
  visibleTerminPopup,
  popupSettingsVisible,
  hausanschluesse,
  actions,
}) => {
  const APP = React.useContext(AppContext);
  const user = APP.getUser();

  const view = userHasPermission(user, "ccBauvorhabenEdit") ? "cc" : "home";

  return (
    <div className="__cc-bauvorhaben-hausanschluesse">
      {view === "cc" && (
        <div className="text-right">
          <button className="small primary button" onClick={actions.showSettingsPopup}>
            <FDN.Icon icon="cogs" left /> {FDN.I18n.t("ccBvHausanschluesse.buttons.settings.label")}
          </button>
          <div>&nbsp;</div>
        </div>
      )}
      <FDN.Grid full>
        <FDN.Row margin="xy">
          {hausanschluesse.map((hausanschluss) => {
            return (
              <FDN.Cell sm={24} md={12} key={hausanschluss.type}>
                <Hausanschluss
                  visibleTerminPopup={visibleTerminPopup}
                  hausanschluss={hausanschluss}
                  view={view}
                  actions={actions}
                />
              </FDN.Cell>
            );
          })}
        </FDN.Row>
      </FDN.Grid>
      {popupSettingsVisible === true ? (
        <HausanschluessePopup hausanschluesse={hausanschluesse} actions={actions} />
      ) : null}
    </div>
  );
};

interface IHausanschlussProps {
  hausanschluss: IHausanschluss;
  visibleTerminPopup?: string;
  view: "cc" | "home";
  actions: TActionsBauvorhabenHausanschluesse;
}

const Hausanschluss: React.FunctionComponent<IHausanschlussProps> = ({
  visibleTerminPopup,
  hausanschluss,
  view,
  actions,
}) => {
  return (
    <div
      className={`${
        hausanschluss.used === false
          ? `not-used`
          : getHausanschlussPercent(hausanschluss) === 100
          ? `finished`
          : ``
      } hausanschluesse-type-box`}
    >
      <FDN.Box noPadding>
        <h4>{FDN.I18n.t(`ccBvHausanschluesse.types.${hausanschluss.type}.name`)}</h4>
        <div className="box-padding text-center">
          <FDN.Grid full>
            <FDN.Row margin="xy">
              <FDN.Cell sm={24} md={8}>
                {FDN.I18n.t("ccBvHausanschluesse.ausfuehrung.title")}:
              </FDN.Cell>
              <FDN.Cell sm={24} md={8}>
                {FDN.I18n.t("ccBauvorhaben.soll")}:{" "}
                {hausanschluss.used === true ? (
                  <button
                    className={`hausanschluss-button-edit-termin view-${view}`}
                    onClick={() => {
                      if (view === "cc") actions.showTerminPopup(hausanschluss.identifier);
                    }}
                  >
                    {hausanschluss.termin_soll
                      ? FDN.formatDate(hausanschluss.termin_soll, { useHumanReadableDate: false })
                      : FDN.I18n.t("ccBvHausanschluesse.notset")}
                  </button>
                ) : (
                  <span>
                    {hausanschluss.termin_soll
                      ? FDN.formatDate(hausanschluss.termin_soll, { useHumanReadableDate: false })
                      : FDN.I18n.t("ccBvHausanschluesse.notset")}
                  </span>
                )}
              </FDN.Cell>
              <FDN.Cell sm={24} md={8}>
                {FDN.I18n.t("ccBauvorhaben.ist")}:{" "}
                {hausanschluss.used === true ? (
                  <button
                    className={`hausanschluss-button-edit-termin view-${view}`}
                    onClick={() => {
                      if (view === "cc") actions.showTerminPopup(hausanschluss.identifier);
                    }}
                  >
                    {hausanschluss.termin_ist
                      ? FDN.formatDate(hausanschluss.termin_ist, { useHumanReadableDate: false })
                      : FDN.I18n.t("ccBvHausanschluesse.notset")}
                  </button>
                ) : (
                  <span>
                    {hausanschluss.termin_ist
                      ? FDN.formatDate(hausanschluss.termin_ist, { useHumanReadableDate: false })
                      : FDN.I18n.t("ccBvHausanschluesse.notset")}
                  </span>
                )}
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </div>
        <div className="box-padding text-center">
          <HausanschlussStatuses hausanschluss={hausanschluss} view={view} actions={actions} />
        </div>
        <div className="box-padding text-center">
          <FDN.Grid full>
            <FDN.Row margin="xy">
              <FDN.Cell sm={24}>
                {FDN.I18n.t("ccBvHausanschluesse.anschlussInstalliert")}:{" "}
                {hausanschluss.used === true ? (
                  <button
                    className={`hausanschluss-button-edit-termin view-${view}`}
                    onClick={() => {
                      if (view === "cc") actions.showTerminPopup(hausanschluss.identifier);
                    }}
                  >
                    {hausanschluss.termin_installation
                      ? FDN.formatDate(hausanschluss.termin_installation, {
                          useHumanReadableDate: false,
                        })
                      : FDN.I18n.t("ccBvHausanschluesse.notset")}
                  </button>
                ) : (
                  <span>
                    {hausanschluss.termin_installation
                      ? FDN.formatDate(hausanschluss.termin_installation, {
                          useHumanReadableDate: false,
                        })
                      : FDN.I18n.t("ccBvHausanschluesse.notset")}
                  </span>
                )}
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </div>
      </FDN.Box>
      {visibleTerminPopup && visibleTerminPopup === hausanschluss.identifier ? (
        <HausanschlussTerminPopup hausanschluss={hausanschluss} view={view} actions={actions} />
      ) : null}
    </div>
  );
};

const HausanschlussStatuses: React.FunctionComponent<IHausanschlussProps> = ({
  hausanschluss,
  view,
  actions,
}) => {
  return (
    <div>
      {hausanschluss.statuses?.map((hausanschlussStatus) => {
        return (
          <div key={hausanschlussStatus.type}>
            {hausanschluss.used === true ? (
              <button
                className={`hausanschluesse-status view-${view} ${
                  hausanschlussStatus.checked === true ? "success-text" : "error-text"
                }`}
                onClick={() => {
                  if (view === "cc")
                    actions.hausanschluesseToggleStatus(
                      hausanschluss.type,
                      hausanschlussStatus.type
                    );
                }}
              >
                <FDN.Icon icon={hausanschlussStatus.checked === true ? "check" : "times"} left />{" "}
                {FDN.I18n.t(
                  `ccBvHausanschluesse.status.${hausanschluss.type}.${hausanschlussStatus.type}.name`
                )}
              </button>
            ) : (
              <div className={`hausanschluesse-status `}>
                {FDN.I18n.t(
                  `ccBvHausanschluesse.status.${hausanschluss.type}.${hausanschlussStatus.type}.name`
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const HausanschlussTerminPopup: React.FunctionComponent<IHausanschlussProps> = ({
  hausanschluss,
  actions,
}) => {
  const [updatedHausanschluss, setUpdatedHausanschluss] = React.useState(cloneDeep(hausanschluss));

  const editTermin = (terminType: HausanschlussTermine, value: Date | null) => {
    updatedHausanschluss[terminType] = value || undefined;
    setUpdatedHausanschluss(cloneDeep(updatedHausanschluss));
  };

  return (
    <FDN.Popup
      size="medium"
      onClose={actions.hideTerminPopup}
      title={FDN.I18n.t(`ccBvHausanschluesse.types.${hausanschluss.type}.name`)}
    >
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={8} className="text-center">
            <h4>{FDN.I18n.t(`ccBvHausanschluesse.termine.termin_soll.name`)}</h4>
            <DatePicker
              locale="de"
              selected={
                updatedHausanschluss.termin_soll
                  ? new Date(updatedHausanschluss.termin_soll)
                  : undefined
              }
              dateFormat={"dd.MM.yyyy"}
              showMonthDropdown
              showYearDropdown
              showWeekNumbers
              isClearable
              todayButton={"Heute"}
              onChange={(date) => {
                editTermin("termin_soll", date);
              }}
            />
          </FDN.Cell>
          <FDN.Cell sm={24} md={8} className="text-center">
            <h4>{FDN.I18n.t(`ccBvHausanschluesse.termine.termin_ist.name`)}</h4>
            <DatePicker
              locale="de"
              selected={
                updatedHausanschluss.termin_ist
                  ? new Date(updatedHausanschluss.termin_ist)
                  : undefined
              }
              dateFormat={"dd.MM.yyyy"}
              showMonthDropdown
              showYearDropdown
              showWeekNumbers
              isClearable
              todayButton={"Heute"}
              onChange={(date) => {
                editTermin("termin_ist", date);
              }}
            />
          </FDN.Cell>
          <FDN.Cell sm={24} md={8} className="text-center">
            <h4>{FDN.I18n.t(`ccBvHausanschluesse.termine.termin_installation.name`)}</h4>
            <DatePicker
              locale="de"
              selected={
                updatedHausanschluss.termin_installation
                  ? new Date(updatedHausanschluss.termin_installation)
                  : undefined
              }
              dateFormat={"dd.MM.yyyy"}
              showMonthDropdown
              showYearDropdown
              showWeekNumbers
              isClearable
              todayButton={"Heute"}
              onChange={(date) => {
                editTermin("termin_installation", date);
              }}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      <FDN.FormButtons
        onCancel={actions.hideTerminPopup}
        onSave={() => actions.updateTermine(updatedHausanschluss)}
      />
    </FDN.Popup>
  );
};

interface IHausanschluessePopupProps {
  hausanschluesse: IHausanschluesse;
  actions: TActionsBauvorhabenHausanschluesse;
}

const HausanschluessePopup: React.FunctionComponent<IHausanschluessePopupProps> = ({
  hausanschluesse,
  actions,
}) => {
  return (
    <div className="hausanschluesse-settings-popup">
      <FDN.Popup
        size="small"
        title={FDN.I18n.t("ccBvHausanschluesse.settingsPopup.title")}
        onClose={actions.hideSettingsPopup}
      >
        <FDN.Grid full>
          <FDN.Row margin="xy">
            {hausanschluesse.map((hausanschluss) => {
              return (
                <FDN.Cell key={hausanschluss.type} sm={24} md={12}>
                  <button
                    className="hausanschluesse-settings-popup-toggle"
                    onClick={() => actions.hausanschluesseToggleRequired(hausanschluss.identifier)}
                  >
                    <FDN.Icon
                      icon={hausanschluss.used === true ? "check-square-o" : "square-o"}
                      left
                    />{" "}
                    {FDN.I18n.t(`ccBvHausanschluesse.types.${hausanschluss.type}.name`)}
                  </button>
                </FDN.Cell>
              );
            })}
          </FDN.Row>
        </FDN.Grid>
      </FDN.Popup>
    </div>
  );
};

export default BvHausanschluesse;
