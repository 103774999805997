import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben } from "src/types/types";
import { useNavigate } from "react-router-dom";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import useServiceCCBvLists, { TActionsBauvorhabenLists } from "src/services/cc/CCBvListsService";
import BvList from "./lists/BvList";
import MstSyncInfo from "src/components/thirdparty/microsoft/MstSyncInfo";

interface IBauvorhabenListsProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BauvorhabenLists: React.FunctionComponent<IBauvorhabenListsProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  const {
    tabs,
    selectedTab,
    setSelectedTab,
    mstList,
    lastSync,
    actions: mstListActions,
  } = useServiceCCBvLists(actions);

  const moduleActions: TActionsBauvorhabenLists = { ...actions, ...mstListActions };

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!mstList) mstListActions.init(bauvorhaben);
  }, [bauvorhaben]);

  if (!mstList) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-tab-lists">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">{mstList.name}</h2>
      <FDN.TabsContent>
        <MstSyncInfo lastSync={lastSync} bvIdentifier={bauvorhaben.identifier} type="lists" />
        {selectedTab === "list" && <BvList mstList={mstList} actions={moduleActions} />}
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenLists;
