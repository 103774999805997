import * as React from "react";
import * as FDN from "src/core";
import LayoutWithTopbar from "../../layouts/LayoutWithTopbar";
import PageContent from "../../layouts/PageContent";
import MyAccountHeader from "./myaccount/MyAccountHeader";
import TabSecurity from "./usersettings/TabSecurity";
import useServiceMySettings from "../../../services/MySettingsService";
import TabMicrosoftTeams from "./usersettings/TabMicrosoftTeams";
import { userHasAtLeastOneRole } from "../../../core/AdminService/helpers";
import { useNavigate } from "react-router-dom";

const tabs: { [key: string]: FDN.ITabsTab } = {
  // general: {
  //   label: FDN.I18n.t("usersettings.tabs.general.label"),
  //   icon: "user",
  // },
  // privacy: {
  //   label: FDN.I18n.t("usersettings.tabs.privacy.label"),
  //   icon: "key",
  // },
  security: {
    label: FDN.I18n.t("usersettings.tabs.security.label"),
    icon: "shield",
  },
  microsoft: {
    label: FDN.I18n.t("usersettings.tabs.microsoft.label"),
    icon: "share-alt",
  },
};

const defaultTab = "security";

const MySettingsPage: React.FunctionComponent = (props) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const { actions, userSettings, teamsUserInfo, APP } = useServiceMySettings();

  const navigate = useNavigate();

  if (!userSettings) return <FDN.Loading />;

  const user = APP.getUser();

  if (!user || !userHasAtLeastOneRole(user, ["STAFF", "MANAGER", "FULLADMIN"])) {
    delete tabs.microsoft;
  }

  return (
    <LayoutWithTopbar page="myaccount">
      <PageContent>
        <MyAccountHeader page="settings" />
        <div>&nbsp;</div>

        <FDN.Tabs
          updateHistory={true}
          tabs={tabs}
          editMode={false}
          selected={selectedTab}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
        <FDN.TabsContent>
          {selectedTab === "security" && (
            <TabSecurity userSettings={userSettings} actions={actions} />
          )}
          {selectedTab === "microsoft" && (
            <TabMicrosoftTeams
              userSettings={userSettings}
              teamsUserInfo={teamsUserInfo}
              actions={actions}
            />
          )}
        </FDN.TabsContent>
      </PageContent>
    </LayoutWithTopbar>
  );
};

export default MySettingsPage;
