import * as React from "react";
import * as FDN from "src/core";
import { BvLocation, ITodo, ITodoTask } from "src/types/types";
import TaskComments from "./TaskComments";
import { TActionsBauvorhabenAufgaben } from "src/services/cc/CCBvAufgabenService";

interface ITaskPopupProps {
  location: BvLocation;
  task: ITodoTask | "LOADING";
  todo: ITodo;
  taskEditMode: boolean;
  actions: TActionsBauvorhabenAufgaben;
}

const TaskPopup: React.FunctionComponent<ITaskPopupProps> = ({
  location,
  task,
  todo,
  taskEditMode,
  actions,
}) => {
  return (
    <div className="__cc-bauvorhaben-aufgaben-popup">
      <FDN.Popup
        size="large"
        title={task !== "LOADING" ? task.title : ""}
        onClose={() => actions.openTask()}
      >
        {task === "LOADING" ? (
          <div style={{ padding: "80px 0" }}>
            <FDN.Loading box />
          </div>
        ) : (
          <FDN.Grid full>
            <FDN.Row margin="xy">
              <FDN.Cell md={12}>
                <div className="__cc-bauvorhaben-aufgaben-popup-topinfoline">
                  <TaskStatus
                    task={task}
                    todo={todo}
                    taskEditMode={taskEditMode}
                    actions={actions}
                  />
                  <TaskPriority
                    task={task}
                    todo={todo}
                    taskEditMode={taskEditMode}
                    actions={actions}
                  />
                  <TaskDueDate
                    task={task}
                    todo={todo}
                    taskEditMode={taskEditMode}
                    actions={actions}
                  />
                </div>
                <TaskDescription
                  task={task}
                  todo={todo}
                  taskEditMode={taskEditMode}
                  actions={actions}
                />
                <TaskChecklist
                  task={task}
                  todo={todo}
                  taskEditMode={taskEditMode}
                  actions={actions}
                />
              </FDN.Cell>
              <FDN.Cell md={12}>
                <TaskComments location={location} task={task} />
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        )}
      </FDN.Popup>
    </div>
  );
};

interface ITaskProps {
  task: ITodoTask;
  todo: ITodo;
  taskEditMode: boolean;
  actions: TActionsBauvorhabenAufgaben;
}

const TaskStatus: React.FunctionComponent<ITaskProps> = ({ task }) => {
  return (
    <div className="__cc-bauvorhaben-aufgaben-popup-status">
      <FDN.Box small className="text-center">
        {task.percentComplete === 100 && (
          <>
            <FDN.Icon icon="check-circle" left />{" "}
            {FDN.I18n.t("ccBvAufgaben.popup.status.100.label")}
          </>
        )}
        {task.percentComplete === 50 && (
          <>
            <FDN.Icon icon="play-circle" left /> {FDN.I18n.t("ccBvAufgaben.popup.status.50.label")}
          </>
        )}
        {task.percentComplete === 0 && (
          <>
            <FDN.Icon icon="circle-thin" left /> {FDN.I18n.t("ccBvAufgaben.popup.status.0.label")}
          </>
        )}
      </FDN.Box>
    </div>
  );
};

const TaskPriority: React.FunctionComponent<ITaskProps> = ({ task }) => {
  return (
    <div className="__cc-bauvorhaben-aufgaben-popup-priority">
      <FDN.Box small className="text-center">
        {task.priority === 1 && (
          <>
            <span className="exclamation-icon">!!</span> &nbsp;
            {FDN.I18n.t("ccBvAufgaben.popup.priority.1.label")}
          </>
        )}
        {task.priority === 3 && (
          <>
            <span className="exclamation-icon">!</span> &nbsp;
            {FDN.I18n.t("ccBvAufgaben.popup.priority.3.label")}
          </>
        )}
        {task.priority === 5 && (
          <>
            <FDN.Icon icon="dot-circle-o" className="progress-icon" left />{" "}
            {FDN.I18n.t("ccBvAufgaben.popup.priority.5.label")}
          </>
        )}

        {task.priority === 7 && (
          <>
            <FDN.Icon icon="arrow-down" left /> {FDN.I18n.t("ccBvAufgaben.popup.priority.7.label")}
          </>
        )}
      </FDN.Box>
    </div>
  );
};

const TaskDescription: React.FunctionComponent<ITaskProps> = ({ task, taskEditMode }) => {
  return (
    <div className="__cc-bauvorhaben-aufgaben-popup-description">
      <FDN.FormGroup title={FDN.I18n.t("ccBvAufgaben.popup.description.label")}>
        {taskEditMode ? (
          <FDN.Input
            type="textarea"
            value={task.description}
            label={FDN.I18n.t("ccBvAufgaben.popup.description.label")}
            editable={true}
            editMode={taskEditMode}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: task.description }} />
        )}
      </FDN.FormGroup>
    </div>
  );
};

const TaskChecklist: React.FunctionComponent<ITaskProps> = ({
  task,
  todo,
  taskEditMode,
  actions,
}) => {
  if (task.checklistItemCount) {
    return (
      <FDN.FormGroup title={FDN.I18n.t("ccBvAufgaben.popup.checklist.label")}>
        <div className="__cc-bauvorhaben-aufgaben-popup-checklist">
          <TaskChecklistList
            task={task}
            todo={todo}
            taskEditMode={taskEditMode}
            actions={actions}
          />
          <FDN.Icon icon="list-ul" left /> {task.finishedChecklistItemCount} /{" "}
          {task.checklistItemCount}
        </div>
      </FDN.FormGroup>
    );
  } else return <>&nbsp;</>;
};

const TaskChecklistList: React.FunctionComponent<ITaskProps> = ({ task }) => {
  if (!task.checklistItems || task.checklistItems.length === 0) return null;

  const checklistItems = task.checklistItems as [{ [key: string]: any }];

  return (
    <div className="__cc-bauvorhaben-aufgaben-popup-checklist-list">
      <ul>
        {checklistItems.map((checklistItem) => {
          return (
            <li key={checklistItem.identifier}>
              <FDN.Icon
                icon={checklistItem.isChecked === true ? `check-circle-o` : `circle-o`}
                left
              />
              {checklistItem.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const TaskDueDate: React.FunctionComponent<ITaskProps> = ({ task }) => {
  return (
    <div className="__cc-bauvorhaben-aufgaben-popup-duedate">
      <FDN.Box small className="text-center">
        <FDN.Icon icon="calendar" left />{" "}
        {!task.dueDate ? (
          FDN.I18n.t("ccBvAufgaben.popup.duedate.noDueDate")
        ) : (
          <span>{FDN.formatDate(task.dueDate)}</span>
        )}
      </FDN.Box>
    </div>
  );
};

export default TaskPopup;
