import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben, IBvClients } from "src/types/types";
import useServiceCore from "src/services/CoreService";
import CCApi from "src/api/CCApi";
import StatusCode from "src/config/statuscodes";
import BvClients from "./clients/BvClients";
import BvClientsContent from "./clients/BvClientsContent";
import { useNavigate } from "react-router-dom";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";

const contentTab = {
  label: FDN.I18n.t("ccBvClients.tabs.content.label"),
  icon: "ban",
};

const tabs: { clients: FDN.ITabsTab; content?: FDN.ITabsTab } = {
  clients: {
    label: FDN.I18n.t("ccBvClients.tabs.clients.label"),
    icon: "key",
  },
};

const defaultTab = "clients";

interface IBauvorhabenClientsProps {
  bauvorhaben: IBauvorhaben;
  editMode: boolean;
  actions: TActionsBauvorhaben;
}

const BauvorhabenClients: React.FunctionComponent<IBauvorhabenClientsProps> = ({
  bauvorhaben,
  editMode,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);
  const [clients, setClients] = React.useState<IBvClients>();

  const { api, APP } = useServiceCore();
  const { userView } = APP;

  const navigate = useNavigate();

  React.useEffect(() => {
    if (userView === "cc") tabs.content = contentTab;

    CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "clients").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setClients(response?.body?.clients as IBvClients);
      }
    });
  }, [bauvorhaben]);

  if (!clients) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-tab-clients">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">
        {FDN.I18n.t(`ccBvClients.tabs.${selectedTab}.title`)}
      </h2>
      <FDN.TabsContent>
        {selectedTab === "clients" && (
          <BvClients
            bauvorhaben={bauvorhaben}
            clients={clients}
            editMode={editMode}
            actions={actions}
          />
        )}
        {selectedTab === "content" && (
          <BvClientsContent bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
        )}
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenClients;
