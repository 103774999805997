import { useState } from "react";
import MstApi from "../../../../../api/MstApi";
import useServiceCore from "../../../../../services/CoreService";
import { IFileBrowserFile, IMstFolder, TActions } from "../../../../../types/types";
import { cloneDeep } from "lodash";

export const useServiceFileBrowser = (initMstFolder: IMstFolder) => {
  const [mstFolder, setMstFolder] = useState<IMstFolder>(cloneDeep(initMstFolder));
  const [selectedFile, setSelectedFile] = useState<IFileBrowserFile>();
  const [listLoading, setListLoading] = useState(false);
  const [pdfViewFile, setPdfViewFile] = useState<IFileBrowserFile>();

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  const openFolder = (_driveId: string, _folderId: string, forceReload: "0" | "1" = "0") => {
    setListLoading(true);
    selectFile();
    MstApi.filesOpenFolder(api, _driveId, _folderId, forceReload).then((response) => {
      if (response?.body?.folder) {
        setMstFolder(response?.body?.folder as IMstFolder);
        setListLoading(false);
      }
    });
  };

  const selectFile = (file?: IFileBrowserFile | null) => {
    if (file) setSelectedFile(cloneDeep(file));
    else setSelectedFile(undefined);
  };

  const viewPdf = (file?: IFileBrowserFile | null) => {
    if (file) setPdfViewFile(cloneDeep(file));
    else setPdfViewFile(undefined);
  };

  const actions: TActions = {
    openFolder,
    selectFile,
    viewPdf,
  };

  return { mstFolder, pdfViewFile, selectedFile, listLoading, actions };
};
