import * as React from "react";
import * as FDN from "src/core";
import { Avatar } from "@tschirpke/ptfdn-frontend-components";
import {
  BvLocation,
  IAccountState,
  IConversation,
  INewConversationPost,
  MstPost,
  TFunction,
} from "src/types/types";
import UrlService from "src/core/UrlService";
import { cloneDeep } from "lodash";
import MstApi from "src/api/MstApi";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import { formatDateTime } from "src/core/helpers/helpers";

interface IConversationProps {
  location: BvLocation;
  conversation: IConversation;
}

const Conversation: React.FunctionComponent<IConversationProps> = (props) => {
  const initConversation = props.conversation;
  const { location } = props;

  const virginNewPost: INewConversationPost = {
    groupId: initConversation.groupId,
    threadId: initConversation.threadId,
    body: "",
    onBehalfOf: undefined,
  };
  const [conversation, setConversation] = React.useState(initConversation);
  const [newPost, setNewPost] = React.useState<INewConversationPost>();
  const [sendLoading, setSendLoading] = React.useState(false);
  const [postsLoading, setPostsLoading] = React.useState(false);

  const { APP, NOTIFICATIONS, api } = useServiceCore();
  const user = APP.getUser();

  React.useEffect(() => {
    if (!newPost) setNewPost(cloneDeep(virginNewPost));
  }, [conversation]);

  const onEditNewComment = (text: string) => {
    if (!newPost) return;

    newPost.body = text;
    setNewPost(cloneDeep(newPost));
  };

  const createNewPost = () => {
    if (!newPost || !newPost.body || !user) return;

    if (!user.teamsAccessToken || !user.teamsPermissions) {
      newPost.onBehalfOf = user.displayname;
    }

    setSendLoading(true);

    MstApi.conversationNewPost(api, newPost).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setNewPost(cloneDeep(virginNewPost));
        setPostsLoading(true);

        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("ccBvAufgaben.comments.refresh.title"),
          FDN.I18n.t("ccBvAufgaben.comments.refresh.text")
        );

        window.setTimeout(() => {
          MstApi.conversationRefresh(api, conversation.groupId, conversation.threadId).then(
            (response) => {
              if (response?.statusCode === StatusCode.SUCCESS && response?.body?.conversation) {
                setConversation(response.body.conversation as IConversation);
                setPostsLoading(false);
              }
            }
          );
        }, 3000);
      }
      setSendLoading(false);
    });
  };

  if (!conversation || !conversation.posts || conversation.posts.length === 0 || !newPost)
    return (
      <div className="__conversation-comments-noposts">
        {FDN.I18n.t("ccMain.conversation.noposts")}
      </div>
    );

  return (
    <div className="__conversation">
      {!user ? (
        <></>
      ) : (
        <>
          {location === "CC" && (!user.teamsAccessToken || !user.teamsPermissions) ? (
            <div
              className="__conversation-newcommentform-noteamsconnection"
              dangerouslySetInnerHTML={{
                __html: FDN.I18n.t("ccBvAufgaben.comments.newComment.noTeamsConnection", {
                  url: `${UrlService.url("user.settings")}?tab=microsoft`,
                }),
              }}
            />
          ) : (
            <NewCommentForm
              newPost={newPost}
              sendLoading={sendLoading}
              onEditNewComment={onEditNewComment}
              createNewPost={createNewPost}
              user={user}
            />
          )}
        </>
      )}
      {postsLoading === true ? (
        <FDN.Loading box />
      ) : (
        <Posts conversation={conversation} user={user} />
      )}
    </div>
  );
};

interface IPostsProps {
  conversation: IConversation;
  user?: IAccountState | null;
}

const Posts: React.FunctionComponent<IPostsProps> = ({ conversation, user }) => {
  return (
    <div className="__conversation-posts">
      {conversation.posts.map((post) => {
        return <Post post={post} user={user} key={post.identifier} />;
      })}
    </div>
  );
};

interface IPostProps {
  post: MstPost;
  user?: IAccountState | null;
}

const Post: React.FunctionComponent<IPostProps> = ({ post, user }) => {
  return (
    <div className={`__conversation-posts-post ${user?.email !== post.author?.email ? `own` : ``}`}>
      <div className="__conversation-posts-post-frame">
        <div className="__conversation-posts-post-avatar">
          <Avatar user={post.author} size={30} />
        </div>
        <div className="__conversation-posts-post-content-wrapper">
          <PostInfoLine post={post} user={user} />
          <div className="__conversation-posts-post-content">
            <div
              className="__conversation-posts-post-body"
              dangerouslySetInnerHTML={{ __html: post.body }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const PostInfoLine: React.FunctionComponent<IPostProps> = ({ post }) => {
  const date = formatDateTime(post._teamsCreatedDateTime)
    .replace("Today", FDN.I18n.t("main.date.today"))
    .replace("Yesterday", FDN.I18n.t("main.date.yesterday"))
    .replace("Tomorrow", FDN.I18n.t("main.date.tomorrow"));

  return (
    <div className="__conversation-posts-post-infoline">
      <div className="__conversation-posts-post-infoline-author">
        {post.author?.displayname ? post.author.displayname : "?"}
      </div>
      <div className="__conversation-posts-post-infoline-date">{date}</div>
    </div>
  );
};

interface INewCommentFormProps {
  post?: MstPost;
  user: IAccountState;
  newPost: INewConversationPost;
  sendLoading: boolean;
  onEditNewComment: TFunction;
  createNewPost: TFunction;
}

const NewCommentForm: React.FunctionComponent<INewCommentFormProps> = ({
  user,
  newPost,
  sendLoading,
  onEditNewComment,
  createNewPost,
}) => {
  return (
    <div className="__conversation-newcommentform">
      <div className="__conversation-newcommentform-title">
        {FDN.I18n.t("ccBvAufgaben.comments.newComment.title")}
      </div>
      <div className="__conversation-newcommentform-form">
        <Avatar user={user} size={46} />
        <div className="__conversation-newcommentform-form-textarea">
          <textarea
            value={newPost.body}
            rows={4}
            onChange={(e) => onEditNewComment(e.target.value)}
          />
          <div className="__conversation-newcommentform-form-buttons">
            <button
              className="small primary button"
              disabled={!newPost.body || sendLoading === true}
              style={{ minWidth: "140px" }}
              onClick={createNewPost}
            >
              {sendLoading === true ? (
                <FDN.Icon icon="circle-o-notch fa-spin" />
              ) : (
                <>
                  <FDN.Icon icon="check" left />
                  {FDN.I18n.t("ccBvAufgaben.comments.newComment.form.onSend.label")}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conversation;
