import * as React from "react";
import * as FDN from "src/core";
import CCLayout from "../layouts/CCLayout";
import useServiceCCBauvorhabenList from "src/services/cc/CCBauvorhabenListService";
import CCHeader from "../layouts/CCHeader";
import NewBauvorhaben from "./NewBauvorhaben";
import { IBauvorhaben, ICategorizedBauvorhabenList, IJumpToListBauvorhaben } from "src/types/types";
import BauvorhabenImage from "../bauvorhaben/parts/BauvorhabenImage";
import BauvorhabenLabels from "../bauvorhaben/parts/BauvorhabenLabels";
import { Link, useNavigate } from "react-router-dom";
import UrlService from "src/core/UrlService";

const CCBauvorhabenList: React.FunctionComponent = () => {
  const [newPopupVisible, setNewPopupVisible] = React.useState(false);
  const { bauvorhabenList, newBauvorhaben, missing, tabs, selectedTab, actions } =
    useServiceCCBauvorhabenList();

  const navigate = useNavigate();

  const filterBauvorhaben = (
    type: keyof ICategorizedBauvorhabenList
  ): (IBauvorhaben | IJumpToListBauvorhaben)[] | undefined => {
    if (bauvorhabenList && bauvorhabenList.list && type in bauvorhabenList.list)
      return bauvorhabenList.list[type];
    return undefined;
  };

  if (!bauvorhabenList || !newBauvorhaben) return <FDN.Loading box />;

  const filteredBauvorhaben = filterBauvorhaben(selectedTab as keyof ICategorizedBauvorhabenList);

  return (
    <CCLayout page="cc-bauvorhaben" contentPadding="20px">
      <CCHeader
        subtitle={FDN.I18n.t("ccBauvorhaben.bauvorhabenList.header.subtitle")}
        title={FDN.I18n.t("ccBauvorhaben.bauvorhabenList.header.title")}
        buttons={[
          <button className="primary button" onClick={() => setNewPopupVisible(true)} key="add">
            <FDN.Icon icon="plus" left /> {FDN.I18n.t("ccBauvorhaben.buttons.new.label")}
          </button>,
        ]}
      />
      <div>&nbsp;</div>

      <div className="__cc-bauvorhaben-list-tabs">
        <FDN.Tabs
          updateHistory={true}
          tabs={tabs}
          selected={selectedTab}
          onSelect={(tabKey) => actions.setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
      </div>
      <FDN.TabsContent>
        {!filteredBauvorhaben || filteredBauvorhaben.length === 0 ? (
          <div className="text-center" style={{ padding: "60px" }}>
            {FDN.I18n.t(`ccBauvorhaben.list.noBv.${selectedTab}`)}
          </div>
        ) : (
          <BauvorhabenList list={filteredBauvorhaben} />
        )}
      </FDN.TabsContent>
      {newPopupVisible === true && (
        <FDN.Popup
          size="medium"
          title={FDN.I18n.t("ccBauvorhaben.new.title")}
          onClose={actions.onCancelNew}
        >
          <NewBauvorhaben newBauvorhaben={newBauvorhaben} missing={missing} actions={actions} />
        </FDN.Popup>
      )}
    </CCLayout>
  );
};

interface IBauvorhabenListProps {
  list: (IBauvorhaben | IJumpToListBauvorhaben)[];
}

const BauvorhabenList: React.FunctionComponent<IBauvorhabenListProps> = ({ list }) => {
  return (
    <div className="__cc-bauvorhaben-list">
      <ul>
        {list.map((bauvorhaben, index) => {
          return (
            <li key={index}>
              <BauvorhabenListItem bauvorhaben={bauvorhaben} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

interface IBauvorhabenListItemProps {
  bauvorhaben: IBauvorhaben;
}

const BauvorhabenListItem: React.FunctionComponent<IBauvorhabenListItemProps> = ({
  bauvorhaben,
}) => {
  return (
    <Link
      to={UrlService.url("cc.bauvorhaben.show", { identifier: bauvorhaben.identifier })}
      className="__cc-bauvorhaben-list-item"
    >
      <div className="__cc-bauvorhaben-list-item-name-and-image">
        <BauvorhabenImage bauvorhaben={bauvorhaben} />
        <div>
          <div className="__cc-bauvorhaben-list-item-name">{bauvorhaben.name}</div>
          <div className="__cc-bauvorhaben-list-item-address">{bauvorhaben.address}</div>
          <div className="__cc-bauvorhaben-list-item-labels">
            <BauvorhabenLabels bauvorhaben={bauvorhaben} hide={["status"]} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CCBauvorhabenList;
