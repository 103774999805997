import Config from "../Config";
import { I18nCore } from "@tschirpke/ptfdn-frontend-components";

import main from "./translations/main.json";
import buttons from "./translations/buttons.json";
import errors from "./translations/errors.json";
import topbar from "./translations/topbar.json";

import login from "./translations/pages/login.json";
import passwordreset from "./translations/pages/passwordreset.json";
import signup from "./translations/pages/signup.json";
import welcome from "./translations/pages/welcome.json";
import myaccount from "./translations/pages/myaccount.json";
import usersettings from "./translations/pages/usersettings.json";

import imageupload from "./translations/components/imageupload.json";
import passwordsetform from "./translations/components/passwordsetform.json";
import cookiebanner from "./translations/components/cookiebanner.json";
import languageswitch from "./translations/components/languageswitch.json";
import usernotifications from "./translations/components/usernotifications.json";
import filebrowser from "./translations/components/filebrowser.json";

import ccMain from "./translations/cc/main.json";
import ccBauvorhaben from "./translations/cc/bauvorhaben/bauvorhaben.json";
import ccBvStatus from "./translations/cc/bauvorhaben/status.json";
import ccBvDetails from "./translations/cc/bauvorhaben/details.json";
import ccBvAufgaben from "./translations/cc/bauvorhaben/aufgaben.json";
import ccBvTermine from "./translations/cc/bauvorhaben/termine.json";
import ccBvDateien from "./translations/cc/bauvorhaben/dateien.json";
import ccBvClients from "./translations/cc/bauvorhaben/clients.json";
import ccBvEinstellungen from "./translations/cc/bauvorhaben/einstellungen.json";
import ccBvInformationen from "./translations/cc/bauvorhaben/informationen.json";
import ccBvBauakte from "./translations/cc/bauvorhaben/bauakte.json";
import ccBvKnowledgebase from "./translations/cc/bauvorhaben/knowledgebase.json";
import ccBvHausanschluesse from "./translations/cc/bauvorhaben/hausanschluesse.json";
import ccBvBauvorbereitung from "./translations/cc/bauvorhaben/bauvorbereitung.json";
import ccBvPaymentPlan from "./translations/cc/bauvorhaben/paymentplan.json";
import ccBvLists from "./translations/cc/bauvorhaben/lists.json";

import adminAdmin from "./translations/admin/admin.json";
import adminLogs from "./translations/admin/logs.json";
import adminDashboard from "./translations/admin/dashboard.json";
import adminAccounts from "./translations/admin/accounts.json";
import adminUserroles from "./translations/admin/userroles.json";
import adminSettings from "./translations/admin/settings.json";
import adminEmailTemplates from "./translations/admin/emailtemplates.json";

const translations: { [key: string]: any } = {
  main,
  buttons,
  errors,
  topbar,

  login,
  passwordreset,
  signup,
  welcome,
  myaccount,
  usersettings,

  imageupload,
  passwordsetform,
  cookiebanner,
  languageswitch,
  usernotifications,
  filebrowser,

  ccMain,
  ccBauvorhaben,
  ccBvStatus,
  ccBvDetails,
  ccBvAufgaben,
  ccBvTermine,
  ccBvDateien,
  ccBvClients,
  ccBvEinstellungen,
  ccBvInformationen,
  ccBvBauakte,
  ccBvKnowledgebase,
  ccBvHausanschluesse,
  ccBvBauvorbereitung,
  ccBvPaymentPlan,
  ccBvLists,

  adminAdmin,
  adminLogs,
  adminDashboard,
  adminAccounts,
  adminUserroles,
  adminSettings,
  adminEmailTemplates,
};

const I18n = new I18nCore(Config.get("i18n.defaultLanguage") as string, translations);

export default I18n;
