import * as React from "react";
import * as FDN from "src/core";
import { IBauvorhaben } from "src/types/types";
import BauvorhabenImage from "../parts/BauvorhabenImage";
import BauvorhabenLabels from "../parts/BauvorhabenLabels";
import { IAdminBackButton } from "src/components/pages/admin/layouts/AdminBackButton";

interface IBauvorhabenHeaderProps {
  bauvorhaben: IBauvorhaben;
  backButton?: IAdminBackButton;
  buttons?: React.ReactNode[];
}

const BauvorhabenHeader: React.FunctionComponent<IBauvorhabenHeaderProps> = ({
  bauvorhaben,
  buttons,
}) => {
  return (
    <div className="__cc-bauvorhaben-header">
      <div className="__cc-bauvorhaben-header-box">
        <div className="__cc-bauvorhaben-header-box-content">
          <div className="__cc-bauvorhaben-header-box-main">
            <BauvorhabenImage bauvorhaben={bauvorhaben} />
            <div className="__admin-header-title">
              {bauvorhaben.name && <h1>{bauvorhaben.name}</h1>}
              <div className="__cc-bauvorhaben-header-address">{bauvorhaben.address}</div>
              <div className="__cc-bauvorhaben-header-labels">
                <BauvorhabenLabels bauvorhaben={bauvorhaben} />
              </div>
            </div>
            <div className="__cc-bauvorhaben-header-termine">
              {bauvorhaben.baubeginnDate && bauvorhaben.baubeginnType ? (
                <div className="__cc-bauvorhaben-header-termine-termin">
                  <div className="__cc-bauvorhaben-header-termine-termin-content">
                    <div className="termin-text">
                      {FDN.I18n.t(
                        `ccBauvorhaben.header.termine.baubeginn.${bauvorhaben.baubeginnType}.label`
                      )}
                    </div>
                    <div className="termin-date">({FDN.formatDate(bauvorhaben.baubeginnDate)})</div>
                  </div>
                </div>
              ) : null}
              {bauvorhaben.uebergabeDate && bauvorhaben.uebergabeType ? (
                <div className="__cc-bauvorhaben-header-termine-termin">
                  <div className="__cc-bauvorhaben-header-termine-termin-content">
                    <div className="termin-text">
                      {FDN.I18n.t(
                        `ccBauvorhaben.header.termine.uebergabe.${bauvorhaben.uebergabeType}.label`
                      )}
                    </div>
                    <div className="termin-date">({FDN.formatDate(bauvorhaben.uebergabeDate)})</div>
                  </div>
                </div>
              ) : null}
            </div>
            {buttons && (
              <div className="__admin-header-buttons text-right">
                {buttons.map((button, index) => (
                  <span key={index} className="__admin-header-buttons-item">
                    {button}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BauvorhabenHeader;
