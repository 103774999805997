import * as React from "react";
import { IFileBrowserFolder, IMstFolder } from "../../../../../../types/types";
import filetypes from "../../service/filetypes";

interface IFileIconProps {
  item?: IFileBrowserFolder | IFileBrowserFolder;
  back?: boolean;
}

const FileIcon: React.FunctionComponent<IFileIconProps> = ({ item, back }) => {
  let icon = filetypes.file.icon;

  let itemType;
  if (back === true) itemType = "back";
  else if (item && item.type === "FOLDER") itemType = "folder";
  else if (item && item.type === "FILE") itemType = "file";

  if (itemType === "back") icon = filetypes.back.icon;
  else if (itemType === "folder") icon = filetypes.folder.icon;
  else if (item && filetypes[item.fileType as keyof typeof filetypes]?.icon)
    icon = filetypes[item.fileType as keyof typeof filetypes]?.icon;

  return (
    <img
      className={`__filebrowser-list-icon __filebrowser-list-icon-type-${itemType}`}
      src={icon}
    />
  );

  return null;
};

export default FileIcon;
