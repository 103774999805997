import * as React from "react";
import * as FDN from "src/core";
import {
  IBauvorhaben,
  IBvDetail,
  IBvFenster,
  IBvFertigungsbuergschaft,
  IBvRohbauCheck,
  IBvVermessung,
} from "src/types/types";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import BvVermessung from "./BvVermessung";
import BvDetails from "./BvDetails";
import BvFenster from "./BvFenster";
import BvRohbauCheck from "./BvRohbauCheck";
import BvFertigungsbuergschaften from "./BvFertigungsbuergschaften";
import BvWaermepumpe from "../../parts/BvWaermepumpe";
import { TActionsBauvorhabenBauvorbereitung } from "src/services/cc/CCBvBauvorbereitungService";
import BvFortschritt from "../../parts/BvFortschritt";

interface IBvVorBaubeginnProps {
  bauvorhaben: IBauvorhaben;
  details: IBvDetail;
  vermessung: IBvVermessung;
  fenster: IBvFenster;
  rohbauCheck: IBvRohbauCheck;
  fertigungsbuergschaften: IBvFertigungsbuergschaft;
  editMode: boolean;
  actions: TActionsBauvorhabenBauvorbereitung;
}

const BvVorBaubeginn: React.FunctionComponent<IBvVorBaubeginnProps> = ({
  bauvorhaben,
  details,
  vermessung,
  fenster,
  rohbauCheck,
  fertigungsbuergschaften,
  editMode,
  actions,
}) => {
  return (
    <div className="__cc-bauvorhaben-vorbaubeginn">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={8}>
            <BvFortschritt details={details} editMode={editMode} actions={actions} />
            <BvWaermepumpe details={details} editMode={editMode} actions={actions} />
          </FDN.Cell>
          <FDN.Cell sm={24} md={8}>
            <BvVermessung vermessung={vermessung} />
            <BvFenster fenster={fenster} />
            <BvRohbauCheck rohbauCheck={rohbauCheck} />
            <BvFertigungsbuergschaften fertigungsbuergschaften={fertigungsbuergschaften} />
          </FDN.Cell>
          <FDN.Cell sm={24} md={8}>
            <BvDetails details={details} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default BvVorBaubeginn;
