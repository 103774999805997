import * as React from "react";
import * as FDN from "src/core";
import { IBvFertigungsbuergschaft } from "src/types/types";
import BvProgressListBox from "../../parts/BvProgressList";
import UrlService from "src/core/UrlService";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IBvFertigungsbuergschaftenProps {
  fertigungsbuergschaften?: IBvFertigungsbuergschaft;
}

const BvFertigungsbuergschaften: React.FunctionComponent<IBvFertigungsbuergschaftenProps> = ({
  fertigungsbuergschaften,
}) => {
  if (!fertigungsbuergschaften) return <FDN.Loading box />;

  const APP = React.useContext(AppContext);
  const userView = APP.userView;

  return (
    <div className="__bv-fertigungsbuergschaften">
      <FDN.Box>
        <BvProgressListBox
          type="fertigungsbuergschaften"
          isEditable={userView === "cc"}
          fertigungsbuergschaften={fertigungsbuergschaften}
        />
      </FDN.Box>
    </div>
  );
};

export default BvFertigungsbuergschaften;
