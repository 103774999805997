import * as React from "react";
import * as FDN from "src/core";
import BauvorhabenStatus from "../tabs/BauvorhabenStatus";
import BauvorhabenAufgaben from "../tabs/BauvorhabenAufgaben";
import BauvorhabenTermine from "../tabs/BauvorhabenTermine";
import BauvorhabenDateien from "../tabs/BauvorhabenDateien";
import BauvorhabenEinstellungen from "../tabs/BauvorhabenEinstellungen";
import BauvorhabenInformationen from "../tabs/BauvorhabenInformationen";
import BauvorhabenClients from "../tabs/BauvorhabenClients";
import { BvLocation, IBauvorhaben } from "src/types/types";
import BauvorhabenBauakte from "../tabs/BauvorhabenBauakte";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import BauvorhabenKnowledgebase from "../tabs/BauvorhabenKnowledgebase";
import BauvorhabenKnowledgebaseFormulare from "../tabs/BauvorhabenKnowledgebaseFormulare";
import BauvorhabenHausanschluesse from "../tabs/BauvorhabenHausanschluesse";
import BauvorhabenBauvorbereitung from "../tabs/BauvorhabenBauvorbereitung";
import BauvorhabenPaymentPlan from "../tabs/BauvorhabenPaymentPlan";
import BauvorhabenLists from "../tabs/BauvorhabenLists";

interface IBauvorhabenContentProps {
  bauvorhaben: IBauvorhaben;
  location: BvLocation;
  editMode: boolean;
  selectedTab: string;
  actions: TActionsBauvorhaben;
}

const BauvorhabenContent: React.FunctionComponent<IBauvorhabenContentProps> = ({
  bauvorhaben,
  location,
  editMode,
  selectedTab,
  actions,
}) => {
  return (
    <div className={`__cc-bauvorhaben-content`}>
      {selectedTab === "status" && (
        <BauvorhabenStatus bauvorhaben={bauvorhaben} actions={actions} />
      )}
      {selectedTab === "aufgaben" && (
        <BauvorhabenAufgaben
          bauvorhaben={bauvorhaben}
          location={location}
          editMode={editMode}
          actions={actions}
        />
      )}
      {selectedTab === "lists" && (
        <BauvorhabenLists bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
      )}
      {selectedTab === "termine" && (
        <BauvorhabenTermine bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
      )}
      {selectedTab === "bauakte" && (
        <BauvorhabenBauakte bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
      )}
      {selectedTab === "knowledgebase" && (
        <BauvorhabenKnowledgebase bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
      )}
      {selectedTab === "formulare" && (
        <BauvorhabenKnowledgebaseFormulare
          bauvorhaben={bauvorhaben}
          editMode={editMode}
          actions={actions}
        />
      )}
      {selectedTab === "dateien" && (
        <BauvorhabenDateien bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
      )}
      {selectedTab === "bauvorbereitung" && (
        <BauvorhabenBauvorbereitung
          bauvorhaben={bauvorhaben}
          editMode={editMode}
          actions={actions}
        />
      )}
      {selectedTab === "einstellungen" && (
        <BauvorhabenEinstellungen bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
      )}
      {selectedTab === "informationen" && (
        <BauvorhabenInformationen bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
      )}
      {selectedTab === "clients" && (
        <BauvorhabenClients bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
      )}
      {selectedTab === "hausanschluesse" && (
        <BauvorhabenHausanschluesse
          bauvorhaben={bauvorhaben}
          editMode={editMode}
          actions={actions}
        />
      )}
      {selectedTab === "paymentplan" && (
        <BauvorhabenPaymentPlan bauvorhaben={bauvorhaben} editMode={editMode} actions={actions} />
      )}
      {editMode === true && (
        <FDN.FormButtons
          smallButtons
          marginBottom="20px"
          onSave={actions.onSave}
          onCancel={actions.onCancel}
        />
      )}
    </div>
  );
};

export default BauvorhabenContent;
