import * as React from "react";
import * as FDN from "src/core";
import CCLayout from "../layouts/CCLayout";
import useServiceCCDashboard from "src/services/cc/CCDashboardService";
import UrlService from "src/core/UrlService";

const CCDashboard: React.FunctionComponent = () => {
  React.useEffect(() => {
    window.location.href = UrlService.url("cc.bauvorhaben.index");
  }, []);

  return null;
  // const { pageLoaded, actions } = useServiceCCDashboard();

  // if (!pageLoaded) return <FDN.Loading />;

  // return (
  //   <CCLayout page="cc-dashboard">
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         padding: "40px",
  //       }}
  //     >
  //       <FDN.Box>CC DASHBOARD following...</FDN.Box>
  //     </div>
  //   </CCLayout>
  // );
};

export default CCDashboard;
