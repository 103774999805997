import * as React from "react";
import * as FDN from "src/core";
import ProgressBar from "@ramonak/react-progress-bar";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { TActionsBauvorhabenBauvorbereitung } from "src/services/cc/CCBvBauvorbereitungService";
import { BvDetailTypePercent, IBvDetail } from "src/types/types";
import { isInteger } from "lodash";

interface IBvDetailPercentProps {
  details?: IBvDetail;
  type: string;
  title?: string;
  editMode: boolean;
  step?: number;
  actions?: TActionsBauvorhabenBauvorbereitung;
}

const BvDetailPercent: React.FunctionComponent<IBvDetailPercentProps> = ({
  details,
  type,
  title,
  editMode,
  step,
  actions,
}) => {
  if (!details) return null;

  if (!step) step = 10;

  return (
    <div className="__bv-detail-percent">
      {editMode === true && actions ? (
        <>
          {title ? <div className="__bv-detail-percent-title">{title}</div> : null}
          <PercentEdit
            type={type}
            value={details[type as keyof typeof details] as BvDetailTypePercent}
            step={step}
            actions={actions}
          />
        </>
      ) : (
        <>
          {title ? <div className="__bv-detail-percent-title">{title}</div> : null}
          <PercentView
            type={type}
            value={details[type as keyof typeof details] as BvDetailTypePercent}
          />
        </>
      )}
    </div>
  );
};

interface IPercentView {
  type: string;
  value: BvDetailTypePercent;
}

const PercentView: React.FunctionComponent<IPercentView> = ({ type, value }) => {
  const percent = value || 0;
  let bgColor = "rgb(200,200,200)";

  if (percent <= 50) bgColor = "red";
  else if (percent > 50 && percent < 100) bgColor = "orange";
  else if (percent === 100) bgColor = "green";

  return (
    <div className="__bv-detail-percent-view">
      <div className="__bv-detail-percent-view-bar">
        <ProgressBar
          completed={`${percent}`}
          isLabelVisible={false}
          height="10px"
          bgColor={bgColor}
        />
      </div>
      <div className="__bv-detail-percent-view-label">{percent} %</div>
    </div>
  );
};

interface IPercentEdit {
  type: string;
  value: BvDetailTypePercent;
  step: number;
  actions: TActionsBauvorhabenBauvorbereitung;
}

const PercentEdit: React.FunctionComponent<IPercentEdit> = ({ type, value, step, actions }) => {
  const percent = value || 0;

  return (
    <div className="__bv-detail-percent-view">
      <div className="__bv-detail-percent-view-bar">
        <Slider
          value={percent}
          step={step}
          onChange={(value) => {
            if (actions?.onEditDetail && isInteger(value))
              actions.onEditDetail(type as keyof IBvDetail, value as number);
          }}
        />
      </div>
      <div className="__bv-detail-percent-view-label">{percent} %</div>
    </div>
  );
};

export default BvDetailPercent;
