import * as React from "react";
import * as FDN from "src/core";
import {
  IBvFenster,
  IBvFertigungsbuergschaft,
  IBvRohbauCheck,
  IBvVermessung,
} from "src/types/types";
import BvPercentBar from "./BvPercentBar";
import BvDetail from "./BvDetail";
import { Link } from "react-router-dom";

const settings = {
  vermessung: {
    showPercent: true,
    items: [
      "vermessung__beauftragt",
      "vermessung__vermessungsvorschlag",
      "vermessung__grobabsteckung",
      "vermessung__hoehenprotokoll",
      "vermessung__vermessungsprotokoll",
      "vermessung__vermessungsprotokoll_abgezeichnet",
    ],
  },
  fenster: {
    showPercent: true,
    items: [
      "fensterblankett",
      "fenster_energieberater",
      "fenster_genehmigt_bl",
      "fenster_genehmigt",
      "fenster_beauftragt",
      "fensterbaenke_bemustert",
      "fensterfolien_entfernt",
    ],
  },
  rohbauCheck: {
    showPercent: true,
    items: [
      "rohbau__meterriss",
      "rohbau__estrich",
      "rohbau__betontreppe",
      "rohbau__durchbrueche",
      "rohbau__stuerze",
      "rohbau__fenster",
      "rohbau__tueren",
      "rohbau__waende",
    ],
  },
  fertigungsbuergschaften: {
    showPercent: true,
    items: ["buergschaft__1", "buergschaft__reinschrift", "buergschaft__2"],
  },
};

type FUpdateFromChild = (props: FUpdateFromChildProps) => void;

interface FUpdateFromChildProps {
  vermessung?: IBvVermessung | false;
  fenster?: IBvFenster | false;
  rohbauCheck?: IBvRohbauCheck | false;
  fertigungsbuergschaften?: IBvFertigungsbuergschaft | false;
}

interface IBvProgressListProps {
  type: "vermessung" | "fenster" | "rohbauCheck" | "fertigungsbuergschaften";
  isEditable: boolean;
  link?: string;
  vermessung?: IBvVermessung | false;
  fenster?: IBvFenster | false;
  rohbauCheck?: IBvRohbauCheck | false;
  fertigungsbuergschaften?: IBvFertigungsbuergschaft | false;
}

const BvProgressListBox: React.FunctionComponent<IBvProgressListProps> = ({
  type,
  isEditable,
  link,
  vermessung,
  fenster,
  rohbauCheck,
  fertigungsbuergschaften,
}) => {
  const [bvVermessung, setBvVermessung] = React.useState<IBvVermessung | undefined | false>(
    vermessung
  );
  const [bvFenster, setBvFenster] = React.useState<IBvFenster | undefined | false>(fenster);
  const [bvRohbauCheck, setBvRohbauCheck] = React.useState<IBvRohbauCheck | undefined | false>(
    rohbauCheck
  );
  const [bvFertigungsbuergschaften, setBvFertigungsbuergschaften] = React.useState<
    IBvFertigungsbuergschaft | undefined | false
  >(fertigungsbuergschaften);

  const [percent, setPercent] = React.useState(0);

  React.useEffect(() => {
    if (type === "vermessung" && bvVermessung) {
      setPercent(bvVermessung.percent);
    }
  }, [bvVermessung]);

  React.useEffect(() => {
    if (type === "fenster" && bvFenster) {
      setPercent(bvFenster.percent);
    }
  }, [bvFenster]);

  React.useEffect(() => {
    if (type === "rohbauCheck" && bvRohbauCheck) {
      setPercent(bvRohbauCheck.percent);
    }
  }, [bvRohbauCheck]);

  React.useEffect(() => {
    if (type === "fertigungsbuergschaften" && bvFertigungsbuergschaften) {
      setPercent(bvFertigungsbuergschaften.percent);
    }
  }, [bvFertigungsbuergschaften]);

  const updateFromChild: FUpdateFromChild = (props) => {
    if (type === "vermessung") {
      const updatedBvVermessung = props.vermessung;
      setBvVermessung(updatedBvVermessung);
    } else if (type === "fenster") {
      const updatedBvFenster = props.fenster;
      setBvFenster(updatedBvFenster);
    } else if (type === "rohbauCheck") {
      const updatedBvRohbauCheck = props.rohbauCheck;
      setBvRohbauCheck(updatedBvRohbauCheck);
    } else if (type === "fertigungsbuergschaften") {
      const updatedBvFertigungsbuergschaften = props.fertigungsbuergschaften;
      setBvFertigungsbuergschaften(updatedBvFertigungsbuergschaften);
    }
  };

  if (type === "vermessung" && !bvVermessung) return <FDN.Loading box />;
  else if (type === "fenster" && !bvFenster) return <FDN.Loading box />;
  else if (type === "rohbauCheck" && !bvRohbauCheck) return <FDN.Loading box />;
  else if (type === "fertigungsbuergschaften" && !bvFertigungsbuergschaften)
    return <FDN.Loading box />;

  const setting = settings[type as keyof typeof settings];

  if (!setting) return <FDN.Loading box />;

  return (
    <div className="__progresslistbox">
      <h3>
        {link && (
          <div className="float-right button-goto-tab">
            <Link to={link}>
              <FDN.Icon icon="share-square-o" />
            </Link>
          </div>
        )}
        {FDN.I18n.t(`ccBvStatus.${type}.box.title`)}
      </h3>
      {setting.showPercent === true ? (
        <div className="__progresslistbox-percent">
          <BvPercentBar percent={percent} />
        </div>
      ) : null}

      {type === "vermessung" && bvVermessung ? (
        <ListVermessung
          vermessung={bvVermessung}
          items={setting.items}
          isEditable={isEditable}
          updateFromChild={updateFromChild}
        />
      ) : null}

      {type === "fenster" && bvFenster ? (
        <ListFenster
          fenster={bvFenster}
          items={setting.items}
          isEditable={isEditable}
          updateFromChild={updateFromChild}
        />
      ) : null}

      {type === "rohbauCheck" && bvRohbauCheck ? (
        <ListRohbauCheck
          rohbauCheck={bvRohbauCheck}
          items={setting.items}
          isEditable={isEditable}
          updateFromChild={updateFromChild}
        />
      ) : null}

      {type === "fertigungsbuergschaften" && bvFertigungsbuergschaften ? (
        <ListFertigungsbuergschaften
          fertigungsbuergschaften={bvFertigungsbuergschaften}
          items={setting.items}
          isEditable={isEditable}
          updateFromChild={updateFromChild}
        />
      ) : null}
    </div>
  );
};

interface IListVermessungProps {
  vermessung: IBvVermessung;
  items: string[];
  isEditable: boolean;
  updateFromChild: FUpdateFromChild;
}

const ListVermessung: React.FunctionComponent<IListVermessungProps> = ({
  vermessung,
  items,
  isEditable,
  updateFromChild,
}) => {
  return (
    <div className="__progresslistbox-content">
      {items.map((item) => {
        return (
          <React.Fragment key={item}>
            <BvDetail
              parent="vermessung"
              bvIdentifier={vermessung.bvIdentifier}
              type={item}
              value={vermessung[item as keyof IBvVermessung]}
              isEditable={isEditable}
              onUpdate={(value) => {
                updateFromChild({ vermessung: value as IBvVermessung });
              }}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

interface IListFensterProps {
  fenster: IBvFenster;
  items: string[];
  isEditable: boolean;
  updateFromChild: FUpdateFromChild;
}

const ListFenster: React.FunctionComponent<IListFensterProps> = ({
  fenster,
  items,
  isEditable,
  updateFromChild,
}) => {
  return (
    <div className="__progresslistbox-content">
      {items.map((item) => {
        return (
          <React.Fragment key={item}>
            <BvDetail
              parent="fenster"
              bvIdentifier={fenster.bvIdentifier}
              type={item}
              value={fenster[item as keyof IBvFenster]}
              isEditable={isEditable}
              onUpdate={(value) => {
                updateFromChild({ fenster: value as IBvFenster });
              }}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

interface IListRohbauCheckProps {
  rohbauCheck: IBvRohbauCheck;
  items: string[];
  isEditable: boolean;
  updateFromChild: FUpdateFromChild;
}

const ListRohbauCheck: React.FunctionComponent<IListRohbauCheckProps> = ({
  rohbauCheck,
  items,
  isEditable,
  updateFromChild,
}) => {
  return (
    <div className="__progresslistbox-content">
      {items.map((item) => {
        return (
          <React.Fragment key={item}>
            <BvDetail
              parent="rohbauCheck"
              bvIdentifier={rohbauCheck.bvIdentifier}
              type={item}
              value={rohbauCheck[item as keyof IBvRohbauCheck]}
              isEditable={isEditable}
              onUpdate={(value) => {
                updateFromChild({ rohbauCheck: value as IBvRohbauCheck });
              }}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

interface IListFertigungsbuergschaftenProps {
  fertigungsbuergschaften: IBvFertigungsbuergschaft;
  items: string[];
  isEditable: boolean;
  updateFromChild: FUpdateFromChild;
}

const ListFertigungsbuergschaften: React.FunctionComponent<IListFertigungsbuergschaftenProps> = ({
  fertigungsbuergschaften,
  items,
  isEditable,
  updateFromChild,
}) => {
  return (
    <div className="__progresslistbox-content">
      {items.map((item) => {
        return (
          <React.Fragment key={item}>
            <BvDetail
              parent="fertigungsbuergschaften"
              bvIdentifier={fertigungsbuergschaften.bvIdentifier}
              type={item}
              value={fertigungsbuergschaften[item as keyof IBvFertigungsbuergschaft]}
              isEditable={isEditable}
              onUpdate={(value) => {
                updateFromChild({ fertigungsbuergschaften: value as IBvFertigungsbuergschaft });
              }}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BvProgressListBox;
