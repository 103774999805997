import * as React from "react";
import * as FDN from "src/core";
import { TActionsBauvorhaben } from "src/services/cc/CCBauvorhabenService";
import useServiceCCBvStatus, { TActionsBauvorhabenStatus } from "src/services/cc/CCBvStatusService";
import { IBauvorhaben } from "src/types/types";
import BvStatusStatus from "./status/BvStatusStatus";

const tabs = {
  status: {
    label: FDN.I18n.t("ccBvStatus.tabs.status.label"),
    icon: "dashboard",
  },
  // fortschritt: {
  //   label: FDN.I18n.t("ccBvStatus.tabs.fortschritt.label"),
  //   icon: "bar-chart",
  // },
};

const defaultTab = "status";

interface IBauvorhabenStatusProps {
  bauvorhaben: IBauvorhaben;
  actions: TActionsBauvorhaben;
}

const BauvorhabenStatus: React.FunctionComponent<IBauvorhabenStatusProps> = ({
  bauvorhaben,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const { bvStatus, actions: statusActions } = useServiceCCBvStatus(actions);

  const moduleActions: TActionsBauvorhabenStatus = { ...actions, ...statusActions };

  React.useEffect(() => {
    if (!bvStatus) statusActions.init(bauvorhaben);
  }, [bauvorhaben]);

  if (!bvStatus) return <FDN.Loading box />;

  return (
    <div className="__cc-bauvorhaben-tab-status">
      <div className="__cc-bauvorhaben-tab-tabs">
        <FDN.Tabs
          tabs={tabs}
          selected={selectedTab}
          updateHistory={false}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
        />
      </div>
      <h2 className="__cc-bauvorhaben-tab-tabs-title">
        {FDN.I18n.t(`ccBvStatus.tabs.${selectedTab}.title`)}
      </h2>
      <FDN.TabsContent>
        {selectedTab === "status" && (
          <BvStatusStatus bauvorhaben={bauvorhaben} bvStatus={bvStatus} actions={actions} />
        )}
        {selectedTab === "fortschritt" && <FDN.Box>Fortschritt</FDN.Box>}
      </FDN.TabsContent>
    </div>
  );
};

export default BauvorhabenStatus;
