import React, { useContext, useEffect, useState } from "react";
import * as FDN from "src/core";
import { IBauvorhaben, IMstTasksPlan, ITodo, ITodoTask, TFunction } from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import CCApi from "src/api/CCApi";
import { cloneDeep } from "lodash";
import { SocketContext } from "src/components/context/SocketContext/SocketContext";
import MstApi from "src/api/MstApi";
import { TActionsBauvorhaben } from "./CCBauvorhabenService";

export interface TActionsBauvorhabenAufgaben extends TActionsBauvorhaben {
  init: TFunction;
  isTaskOverdue: TFunction;
  refreshTasksPlan: TFunction;
  openTask: TFunction;
  setTaskEditMode: TFunction;
}

const useServiceCCBvAufgaben = (bvActions: TActionsBauvorhaben) => {
  const { NOTIFICATIONS, api } = useServiceCore();
  const SOCKETIO = useContext(SocketContext);

  const [bauvorhaben, setBauvorhaben] = useState<IBauvorhaben>();
  const [todo, setTodo] = React.useState<ITodo>();
  const [selectedTask, setSelectedTask] = React.useState<ITodoTask | "LOADING">();
  const [taskEditMode, setTaskEditMode] = React.useState(false);
  const [updatingTasksPlan, setUpdatingTasksPlan] = useState<IMstTasksPlan>();

  useEffect(() => {
    // console.log("useEffect", SOCKETIO.tasksPlans, Object.keys(SOCKETIO.tasksPlans).length);
    // console.log("useEffect updatingTasksPlan", updatingTasksPlan);

    if (
      !SOCKETIO.tasksPlans ||
      Object.keys(SOCKETIO.tasksPlans).length === 0 ||
      !updatingTasksPlan ||
      !updatingTasksPlan.identifier
    )
      return;

    if (SOCKETIO.tasksPlans[updatingTasksPlan.identifier]) {
      onRefreshTasksPlanUpdatePercent(SOCKETIO.tasksPlans[updatingTasksPlan.identifier]);
    }
  }, [SOCKETIO.tasksPlans]);

  const init = (bauvorhaben: IBauvorhaben) => {
    setBauvorhaben(cloneDeep(bauvorhaben));
    CCApi.ccBauvorhabenContent(api, bauvorhaben.identifier, "todo").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setTodo(response?.body?.todo as ITodo);
      }
    });
  };

  const isTaskOverdue = (task: ITodoTask): boolean => {
    if (!task.dueDate) return false;

    const now = new Date();
    return new Date(task.dueDate) < now;
  };

  const refreshTasksPlan = (tasksPlanIdentifier: string) => {
    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("ccBvAufgaben.refreshTasksPlan.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
            const pseudoTasksPlan: IMstTasksPlan = {
              identifier: tasksPlanIdentifier,
              syncPercent: 0,
            };
            setUpdatingTasksPlan(pseudoTasksPlan);
            SOCKETIO.refreshTasksPlan(tasksPlanIdentifier);
          },
        },
      ],
    });
  };

  const onRefreshTasksPlanUpdatePercent = (mstTasksPlan?: IMstTasksPlan) => {
    if (!mstTasksPlan) return;

    if (mstTasksPlan?.syncPercent === 100) {
      setUpdatingTasksPlan(undefined);

      if (bauvorhaben) {
        setTodo(undefined);
        init(bauvorhaben);
      }

      NOTIFICATIONS.showNotification(
        "success",
        FDN.I18n.t("ccBvAufgaben.refreshTasksPlan.success.title"),
        FDN.I18n.t("ccBvAufgaben.refreshTasksPlan.success.text")
      );
      return;
    }

    setUpdatingTasksPlan(cloneDeep(mstTasksPlan));
  };

  const openTask = (task: ITodoTask | undefined | null) => {
    if (!task) setSelectedTask(undefined);
    else {
      setSelectedTask("LOADING");
      MstApi.tasksReloadTask(api, task.identifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS)
          setSelectedTask(response?.body?.task as ITodoTask);
      });
    }
  };

  const actions: TActionsBauvorhabenAufgaben = {
    ...bvActions,
    init,
    isTaskOverdue,
    refreshTasksPlan,
    openTask,
    setTaskEditMode,
  };

  return { todo, updatingTasksPlan, selectedTask, taskEditMode, actions };
};

export default useServiceCCBvAufgaben;
