import * as React from "react";
import * as FDN from "src/core";
import { TActionsBauvorhabenPaymentPlan } from "src/services/cc/CCBvPaymentPlanService";
import { IBvInstallment } from "src/types/types";

interface IEditInstallmentPopupProps {
  installment: IBvInstallment;
  actions: TActionsBauvorhabenPaymentPlan;
}

const EditInstallmentPopup: React.FunctionComponent<IEditInstallmentPopupProps> = ({
  installment,
  actions,
}) => {
  const editMode =
    installment.status === "APPROVED" ||
    installment.status === "INVOICED" ||
    installment.status === "PAID"
      ? false
      : true;

  const installmentTitle = () =>
    `${installment.number || ""} ${FDN.I18n.t(
      "ccBvPaymentPlan.installments.rate.label"
    )} - ${FDN.formatNumber(installment.percent || 0, 2, 2)} % ${installment.text || ""}`;

  const selectValuesTypes = {
    building: FDN.I18n.t("ccBvPaymentPlan.edit.form.type.options.building.label"),
    bnk: FDN.I18n.t("ccBvPaymentPlan.edit.form.type.options.bnk.label"),
  };

  return (
    <div className="paymentplan-edit-installment">
      <FDN.Popup
        size="medium"
        title={
          installment.identifier === "new"
            ? FDN.I18n.t("ccBvPaymentPlan.edit.new.title")
            : FDN.I18n.t("ccBvPaymentPlan.edit.edit.title")
        }
        onClose={actions.onCancelEditInstallment}
      >
        {editMode === false ? (
          <FDN.CalloutBox type="warning">
            <div>
              <strong>{FDN.I18n.t("ccBvPaymentPlan.edit.editMode.false.title")}</strong>
            </div>
            <div>{FDN.I18n.t("ccBvPaymentPlan.edit.editMode.false.text")}</div>
          </FDN.CalloutBox>
        ) : null}
        <FDN.Box>
          <FDN.Grid full>
            <FDN.Row margin="xy">
              <FDN.Cell sm={24} md={6}>
                <FDN.Input
                  type="text"
                  value={installment.number}
                  label={FDN.I18n.t("ccBvPaymentPlan.edit.form.number.label")}
                  placeholder={FDN.I18n.t("ccBvPaymentPlan.edit.form.number.placeholder")}
                  editMode={editMode}
                  editable={true}
                  onUpdate={(value) => actions.onEditInstallment("number", value)}
                />
              </FDN.Cell>
              <FDN.Cell sm={24} md={18}>
                <FDN.Input
                  type="text"
                  value={installment.text}
                  label={FDN.I18n.t("ccBvPaymentPlan.edit.form.text.label")}
                  placeholder={FDN.I18n.t("ccBvPaymentPlan.edit.form.text.placeholder")}
                  editMode={editMode}
                  editable={true}
                  onUpdate={(value) => actions.onEditInstallment("text", value)}
                />
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </FDN.Box>
        <FDN.Box>
          <FDN.Grid full>
            <FDN.Row margin="xy">
              <FDN.Cell sm={24} md={12}>
                <FDN.Input
                  type="select"
                  selectValues={selectValuesTypes}
                  value={installment.type}
                  showValue={selectValuesTypes[installment.type as keyof typeof selectValuesTypes]}
                  label={FDN.I18n.t("ccBvPaymentPlan.edit.form.type.label")}
                  placeholder={FDN.I18n.t("ccBvPaymentPlan.edit.form.type.placeholder")}
                  editMode={editMode}
                  editable={true}
                  onUpdate={(value) => actions.onEditInstallment("type", value)}
                />
              </FDN.Cell>
              <FDN.Cell sm={24} md={12}>
                <FDN.Input
                  type="number"
                  value={installment.percent || 0}
                  showValue={FDN.formatNumber(installment.percent || 0, 2, 2)}
                  label={FDN.I18n.t("ccBvPaymentPlan.edit.form.percent.label")}
                  editMode={editMode}
                  editable={true}
                  onUpdate={(value) => actions.onEditInstallment("percent", value)}
                />
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </FDN.Box>
        <FDN.Box>
          <div className="text-center">{installmentTitle()}</div>
        </FDN.Box>
        {editMode && (
          <FDN.FormButtons
            onDelete={() => actions.onDeleteInstallment(installment)}
            onCancel={actions.onCancelEditInstallment}
            onSave={actions.onSaveEditInstallment}
          />
        )}
      </FDN.Popup>
    </div>
  );
};

export default EditInstallmentPopup;
