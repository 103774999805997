import * as React from "react";
import { Link } from "react-router-dom";
import * as FDN from "src/core";
import { TActionsBauvorhabenBauvorbereitung } from "src/services/cc/CCBvBauvorbereitungService";
import { IBvDetail } from "src/types/types";
import BvDetailPercent from "./BvDetailPercent";

interface IBvFortschrittProps {
  details?: IBvDetail;
  link?: string;
  editMode: boolean;
  actions?: TActionsBauvorhabenBauvorbereitung;
}

const BvFortschritt: React.FunctionComponent<IBvFortschrittProps> = ({
  details,
  link,
  editMode,
  actions,
}) => {
  if (!details) return null;

  return (
    <div className="__bv-status-fortschritt">
      <FDN.Box>
        <h3>
          {link ? (
            <div className="cc-bv-status-link-to-tab float-right">
              <Link to={link}>
                <FDN.Icon icon="share-square-o" />
              </Link>
            </div>
          ) : null}
          {FDN.I18n.t(`ccBvStatus.fortschritt.box.title`)}
        </h3>
        <FDN.Grid full>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <BvDetailPercent
                details={details}
                type="asl_percent"
                title={FDN.I18n.t("ccBvBauvorbereitung.fortschritt.asl_percent.label")}
                editMode={editMode}
                actions={actions}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <BvDetailPercent
                details={details}
                type="statik_percent"
                title={FDN.I18n.t("ccBvBauvorbereitung.fortschritt.statik_percent.label")}
                editMode={editMode}
                actions={actions}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <BvDetailPercent
                details={details}
                type="baugenehmigung_percent"
                title={FDN.I18n.t("ccBvBauvorbereitung.fortschritt.baugenehmigung_percent.label")}
                editMode={editMode}
                actions={actions}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <BvDetailPercent
                details={details}
                type="entwaesserungsplanung_percent"
                title={FDN.I18n.t(
                  "ccBvBauvorbereitung.fortschritt.entwaesserungsplanung_percent.label"
                )}
                editMode={editMode}
                actions={actions}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <BvDetailPercent
                details={details}
                type="entwaesserungsgenehmigung_percent"
                title={FDN.I18n.t(
                  "ccBvBauvorbereitung.fortschritt.entwaesserungsgenehmigung_percent.label"
                )}
                editMode={editMode}
                actions={actions}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
      <FDN.Box>
        <FDN.Grid full>
          <FDN.Row margin="y">
            <FDN.Cell sm={24}>
              <BvDetailPercent
                details={details}
                type="haustechnischeplanung_percent"
                step={25}
                title={FDN.I18n.t(
                  "ccBvBauvorbereitung.fortschritt.haustechnischeplanung_percent.label"
                )}
                editMode={editMode}
                actions={actions}
              />
              <div className="bv-fortschritt-htp-status">
                {FDN.I18n.t(
                  `ccBvBauvorbereitung.fortschritt.haustechnischeplanung_percent.status.${
                    details.haustechnischeplanung_percent || 0
                  }.label`
                )}
              </div>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
    </div>
  );
};

export default BvFortschritt;
