import * as React from "react";
import * as FDN from "src/core";
import { TFunction } from "src/types/types";

interface IBauvorhabenTabsProps {
  tabs: FDN.ITabs[];
  visibleTabs?: string[];
  selectedTab: string;
  onSelectTab: TFunction;
}

const BauvorhabenTabs: React.FunctionComponent<IBauvorhabenTabsProps> = ({
  tabs,
  visibleTabs,
  selectedTab,
  onSelectTab,
}) => {
  const visibleTabBlocks: number[] = [];
  for (let i = 0; i < tabs.length; i++) {
    if (!visibleTabs) visibleTabBlocks.push(i);
    else {
      let isVisible = false;

      for (const tabKey of Object.keys(tabs[i])) {
        if (visibleTabs.includes(tabKey)) isVisible = true;
      }

      if (isVisible === true) visibleTabBlocks.push(i);
    }
  }

  return (
    <div className="__cc-bauvorhaben-bv-tabs">
      {tabs.map((tabsBlock, index) => {
        if (!visibleTabBlocks.includes(index)) return null;

        return (
          <FDN.Box noPadding noMargin key={index}>
            {Object.keys(tabsBlock).map((tabKey) => {
              let isVisible = false;
              if (!visibleTabs || visibleTabs.includes(tabKey)) isVisible = true;
              if (!isVisible) return null;

              const tab = tabsBlock[tabKey];

              if (tab.disabled === true) return null;
              return (
                <button
                  className={`${
                    tabKey === selectedTab ? `selected` : ``
                  } __cc-bauvorhaben-bv-tabs-tab`}
                  key={tabKey}
                  onClick={() => {
                    if (!tab.disabled) onSelectTab(tabKey);
                  }}
                >
                  <div className="__cc-bauvorhaben-bv-tabs-tab-border">
                    {tab.icon && <FDN.Icon icon={tab.icon as string} left />}{" "}
                    <span>{tab.label}</span>
                  </div>
                </button>
              );

              // return (
              //   <button
              //     className={`${tab.disabled === true ? `disabled` : ``} ${
              //       tabKey === selectedTab ? `selected` : ``
              //     } __cc-bauvorhaben-bv-tabs-tab`}
              //     key={tabKey}
              //     onClick={() => {
              //       if (!tab.disabled) onSelectTab(tabKey);
              //     }}
              //   >
              //     <div className="__cc-bauvorhaben-bv-tabs-tab-border">
              //       {tab.icon && <FDN.Icon icon={tab.icon as string} left />}{" "}
              //       <span>{tab.label}</span>
              //     </div>
              //   </button>
              // );
            })}
          </FDN.Box>
        );
      })}
    </div>
  );
};

export default BauvorhabenTabs;
