import { useEffect, useState } from "react";
import * as FDN from "src/core";
import { IBauvorhaben, TFunction } from "src/types/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import CCApi from "src/api/CCApi";
import { cloneDeep } from "lodash";
import { getValidationMissingFields } from "src/core/helpers/helpers";
import { useParams } from "react-router-dom";

export interface TActionsBauvorhaben {
  revokeAccess: TFunction;
  toggleTabsForClients: TFunction;
  onCancel: TFunction;
  setEditMode: TFunction;
  onUpdateBauvorhaben: TFunction;

  onSelectTab?: TFunction;
  onEdit: TFunction;
  onClose?: TFunction;
  onSave?: TFunction;
  onDelete?: TFunction;

  loadMstFolders?: TFunction;
  loadTeamsAndChannels?: TFunction;
}

const useServiceCCBauvorhaben = () => {
  const { NOTIFICATIONS, api } = useServiceCore();

  const [editMode, setEditMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [orgBauvorhaben, setOrgBauvorhaben] = useState<IBauvorhaben>();
  const [bauvorhaben, setBauvorhaben] = useState<IBauvorhaben>();

  const [missing, setMissing] = useState<string[]>([]);

  const params = useParams();
  const bauvorhabenIdentifier = params.identifier as string;

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    CCApi.ccBauvorhaben(api, bauvorhabenIdentifier).then((response) => {
      if (response?.body?.bauvorhaben) {
        setBauvorhaben(response?.body?.bauvorhaben as IBauvorhaben);
        setOrgBauvorhaben(cloneDeep(response?.body?.bauvorhaben) as IBauvorhaben);
      }
    });
  };

  const onEdit = (property: string, value: any) => {
    if (!bauvorhaben) return;
    bauvorhaben[property] = value;

    if (property === "teamsTeamIdentifier") {
      bauvorhaben.teamsChannelIdentifier = "none";
      bauvorhaben.teamsTaskPlanIdentifier = "none";
    }

    setBauvorhaben(cloneDeep(bauvorhaben));
    setUnsavedChanges(true);
  };

  const revokeAccess = (userIdentifier: string, callback: (bauvorhaben?: IBauvorhaben) => void) => {
    if (!bauvorhaben) return;

    CCApi.ccBvRevokeAccess(api, bauvorhaben.identifier, userIdentifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS && response?.body?.bauvorhaben && callback) {
        setBauvorhaben(response?.body?.bauvorhaben as IBauvorhaben);
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("ccBvClients.deleteAccess.success.title"),
          FDN.I18n.t("ccBvClients.deleteAccess.success.text")
        );
        callback(response?.body?.bauvorhaben);
      }
    });
  };

  const toggleTabsForClients = (key: string) => {
    if (!bauvorhaben) return;
    if (!bauvorhaben.tabsForClients) bauvorhaben.tabsForClients = [];

    bauvorhaben.tabsForClients = FDN.toggleArrayItem(key, bauvorhaben.tabsForClients);

    setBauvorhaben(cloneDeep(bauvorhaben));
  };

  const onCancel = () => {
    const doCancel = () => {
      NOTIFICATIONS.hideDialog();
      setEditMode(false);
      setUnsavedChanges(false);
      setBauvorhaben(cloneDeep(orgBauvorhaben));
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSave = () => {
    if (!bauvorhaben) return;

    setMissing([]);

    CCApi.ccUpdateBauvorhaben(api, bauvorhaben.identifier, bauvorhaben).then((response) => {
      if (response?.statusCode === StatusCode.INVALID_FORM_DATA) {
        setMissing(getValidationMissingFields(response?.body?.error));
      } else if (response?.statusCode === StatusCode.SUCCESS) {
        if (response?.body?.bauvorhaben)
          setBauvorhaben(response?.body?.bauvorhaben as IBauvorhaben);
        setEditMode(false);
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("ccBauvorhaben.messages.onSave.success.title"),
          FDN.I18n.t("ccBauvorhaben.messages.onSave.success.text")
        );
      }
    });
  };

  const onUpdateBauvorhaben = (bauvorhaben: IBauvorhaben) => {
    setBauvorhaben(cloneDeep(bauvorhaben));
  };

  const actions: TActionsBauvorhaben = {
    onEdit,
    revokeAccess,
    toggleTabsForClients,
    onCancel,
    onSave,
    setEditMode,
    onUpdateBauvorhaben,
  };

  return { bauvorhaben, editMode, missing, actions };
};

export default useServiceCCBauvorhaben;
